import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../api"; // Import the axios instance

// Define initial state
const initialState = {
  menuItems: [],
  status: "idle",
  error: null,
};

// Async thunk for fetching menu items
export const fetchMenuItems = createAsyncThunk(
  "menuItems/fetchMenuItems",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.menuItemApi.getMenuItems(); // Use the axios instance
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Async thunk for adding a menu item
export const addMenuItem = createAsyncThunk(
  "menuItems/addMenuItem",
  async (menuItemData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await apiServices.menuItemApi.createMenuItem(
        menuItemData,
        config,
      ); // Post the new menu item
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateMenuItem = createAsyncThunk(
  "menuItems/updateMenuItem",
  async (menuItemData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await apiServices.menuItemApi.updateMenuItem(
        menuItemData.get("_id"),
        menuItemData,
        config,
      ); // Update the menu item
      return response.data;
    } catch (error) {
      console.error("Error updating menu item:", error);
      return rejectWithValue(error.response.data);
    }
  },
);

// Async thunk for deleting a menu item
export const deleteMenuItem = createAsyncThunk(
  "menuItems/deleteMenuItem",
  async (id, { rejectWithValue }) => {
    try {
      await apiServices.menuItemApi.deleteMenuItem(id); // Delete the menu item
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Create the slice
const menuItemsSlice = createSlice({
  name: "menuItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMenuItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.menuItems = action.payload;
      })
      .addCase(fetchMenuItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(addMenuItem.fulfilled, (state, action) => {
        state.menuItems.push(action.payload);
      })
      .addCase(updateMenuItem.fulfilled, (state, action) => {
        const { _id, name, description, size, price, category, active, image } =
          action.payload;
        const existingItem = state.menuItems.find((item) => item._id === _id);
        if (existingItem) {
          existingItem.name = name || existingItem.name;
          existingItem.description = description || existingItem.description;
          existingItem.size = size || existingItem.size;
          existingItem.price = price || existingItem.price;
          existingItem.category = category || existingItem.category;
          existingItem.active =
            active !== undefined ? active : existingItem.active;
          existingItem.image = image || existingItem.image;
        }
      })
      .addCase(deleteMenuItem.fulfilled, (state, action) => {
        const id = action.payload;
        state.menuItems = state.menuItems.filter((item) => item._id !== id);
      })
      .addCase(deleteMenuItem.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export default menuItemsSlice.reducer;
