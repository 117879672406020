// src/components/Register/Form.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  InputLabel,
  Button,
  Typography,
  Paper,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Chip,
  Link,
} from '@mui/material';
import { showToastError } from '../../utils/toastUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrganizationsPublic,
  selectOrganizations,
} from '../../features/organizations'; // Adjust path as necessary
import {
  fetchSchoolsByOrganization,
  selectSchools,
} from '../../features/schools'; // Adjust path as necessary
import ImagePreview from '../Images/ImagePreview';
import ImageUpload from '../Images/ImageUpload'; // Import ImageUpload component

const RegisterForm = ({ organization, onRegister }) => {
  const dispatch = useDispatch();
  const organizations = useSelector(selectOrganizations);
  const schools = useSelector(selectSchools);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [image, setImage] = useState(null); // State for the image

  useEffect(() => {
    dispatch(fetchOrganizationsPublic(organization));
  }, [dispatch, organization]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(fetchSchoolsByOrganization(selectedOrganization));
    }
  }, [dispatch, selectedOrganization]);

  useEffect(() => {
    if (organization) {
      const org = organizations.find((org) => org._id === organization);
      if (org) {
        setSelectedOrganization(org._id);
      }
    }
  }, [organizations, organization]);

  const handleOrganizationChange = (e) => {
    const newOrganization = e.target.value;
    setSelectedOrganization(newOrganization);
    setSelectedSchools([]); // Clear selected schools when organization changes
  };

  const handleSchoolChange = (e) => {
    setSelectedSchools(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showToastError('Passwords do not match');
      return;
    }

    const userData = {
      firstName,
      lastName,
      email,
      phone,
      password,
      organizations: [selectedOrganization], // Assuming a single organization for now
      schools: selectedSchools,
      image, // Include the image in the form data
    };

    onRegister(userData);
  };

  const isFormValid = () => {
    return (
      firstName &&
      lastName &&
      email &&
      phone &&
      password &&
      confirmPassword &&
      selectedOrganization &&
      password === confirmPassword
    );
  };
  return (
    <>
      {selectedOrganization && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImagePreview
            imageURL={
              organizations.find((org) => org._id === selectedOrganization)
                ?.imageInfo?.url
            }
            alt={`${organizations.find((org) => org._id === selectedOrganization)?.name}`}
            style={{ height: '100px', marginBottom: '1rem' }}
          />
          <Typography variant='h1' textAlign='center'>
            {organizations.find((org) => org._id === selectedOrganization)
              ?.name || ''}
          </Typography>
        </Box>
      )}
      <Paper
        elevation={3}
        style={{ padding: '1rem', maxWidth: '600px', margin: '1rem auto' }}
      >
        <Typography variant='h4' gutterBottom>
          Register
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{ marginBottom: '15px' }}
          gutterBottom
        >
          Create an account to start ordering for your school. If you already
          have an account, please log in. This resource is for schools only. If
          you are a parent, please contact your school's administrator. All
          accounts must be approved {selectedOrganization ? 'by ' : ''}
          {organizations.find((org) => org._id === selectedOrganization)?.name}.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box style={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label='First Name'
              variant='outlined'
              autoComplete='first-name'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <TextField
              label='Last Name'
              autoComplete='last-name'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />

            <TextField
              label='Email'
              autoComplete='email'
              type='email'
              sx={{ flex: 1 }}
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <InputLabel id='school-label'>Profile Picture</InputLabel>
          <ImageUpload
            image={image}
            onImageChange={setImage}
            initialPreview={image && URL.createObjectURL(image)}
          />
          <TextField
            label='Phone'
            autoComplete='phone'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin='dense'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label='Password'
              autoComplete='new-password'
              type='password'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              sx={{ flex: 1 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label='Confirm Password'
              autoComplete='confirm-password'
              type='password'
              variant='outlined'
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              margin='dense'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Box>
          {!organization && (
            <>
              <InputLabel id='organization-label'>
                Select Organization
              </InputLabel>
              <Select
                labelId='organization-label'
                value={selectedOrganization?._id || ''}
                onChange={handleOrganizationChange}
                fullWidth
                displayEmpty
              >
                <MenuItem value='' disabled>
                  Select an Organization
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org._id} value={org._id}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {selectedOrganization && (
            <>
              <InputLabel id='school-label'>
                Select Schools You Will Order For
              </InputLabel>
              <Select
                multiple
                labelId='school-label'
                value={selectedSchools}
                onChange={handleSchoolChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {selected.map((school) => (
                      <Chip
                        key={school}
                        label={school}
                        sx={{ margin: '2px 0' }} // Adjust spacing as needed
                      />
                    ))}
                  </Box>
                )}
                fullWidth
              >
                {schools.map((school) => (
                  <MenuItem key={school._id} value={school.name}>
                    <Checkbox
                      checked={selectedSchools.indexOf(school.name) > -1}
                    />
                    <ListItemText primary={school.name} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <Button
            type='submit'
            sx={{ marginTop: '1rem' }}
            variant='contained'
            color='primary'
            fullWidth
            disabled={!isFormValid()} // Disable button based on form validation
          >
            Register
          </Button>
        </form>
        <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
          <Link href='/login' variant='body2'>
            Already have an account? Log in
          </Link>
        </Box>
      </Paper>
    </>
  );
};

export default RegisterForm;
