import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMenuItems,
  addMenuItem,
  updateMenuItem,
  deleteMenuItem,
} from "../features/menuItems";
import MenuItemList from "../components/MenuItem/List";
import MenuItemForm from "../components/MenuItem/Form";
import {
  Button,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ConfirmationDialog from "../components/ConfirmationDialog"; // Adjust path as needed

const MenuItems = () => {
  const savedState = JSON.parse(localStorage.getItem("menuItemsPageState"));
  const dispatch = useDispatch();
  const menuItems = useSelector((state) => state.menuItems.menuItems);
  const status = useSelector((state) => state.menuItems.status);
  const error = useSelector((state) => state.menuItems.error);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(savedState?.openDialog || false);
  const [dialogMode, setDialogMode] = useState(
    savedState?.dialogMode || "create",
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewMode, setViewMode] = useState(savedState?.viewMode || "table"); // Default view mode
  const [dialogOpen, setDialogOpen] = useState(savedState?.dialogOpen || false);
  const [itemToDelete, setItemToDelete] = useState(
    savedState?.itemToDelete || null,
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState(
    savedState?.snackbarSeverity || "success",
  ); // Default to success

  // Save state to localStorage whenever relevant state variables change
  useEffect(() => {
    const stateToSave = {
      openDialog,
      dialogMode,
      selectedItem,
      viewMode,
      dialogOpen,
      itemToDelete,
      snackbarSeverity,
    };
    localStorage.setItem("menuItemsPageState", JSON.stringify(stateToSave));
  }, [
    openDialog,
    dialogMode,
    selectedItem,
    viewMode,
    dialogOpen,
    itemToDelete,
    snackbarSeverity,
  ]);

  useEffect(() => {
    dispatch(fetchMenuItems());
  }, [dispatch]);

  const handleOpenDialog = (mode, item = null) => {
    setDialogMode(mode);
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleSaveMenuItem = (menuItemData) => {
    if (dialogMode === "create") {
      dispatch(addMenuItem(menuItemData))
        .unwrap()
        .then(() => {
          // dispatch(fetchMenuItems()); // Refresh the menu items after saving
          setSnackbarMessage("Menu item added successfully!");
          setSnackbarSeverity("success"); // Set severity to success
          setOpenSnackbar(true);
        })
        .catch((error) => {
          setSnackbarMessage(`Error: ${error.message}`);
          setSnackbarSeverity("error"); // Set severity to error
          setOpenSnackbar(true);
        });
    } else if (dialogMode === "update" && selectedItem) {
      dispatch(updateMenuItem(menuItemData))
        .unwrap()
        .then(() => {
          // dispatch(fetchMenuItems()); // Refresh the menu items after updating
          setSnackbarMessage("Menu item updated successfully!");
          setSnackbarSeverity("success"); // Set severity to success
          setOpenSnackbar(true);
        })
        .catch((error) => {
          setSnackbarMessage(`Error: ${error.message}`);
          setSnackbarSeverity("error"); // Set severity to error
          setOpenSnackbar(true);
        });
    }
    handleCloseDialog();
  };

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode) {
      setViewMode(newViewMode);
    }
  };

  const handleActiveToggle = (item) => {
    dispatch(updateMenuItem({ ...item, active: !item.active }))
      .unwrap()
      .catch((error) => {
        setSnackbarMessage(`Error: ${error.message}`);
        setOpenSnackbar(true);
      });
  };

  const handleOpenDeleteDialog = (item) => {
    setItemToDelete(item);
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      dispatch(deleteMenuItem(itemToDelete))
        .unwrap()
        .catch((error) => {
          setSnackbarMessage(`Error: ${error.message}`);
          setOpenSnackbar(true);
        });
      setItemToDelete(null);
    }
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setItemToDelete(null);
  };

  const uniqueCategories = [...new Set(menuItems.map((item) => item.category))];

  return (
    <>
      {status === "loading" && <p>Loading...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div style={{ padding: "20px" }}>
          <h1>Menu Items</h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog("create")}
          >
            Add New Menu Item
          </Button>

          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
            style={{ marginTop: "16px", display: "block" }}
          >
            <ToggleButton value="table" aria-label="table view">
              Table View
            </ToggleButton>
            <ToggleButton value="card" aria-label="card view">
              Card View
            </ToggleButton>
          </ToggleButtonGroup>

          {status === "succeeded" && (
            <MenuItemList
              menuItems={menuItems}
              onEdit={(item) => handleOpenDialog("update", item)}
              onDelete={handleOpenDeleteDialog}
              viewMode={viewMode}
              onToggleActive={handleActiveToggle} // Pass the toggle function to MenuItemList
            />
          )}

          <MenuItemForm
            open={openDialog}
            categories={uniqueCategories}
            handleClose={handleCloseDialog}
            selectedItem={selectedItem}
            handleSave={handleSaveMenuItem}
            items={menuItems} // Pass available items to the form
          />

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {/* Confirmation Dialog */}
          <ConfirmationDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            onConfirm={handleConfirmDelete}
            title="Delete Menu Item"
            message={`Are you sure you want to delete this menu item?`}
          />
        </div>
      )}
    </>
  );
};

export default MenuItems;
