import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)({
  width: '90px', // Set your desired max width
  whiteSpace: 'normal',
  wordWrap: 'break-word',
});

const CategoryTable = ({ category, items, isPremade, school }) => {
  const sortItems = (items) => {
    return Object.entries(items)
      .sort(([a], [b]) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };

  const hasSaturday = Object.values(items).some(
    (details) => details.dailyQuantities.saturday,
  );
  const hasSunday = Object.values(items).some(
    (details) => details.dailyQuantities.sunday,
  );
  const colSpan = isPremade
    ? 7 + (hasSaturday ? 1 : 0) + (hasSunday ? 1 : 0)
    : 3;
  return (
    <Box
      display='flex'
      alignContent='flex-start'
      flexDirection='row'
      flexWrap='wrap'
      gap={2}
      mt={2}
      width={isPremade ? '100%' : 'inherit'}
    >
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table
          sx={{
            width: isPremade ? '100%' : '260px',
            maxWidth: isPremade ? '100%' : '260px',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                colSpan={colSpan}
                align='center'
                sx={{ textAlign: 'center', fontSize: '1.2rem' }}
              >
                {category} {isPremade && 'Meals'} {school && `for ${school}`}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Item</StyledTableCell>
              {isPremade && (
                <>
                  <StyledTableCell>Mon</StyledTableCell>
                  <StyledTableCell>Tue</StyledTableCell>
                  <StyledTableCell>Wed</StyledTableCell>
                  <StyledTableCell>Thu</StyledTableCell>
                  <StyledTableCell>Fri</StyledTableCell>
                  {hasSaturday && <StyledTableCell>Sat</StyledTableCell>}
                  {hasSunday && <StyledTableCell>Sun</StyledTableCell>}
                </>
              )}
              <StyledTableCell>Total Quantity</StyledTableCell>
              {!isPremade && <StyledTableCell>Size</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(sortItems(items)).map(([item, details]) => (
              <TableRow key={item}>
                <StyledTableCell>{item}</StyledTableCell>
                {isPremade && (
                  <>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {details.dailyQuantities.monday || 0}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {details.dailyQuantities.tuesday || 0}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {details.dailyQuantities.wednesday || 0}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {details.dailyQuantities.thursday || 0}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {details.dailyQuantities.friday || 0}
                    </StyledTableCell>
                    {hasSaturday && (
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        {details.dailyQuantities.saturday}
                      </StyledTableCell>
                    )}
                    {hasSunday && (
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        {details.dailyQuantities.sunday}
                      </StyledTableCell>
                    )}
                  </>
                )}
                <StyledTableCell sx={{ textAlign: 'center' }}>
                  {isPremade
                    ? (details.totalQuantity ?? 0)
                    : (details.bulkQuantity ?? 0)}
                </StyledTableCell>
                {!isPremade && (
                  <StyledTableCell sx={{ textAlign: 'left' }}>
                    {details.size}
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CategoryTable;
