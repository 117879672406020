import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSchool, updateSchool } from '../../features/schools';
import { fetchOrganizations } from '../../features/organizations';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { sanitizeUrl } from '../../utils/generalUtils';

import { z } from 'zod';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const schema = z.object({
  schoolID: z.string().trim().optional(),
  schoolType: z.enum(['Elementary', 'Middle', 'High', 'K-12', 'Other'], {
    required_error: 'School Type is required',
  }),
  organization: z.string().nonempty('Select an Organization'),
  name: z
    .string({ required_error: 'School Name is required' })
    .trim()
    .nonempty('School Name cannot be empty'),
  shortName: z
    .string({ required_error: 'School Short Name is required' })
    .trim()
    .nonempty('School Short Name cannot be empty'),
  deliveryDate: z
    .enum([
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      '',
    ])
    .optional(),
  address: z.string().trim().optional(),
  phone: z
    .string()
    .trim()
    .optional()
    .refine(
      (value) =>
        !value || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9\s]{3,20}$/.test(value),
      {
        message: 'Invalid phone number format',
      },
    ),
  fax: z
    .string()
    .trim()
    .optional()
    .refine(
      (value) =>
        !value || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9\s]{3,20}$/.test(value),
      {
        message: 'Invalid fax number format',
      },
    ),
  website: z
    .string()
    .trim()
    .transform(sanitizeUrl)
    .optional()
    .refine(
      (value) => !value || /^[a-zA-Z0-9-_.]+(\.[a-zA-Z]{2,})+$/.test(value),
      {
        message: 'Invalid URL format',
      },
    ),
  principal: z.string().trim().optional(),
  vp: z.string().trim().optional(),
  secretary: z.string().trim().optional(),
  contactName: z.string().trim().optional(),
  contactEmail: z
    .string()
    .trim()
    .optional()
    .or(z.string().length(0))
    .refine((value) => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
      message: 'Invalid email format',
    }),
  contactPhone: z
    .string()
    .trim()
    .optional()
    .refine(
      (value) =>
        !value || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9\s]{3,20}$/.test(value),
      {
        message: 'Invalid contact phone number format',
      },
    ),
  population: z
    .union([z.string(), z.number().nullable()])
    .refine((value) => value === '' || value >= 0, {
      message: 'Enter a valid population number',
    }),
  latitude: z
    .union([z.string(), z.number().nullable()])
    .refine((value) => value === '' || (value >= -90 && value <= 90), {
      message: 'Latitude must be between -90 and 90 or an empty string',
    })
    .transform((value) => (value === '' ? null : value)),
  longitude: z
    .union([z.string(), z.number().nullable()])
    .refine((value) => value === '' || (value >= -180 && value <= 180), {
      message: 'Longitude must be between -180 and 180 or an empty string',
    })
    .transform((value) => (value === '' ? null : value)),
});

const SchoolForm = ({ school: schoolToEdit, open, onClose }) => {
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state) => state.organizations.organizations || [],
  );
  const organizationsStatus = useSelector(
    (state) => state.organizations.status,
  );
  // const organizationsError = useSelector((state) => state.organizations.error);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      schoolID: '',
      name: '',
      shortName: '',
      deliveryDate: '',
      address: '',
      phone: '',
      fax: '',
      website: '',
      principal: '',
      vp: '',
      secretary: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      population: 0,
      latitude: '',
      longitude: '',
      organization: '',
      schoolType: 'Elementary',
    },
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    if (organizationsStatus === 'idle') {
      dispatch(fetchOrganizations());
    }
  }, [dispatch, organizationsStatus]);

  useEffect(() => {
    if (schoolToEdit) {
      setValue('schoolID', schoolToEdit.schoolID || '');
      setValue('name', schoolToEdit.name || '');
      setValue('shortName', schoolToEdit.shortName || '');
      setValue('deliveryDate', schoolToEdit.deliveryDate || '');
      setValue('schoolType', schoolToEdit.schoolType || '');
      setValue('address', schoolToEdit.address || '');
      setValue('phone', schoolToEdit.phone || '');
      setValue('fax', schoolToEdit.fax || '');
      setValue('website', schoolToEdit.website || '');
      setValue('principal', schoolToEdit.principal || '');
      setValue('vp', schoolToEdit.vp || '');
      setValue('secretary', schoolToEdit.secretary || '');
      setValue('contactName', schoolToEdit.contactName || '');
      setValue('contactEmail', schoolToEdit.contactEmail || '');
      setValue('contactPhone', schoolToEdit.contactPhone || '');
      setValue('population', schoolToEdit.population || 0);
      setValue(
        'latitude',
        schoolToEdit.latitude !== null ? schoolToEdit.latitude : '',
      ); // Handle null values
      setValue(
        'longitude',
        schoolToEdit.longitude !== null ? schoolToEdit.longitude : '',
      ); // Handle null values
      setValue('organization', schoolToEdit.organization?._id || '');
    }
  }, [schoolToEdit, setValue]);

  const onSubmit = async (data) => {
    try {
      // Convert empty strings to null for latitude and longitude
      const formattedData = {
        ...data,
        population: data.population ? Number(data.population) : 0,
        latitude: data.latitude === '' ? null : Number(data.latitude),
        longitude: data.longitude === '' ? null : Number(data.longitude),
      };
      if (schoolToEdit) {
        await dispatch(
          updateSchool({ id: schoolToEdit._id, updates: formattedData }),
        );
      } else {
        await dispatch(createSchool(formattedData));
      }
      if (onClose) onClose();
    } catch (error) {
      setError(error.message || 'An error occurred');
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{schoolToEdit ? 'Edit School' : 'Add School'}</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            variant='outlined'
            margin='dense'
            required
            error={!!errors.organization}
          >
            <InputLabel id='organization-label'>Organization</InputLabel>
            <Controller
              name='organization'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId='organization-label'
                  label='Organization'
                  value={field.value || ''} // Ensure the selected value matches the current field value
                >
                  {organizations.map((org) => (
                    <MenuItem key={org._id} value={org._id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.organization?.message}</FormHelperText>
          </FormControl>
          <Controller
            name='schoolType'
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                variant='outlined'
                margin='dense'
                required
                error={!!errors.schoolType}
              >
                <InputLabel id='schoolType-label'>School Type</InputLabel>
                <Select
                  {...field}
                  labelId='schoolType-label'
                  label='School Type'
                  value={field.value || ''}
                >
                  <MenuItem value='Elementary'>Elementary</MenuItem>
                  <MenuItem value='Middle'>Middle</MenuItem>
                  <MenuItem value='High'>High</MenuItem>
                  <MenuItem value='K-12'>K-12</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
                <FormHelperText>{errors.schoolType?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name='deliveryDate'
            control={control}
            render={({ field }) => (
              <FormControl
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.deliveryDate}
              >
                <InputLabel>Delivery Date</InputLabel>
                <Select {...field} label='Delivery Date'>
                  <MenuItem key={'empty'} value={''}>
                    -- Not Set --
                  </MenuItem>
                  {daysOfWeek.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.deliveryDate?.message}</FormHelperText>
              </FormControl>
            )}
          />
          {/* <Controller
            name='schoolID'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='School ID'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.schoolID}
                helperText={errors.schoolID?.message}
              />
            )}
          /> */}
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='School Name'
                variant='outlined'
                margin='dense'
                fullWidth
                required
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name='shortName'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='School Short Name'
                variant='outlined'
                margin='dense'
                fullWidth
                required
                error={!!errors.shortName}
                helperText={errors.shortName?.message}
              />
            )}
          />

          <Controller
            name='address'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Address'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            )}
          />
          <Controller
            name='phone'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Phone'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            )}
          />
          <Controller
            name='fax'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Fax'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.fax}
                helperText={errors.fax?.message}
              />
            )}
          />
          <Controller
            name='website'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Website'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.website}
                helperText={errors.website?.message}
              />
            )}
          />
          <Controller
            name='principal'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Principal'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.principal}
                helperText={errors.principal?.message}
              />
            )}
          />
          <Controller
            name='vp'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Vice Principal'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.vp}
                helperText={errors.vp?.message}
              />
            )}
          />
          <Controller
            name='secretary'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Secretary'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.secretary}
                helperText={errors.secretary?.message}
              />
            )}
          />
          <Controller
            name='contactName'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Contact Person'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.contact}
                helperText={errors.contactName?.message}
              />
            )}
          />
          <Controller
            name='contactEmail'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Contact Email'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.contactEmail}
                helperText={errors.contactEmail?.message}
              />
            )}
          />
          <Controller
            name='contactPhone'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Contact Phone'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.contactPhone}
                helperText={errors.contactPhone?.message}
              />
            )}
          />
          <Controller
            name='population'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label='Population'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.population}
                helperText={errors.population?.message}
              />
            )}
          />
          <Controller
            name='latitude'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                step='0.0001'
                label='Latitude'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.latitude}
                helperText={errors.latitude?.message}
                InputProps={{
                  inputProps: {
                    min: -90,
                    max: 90,
                  },
                }}
              />
            )}
          />
          <Controller
            name='longitude'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                step='0.0001'
                label='Longitude'
                variant='outlined'
                margin='dense'
                fullWidth
                error={!!errors.longitude}
                helperText={errors.longitude?.message}
                InputProps={{
                  inputProps: {
                    min: -180,
                    max: 180,
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color='primary'
            variant='contained'
            disabled={isSubmitting}
          >
            {schoolToEdit ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity='error'>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SchoolForm;
