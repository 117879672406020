import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFoodOrders,
  createFoodOrder,
  updateFoodOrder,
  deleteFoodOrder,
} from "../features/foodOrders";
import { fetchSchools } from "../features/schools";
import FoodOrderForm from "../components/FoodOrder/FoodOrderForm";
import FoodOrderList from "../components/FoodOrder/List";
import { Button } from "@mui/material";

const Orders = () => {
  const dispatch = useDispatch();
  const foodOrders = useSelector((state) => state.foodOrders.foodOrders);
  // const status = useSelector((state) => state.foodOrders.status);
  // const error = useSelector((state) => state.foodOrders.error);
  const schools = useSelector((state) => state.schools.schools);
  const [selectedFoodOrder, setSelectedFoodOrder] = useState(null);
  const [openOrderForm, setOpenOrderForm] = useState(false);
  const [dialogMode, setDialogMode] = useState("create");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchFoodOrders());
      await dispatch(fetchSchools());
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const handleOpenOrderForm = (order = null) => {
    setSelectedFoodOrder(order);
    setDialogMode(order ? "update" : "create");
    setOpenOrderForm(true);
  };

  const handleCloseOrderForm = () => {
    setOpenOrderForm(false);
    setSelectedFoodOrder(null);
  };

  const handleSaveOrder = async (foodOrderData) => {
    if (dialogMode === "create") {
      await dispatch(createFoodOrder(foodOrderData));
    } else if (dialogMode === "update" && selectedFoodOrder) {
      await dispatch(
        updateFoodOrder({
          id: selectedFoodOrder._id,
          details: foodOrderData,
        }),
      );
    }
    handleCloseOrderForm();
  };

  const handleDeleteOrder = async (id) => {
    await dispatch(deleteFoodOrder(id));
    dispatch(fetchFoodOrders());
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>Orders</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenOrderForm()}
      >
        Add New Order
      </Button>

      <h3>Order List</h3>
      <FoodOrderList
        foodOrders={foodOrders}
        onEdit={handleOpenOrderForm}
        onDelete={handleDeleteOrder}
      />

      {/* Dialog for Add/Update Order */}
      {openOrderForm && (
        <FoodOrderForm
          open={openOrderForm}
          handleClose={handleCloseOrderForm}
          selectedFoodOrder={selectedFoodOrder}
          schools={schools}
          handleSave={handleSaveOrder}
        />
      )}
    </div>
  );
};

export default Orders;
