import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../api"; // Import the api instance

// Define initial state
const initialState = {
  schools: [],
  status: "idle",
  error: null,
};

// Define async thunks for CRUD operations
export const fetchSchools = createAsyncThunk(
  "schools/fetchSchools",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.schoolApi.getSchools();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Define async thunks for CRUD operations
export const fetchSchoolsByOrganization = createAsyncThunk(
  "schools/fetchSchoolsByOrganization",
  async (orgId, { rejectWithValue }) => {
    try {
      const response =
        await apiServices.schoolApi.getSchoolsPublicByOrgId(orgId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createSchool = createAsyncThunk(
  "schools/createSchool",
  async (school, { rejectWithValue }) => {
    try {
      const response = await apiServices.schoolApi.createSchool(school);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Define async thunk for bulk creation
export const createSchools = createAsyncThunk(
  "schools/createSchools",
  async ({ organization, schools }, { rejectWithValue }) => {
    try {
      const response = await apiServices.schoolApi.createSchools(
        organization,
        schools,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSchool = createAsyncThunk(
  "schools/updateSchool",
  async ({ id, updates }, { rejectWithValue }) => {
    try {
      const response = await apiServices.schoolApi.updateSchool(id, updates);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSchool = createAsyncThunk(
  "schools/deleteSchool",
  async (id, { rejectWithValue }) => {
    try {
      await apiServices.schoolApi.deleteSchool(`${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Define the slice
const schoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchools.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSchools.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.schools = action.payload;
      })
      .addCase(fetchSchools.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchSchoolsByOrganization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSchoolsByOrganization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.schools = action.payload;
      })
      .addCase(fetchSchoolsByOrganization.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(createSchool.fulfilled, (state, action) => {
        state.schools.push(action.payload);
      })
      .addCase(createSchool.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(updateSchool.fulfilled, (state, action) => {
        const index = state.schools.findIndex(
          (school) => school._id === action.payload._id,
        );
        if (index !== -1) {
          state.schools[index] = action.payload;
        }
      })
      .addCase(updateSchool.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteSchool.fulfilled, (state, action) => {
        state.schools = state.schools.filter(
          (school) => school._id !== action.payload,
        );
      })
      .addCase(deleteSchool.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(createSchools.fulfilled, (state, action) => {
        state.schools = [...state.schools, ...action.payload];
      })
      .addCase(createSchools.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const selectSchools = (state) => state.schools.schools;

export default schoolsSlice.reducer;
