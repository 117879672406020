const paletteClassic = {
  primary: {
    main: "#4285f4", // Google Blue
  },
  secondary: {
    main: "#ea4335", // Google Red
  },
  common: {
    white: "#ffffff",
  },
  background: {
    default: "#ffffff", // White background for general use
    paper: "#fafafa", // Light gray background for paper elements
    secondary: "#f5f5f5", // Light gray background for secondary elements
    panel: "#dbe6fd", // Blue gray background for panels
  },
  text: {
    primary: "#202124", // Dark gray text for primary content
    secondary: "#5f6368", // Medium gray text for secondary content
  },
  // Additional color customizations
  divider: "#e0e0e0", // Light gray for dividers
  action: {
    active: "#4285f4", // Google Blue for active elements
    hover: "#dbe6fd", // Light blue for hover state
    selected: "#c6dafc", // Light blue for selected state
  },
};

export default paletteClassic;
