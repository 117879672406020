import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Container, Button, TextField, Typography, Box } from '@mui/material';
import { fetchSchools } from '../../features/schools';
import { fetchMenuItems } from '../../features/menuItems';
import { createFoodOrder } from '../../features/foodOrders';
import '../../assets/scss/components/PremadeMealImport.scss'; // Import the SCSS file

const PremadeMealImport = ({ onDataImported }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSchools());
    dispatch(fetchMenuItems());
  }, [dispatch]);

  const menuItems = useSelector((state) =>
    Array.isArray(state.menuItems.menuItems) ? state.menuItems.menuItems : [],
  );

  const schools = useSelector((state) =>
    Array.isArray(state.schools.schools) ? state.schools.schools : [],
  );

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [textData, setTextData] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [isTextData, setIsTextData] = useState(false);
  const [isDataImported, setIsDataImported] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setIsTextData(false); // Ensure textarea mode is off when a file is selected
    }
  };

  const handleImport = () => {
    if (isTextData) {
      parseTextData(textData);
    } else if (!file) {
      setError('Please select a file.');
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;

        if (file.type === 'application/json') {
          try {
            const data = JSON.parse(text);
            onDataImported(data);
            setIsDataImported(true);
          } catch (err) {
            setError('Failed to parse JSON.');
          }
        } else if (file.type === 'text/csv') {
          Papa.parse(text, {
            header: true,
            complete: (result) => {
              setParsedData(processData(result.data));
              setIsDataImported(true);
            },
            error: () => {
              setError('Failed to parse CSV.');
            },
          });
        } else {
          setError('Unsupported file type.');
        }
      };
      reader.readAsText(file);
      setIsDataImported(true);
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate and extract school names from the headers
      const headers = Object.keys(parsedData[0]);
      const filteredHeaders = headers.filter((header) =>
        parsedData.some(
          (row) => row[header] !== undefined && row[header] !== '',
        ),
      );

      const validSchools = filteredHeaders
        .filter(
          (header) =>
            header !== 'Date' && header !== 'MenuItem' && isValidSchool(header),
        )
        .map((schoolShortName) =>
          schools.find((school) => school.shortName === schoolShortName),
        )
        .filter(Boolean); // Removes any undefined entries if no match is found

      // Loop through each row of data to create order details
      for (let i = 1; i < parsedData.length; i++) {
        const row = parsedData[i];
        const menuItemName = row['MenuItem'];

        const menuItem = menuItems.find((item) => item.name === menuItemName);

        if (!menuItem) {
          console.error(`Invalid menu item: ${menuItemName}`);
          continue;
        }

        // For each valid school, create an order
        for (let j = 0; j < validSchools.length; j++) {
          const school = validSchools[j];
          const quantity = row[school.shortName];

          if (quantity > 0) {
            // Only create orders for quantities greater than 0
            const orderDetails = {
              school, // Using the matched school object
              items: [
                {
                  menuItem: menuItem,
                  price: menuItem.price,
                  quantity: parseInt(quantity),
                },
              ], // Using the matched menuItem object
              totalAmount: parseFloat(quantity * menuItem.price), // Assuming quantity represents the total amount
              status: 'Pending', // Assuming a default status
              contactName: 'Imported', // Contact details left blank as requested
              contactEmail: 'Imported',
              contactPhone: 'Imported',
              orderDate: new Date(row['Date']),
              weekOf: new Date(row['Date']), // Assuming there's a "Week Of" column
            };
            await dispatch(createFoodOrder(orderDetails));
          }
        }
      }
    } catch (error) {
      console.error('Failed to save order: ', error);
    }
  };

  const parseTextData = (data) => {
    const delimiter = data.includes(',') ? ',' : '\t';
    Papa.parse(data, {
      delimiter: delimiter,
      header: true,
      complete: (result) => {
        setParsedData(processData(result.data));
        setError(null);
      },
      error: () => {
        setError('Failed to parse data.');
      },
    });
  };

  const handleTextAreaChange = (e) => {
    setTextData(e.target.value);
  };

  const handleTextAreaImport = () => {
    parseTextData(textData);
    setIsTextData(true);
    setIsDataImported(true); // Ensure isDataImported is set to true
  };

  const processData = (data) => {
    if (data.length === 0) return [];

    const filteredData = data.filter((row) =>
      Object.values(row).some((value) => value !== undefined && value !== ''),
    );

    let lastDate = null;
    return filteredData.map((row, index) => {
      if (index === 0 && row.Date) {
        lastDate = row.Date;
      } else if (row.Date) {
        lastDate = row.Date;
      } else if (lastDate) {
        row.Date = lastDate;
      }
      return row;
    });
  };

  const isValidMenuItem = (name) => {
    return menuItems.some(
      (item) => item.name.toLowerCase() === name.toLowerCase(),
    );
  };

  const isValidSchool = (name) => {
    return schools.some(
      (school) => school.shortName.toLowerCase() === name.toLowerCase(),
    );
  };
  return (
    <Container maxWidth='xl'>
      <Box my={2}>
        <input
          type='file'
          accept='.json, .csv'
          className='input-file'
          onChange={handleFileChange}
        />
      </Box>
      <Box my={2}>
        <Button variant='contained' color='primary' onClick={handleImport}>
          Import
        </Button>
      </Box>
      <Box my={2}>
        <TextField
          multiline
          rows={10}
          placeholder='Paste your CSV or tab-delimited data here...'
          value={textData}
          onChange={handleTextAreaChange}
          className='textarea'
          fullWidth
        />
      </Box>
      <Box my={2}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleTextAreaImport}
        >
          Import from Text Area
        </Button>
      </Box>
      {error && (
        <Box my={2}>
          <Typography color='error'>{error}</Typography>
        </Box>
      )}
      <Box my={2}>
        <Button
          variant='contained'
          color='secondary'
          disabled={!isDataImported}
          onClick={handleSubmit}
        >
          Load Data
        </Button>
      </Box>
      <Box my={2}>
        <Table
          data={parsedData}
          isValidMenuItem={isValidMenuItem}
          isValidSchool={isValidSchool}
        />
      </Box>
    </Container>
  );
};

const Table = ({ data, isValidMenuItem, isValidSchool }) => {
  if (data.length === 0) return <Typography>No data to display.</Typography>;

  const headers = Object.keys(data[0]);
  const filteredHeaders = headers.filter((header) =>
    data.some((row) => row[header] !== undefined && row[header] !== ''),
  );

  if (filteredHeaders.length === 0)
    return <Typography>No data to display.</Typography>;

  return (
    <Container maxWidth='xl'>
      <Box overflow='auto'>
        <table className='table'>
          <thead>
            <tr>
              <th>Valid</th>
              {filteredHeaders.map((header) => (
                <th key={header}>
                  {header}
                  {header !== 'Date' &&
                    header !== 'MenuItem' &&
                    (isValidSchool(header) ? (
                      <CheckIcon color='success' />
                    ) : (
                      <ClearIcon color='error' />
                    ))}
                </th>
              ))}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  {isValidMenuItem(row['MenuItem']) ? (
                    <CheckIcon color='success' />
                  ) : (
                    <ClearIcon color='error' />
                  )}
                </td>
                {filteredHeaders.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Container>
  );
};

export default PremadeMealImport;
