import React, { useState } from 'react';
import {
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import StepNavigationButtons from './StepNavigationButtons'; // Adjust the path as necessary
import { updateSchool } from '../../features/schools'; // Adjust the path as necessary

const SchoolContactStep = ({
  school,
  setActiveStep,
  contactName,
  setContactName,
  contactEmail,
  setContactEmail,
  contactPhone,
  setContactPhone,
}) => {
  const [useAssignedContact, setUseAssignedContact] = useState(false);
  const [updateSchoolContact, setUpdateSchoolContact] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const hasAssignedContact = Boolean(school.contact);

  // Validate the form fields
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Contact Name Validation
    if (!contactName.trim()) {
      newErrors.contactName = 'Contact Name is required';
      valid = false;
    }

    // Contact Email Validation
    if (
      contactEmail !== 'Imported' &&
      (!contactEmail.trim() || !/\S+@\S+\.\S+/.test(contactEmail))
    ) {
      newErrors.contactEmail = 'Invalid email address';
      valid = false;
    }

    // Contact Phone Validation
    const cleanPhone = contactPhone.replace(/\D/g, ''); // Remove all non-numeric characters
    if (
      contactEmail !== 'Imported' &&
      (cleanPhone.length < 9 || cleanPhone.length > 10)
    ) {
      newErrors.contactPhone = `Enter a valid 10 or 11-digit phone number: ${cleanPhone.length}`;
      valid = false;
    }

    setErrors(newErrors);
    setErrorMessage(valid ? '' : Object.values(newErrors).join(' '));
    return valid;
  };

  const handleFieldChange = (field, value) => {
    const trimmedValue = value.trim(); // Trim spaces from input
    if (field === 'contactName') setContactName(value);
    if (field === 'contactEmail') setContactEmail(trimmedValue);
    if (field === 'contactPhone') setContactPhone(trimmedValue);
    // Revalidate on change
    validateForm();
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1); // Navigate to the previous step
  };

  const handleNextStep = () => {
    if (validateForm()) {
      if (updateSchoolContact) {
        // Dispatch action to update school contact info
        dispatch(
          updateSchool({
            id: school._id,
            details: {
              ...school, // Spread existing school data
              contactName: contactName.trim(), // Update contact details
              contactEmail,
              contactPhone: contactPhone.replace(/\D/g, ''), // Store only the digits
            },
          }),
        );
      }
      setActiveStep((prevStep) => prevStep + 1); // Navigate to the next step if the form is valid
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Display the selected school */}
      <Typography variant='h6' gutterBottom>
        Order for {school?.name}
      </Typography>
      <Typography variant='subtitle2'>
        Enter Contact Information for this order
      </Typography>
      <FormControl fullWidth margin='normal'>
        {/* Show checkbox only if there's an assigned contact */}
        {hasAssignedContact && (
          <FormControlLabel
            control={
              <Checkbox
                checked={useAssignedContact}
                onChange={(e) => setUseAssignedContact(e.target.checked)}
              />
            }
            label='Use Assigned Contact'
          />
        )}

        {/* Form fields for contact details */}
        {!useAssignedContact && (
          <>
            <TextField
              label='Contact Name'
              value={contactName}
              onChange={(e) => handleFieldChange('contactName', e.target.value)}
              margin='normal'
              fullWidth
              error={!!errors.contactName}
              helperText={errors.contactName}
            />
            <TextField
              label='Contact Email'
              value={contactEmail}
              onChange={(e) =>
                handleFieldChange('contactEmail', e.target.value)
              }
              margin='normal'
              fullWidth
              type='email'
              error={!!errors.contactEmail}
              helperText={errors.contactEmail}
            />
            <TextField
              label='Contact Phone'
              value={contactPhone}
              onChange={(e) =>
                handleFieldChange('contactPhone', e.target.value)
              }
              margin='normal'
              fullWidth
              type='tel'
              error={!!errors.contactPhone}
              helperText={errors.contactPhone}
            />
          </>
        )}

        {/* Checkbox to update school contact with new entry */}
        {!useAssignedContact && (
          <FormControlLabel
            control={
              <Checkbox
                checked={updateSchoolContact}
                onChange={(e) => setUpdateSchoolContact(e.target.checked)}
              />
            }
            label='Update school contact with new entry'
          />
        )}
      </FormControl>

      <StepNavigationButtons
        previousStep={handlePreviousStep}
        nextStep={handleNextStep}
        nextDisabled={!!errorMessage} // Disable "Next" button if there are validation errors
        nextText={errorMessage || 'Next Step - Order Week Selection'}
      />
    </Box>
  );
};

export default SchoolContactStep;
