import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
  Box,
} from '@mui/material';

const SummaryTable = ({ data }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filter, setFilter] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase()),
  );

  const sortedData = filteredData.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Calculate totals
  const totalQuantity = sortedData.reduce(
    (sum, item) => sum + item.totalQuantity,
    0,
  );
  const totalCost = sortedData.reduce((sum, item) => sum + item.totalCost, 0);

  return (
    <Box>
      <TextField
        label='Filter Items'
        variant='outlined'
        fullWidth
        margin='normal'
        onChange={handleFilterChange}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'totalQuantity'}
                  direction={orderBy === 'totalQuantity' ? order : 'asc'}
                  onClick={() => handleRequestSort('totalQuantity')}
                >
                  Total Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'totalCost'}
                  direction={orderBy === 'totalCost' ? order : 'asc'}
                  onClick={() => handleRequestSort('totalCost')}
                >
                  Total Cost
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.totalQuantity}</TableCell>
                  <TableCell>${item.totalCost.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            {sortedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  No items found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter sx={{ bgcolor: 'primary.main', color: '#FFFFFF' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 800, color: 'common.white' }}>
                <strong>Total</strong>
              </TableCell>
              <TableCell sx={{ fontWeight: 800, color: 'common.white' }}>
                <strong>{totalQuantity.toLocaleString()}</strong>
              </TableCell>
              <TableCell sx={{ fontWeight: 800, color: 'common.white' }}>
                <strong>${totalCost.toLocaleString()}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default SummaryTable;
