import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import {
  Paper,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectMenuForSlot, removeMenuItem } from '../../features/menuSlice'; // Adjust path as needed

const getBackgroundColor = (mealOption, hasDropped, isOver) => {
  if (hasDropped) {
    switch (mealOption) {
      case 'main':
        return 'darksalmon'; // Darker shade for Main
      case 'fruitVeg':
        return 'darkseagreen'; // Darker shade for Fruit/Veg
      case 'snack':
        return 'lightgoldenrodyellow'; // Darker shade for Snack
      default:
        return 'lightgray'; // Default darker shade
    }
  } else if (isOver) {
    switch (mealOption) {
      case 'main':
        return 'darkred'; // Darker shade for Main when hovered
      case 'fruitVeg':
        return 'darkgreen'; // Darker shade for Fruit/Veg when hovered
      case 'snack':
        return 'darkgoldenrod'; // Darker shade for Snack when hovered
      default:
        return 'darkgray'; // Default darker shade when hovered
    }
  } else {
    switch (mealOption) {
      case 'main':
        return 'lightcoral'; // Color for Main
      case 'fruitVeg':
        return 'lightgreen'; // Color for Fruit/Veg
      case 'snack':
        return 'lightyellow'; // Color for Snack
      default:
        return 'white'; // Default color
    }
  }
};

const getBorderColor = (mealOption) => {
  switch (mealOption) {
    case 'main':
      return '#d32f2f'; // Complementary color for Main
    case 'fruitVeg':
      return '#388e3c'; // Complementary color for Fruit/Veg
    case 'snack':
      return '#fbc02d'; // Complementary color for Snack
    default:
      return 'grey'; // Default border color
  }
};

const DroppableSlot = ({
  currentDate,
  mealTime,
  mealOption,
  onDrop,
  children,
  sx,
  view,
  accept = 'MENU_ITEM',
}) => {
  const dispatch = useDispatch();
  const [hasDropped, setHasDropped] = useState(false);
  const [loading, setLoading] = useState(true);
  const justDate = new Date(currentDate).toISOString().split('T')[0];

  // Select the current items in the slot from Redux
  const droppedItems = useSelector((state) =>
    selectMenuForSlot(state, justDate, mealTime, mealOption),
  );

  const [{ isOver }, drop] = useDrop({
    accept,
    drop: useCallback(
      (item) => {
        if (onDrop) {
          if (!justDate || !mealTime || !mealOption) {
            console.error('Drop item missing day, mealTime, or mealOption:', {
              justDate,
              mealTime,
              mealOption,
            });
          } else {
            onDrop({ currentDate: justDate, mealTime, mealOption, item });
            setHasDropped(true);
          }
        }
      },
      [onDrop, justDate, mealTime, mealOption],
    ),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleRemoveItem = (itemId) => {
    dispatch(
      removeMenuItem({
        itemId,
        currentDate: justDate,
        mealTime,
        mealOption,
      }),
    );
  };

  useEffect(() => {
    if (droppedItems) {
      setLoading(false);
    } else {
      setLoading(true); // Set loading true if menuForSlot is not available
    }
  }, [droppedItems]);

  return (
    <Paper
      ref={drop}
      sx={{
        padding: 1,
        backgroundColor: getBackgroundColor(mealOption, hasDropped, isOver),
        border: `2px solid ${getBorderColor(mealOption)}`,
        minHeight: '50px',
        textAlign: 'center',
        ...sx,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {children}
          {droppedItems && droppedItems.length > 0 ? (
            <Box>
              {droppedItems.map((item, index) => (
                <Box
                  key={item._id + index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 1,
                    marginLeft: 2,
                  }}
                >
                  <Typography
                    variant={view === 'day' ? 'h6' : 'caption'}
                    sx={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      width: '100%',
                    }}
                  >
                    {item.name}
                  </Typography>
                  <IconButton
                    onClick={() => handleRemoveItem(item._id)}
                    size='small'
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              <UploadIcon
                sx={{ fontSize: 24, marginBottom: -3, color: 'grey' }}
              />
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default DroppableSlot;
