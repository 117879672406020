//file /src/api/menuAPI.js
import { remove } from 'lodash';
import apiBase from '../apiBase'; // Import the configured axios instance

// Define API functions for menu items
const menuApi = {
  // Fetch all menus
  fetchMenus: () => apiBase.get('/menus'),

  // Fetch a single menu item by ID
  getMenuById: (id) => apiBase.get(`/menus/${id}`),

  // Create a new menu item
  createMenu: (menuData, config) => apiBase.post('/menus', menuData, config),

  // Update an existing menu item by ID
  updateMenu: (id, updates, config) =>
    apiBase.put(`/menus/${id}`, updates, config),

  // Delete a menu item by ID
  deleteMenu: (id) => apiBase.delete(`/menus/${id}`),

  // Fetch menu items for a specific menu
  fetchMenuItems: () => apiBase.get('/menuItems'),

  // Save a weekly menu
  saveMenu: (menuData, config) =>
    apiBase.post('/menus/save-menu', menuData, config),

  removeMenuItem: ({ currentDate, mealTime, mealOption, item }) =>
    apiBase.post(`/menus/dlt-menu`, {
      currentDate,
      mealTime,
      mealOption,
      item,
    }),

  updateDateMenu: ({ currentDate, mealTime, mealOption, item }) =>
    apiBase.put(`/menus/${mealTime}/${mealOption}`, {
      currentDate,
      mealTime,
      mealOption,
      item,
    }),

  // Additional functions as needed, such as filtering, searching, etc.
};

// Export the menu API functions
export default menuApi;
