// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './features/users';
import schoolsReducer from './features/schools';
import foodOrdersReducer from './features/foodOrders';
import menuItemsReducer from './features/menuItems';
import organizationsReducer from './features/organizations';
import authReducer from './features/auth';
import reportingReducer from './features/reporting';
import rolesReducer from './features/roles';
import themeReducer from './features/themeSlice';
import menuReducer from './features/menuSlice';

// Define sanitizers
const actionSanitizer = (action) =>
  action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data
    ? { ...action, image: '<<LONG_IMAGE>>' }
    : action;

// const stateSanitizer = (state) =>
//   state.data ? { ...state, image: "<<LONG_IMAGE>>" } : state;

export const store = configureStore({
  reducer: {
    users: usersReducer,
    schools: schoolsReducer,
    foodOrders: foodOrdersReducer,
    menuItems: menuItemsReducer,
    organizations: organizationsReducer,
    auth: authReducer,
    reporting: reportingReducer,
    roles: rolesReducer,
    theme: themeReducer,
    menus: menuReducer,
  },
  devTools:
    process.env.NODE_ENV !== 'production'
      ? {
          actionSanitizer,
          // stateSanitizer,
          maxAge: 25,
          serialize: true, // Enable serialization
        }
      : false, // Disable devTools in production
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializable state check
    }),
});
