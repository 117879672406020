import React from 'react';
import { Box, Typography } from '@mui/material';

const OrderSummary = ({ regularOrders, veggieOrders }) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        mb: 1,
      }}
    >
      <Typography variant='h6'>Reg: {regularOrders}</Typography>
      <Typography variant='h6'>Veg: {veggieOrders}</Typography>
    </Box>
  );
};

export default OrderSummary;
