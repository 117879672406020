// src/pages/Register.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { register } from '../features/auth';
import RegisterForm from '../components/Register/form';
import { showToastError, showToastSuccess } from '../utils/toastUtils';

const Register = ({ nlsfOrgId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = nlsfOrgId || queryParams.get('orgId');
  const handleRegister = async (userData) => {
    try {
      await dispatch(register(userData)).unwrap();
      showToastSuccess('Registration successful');
      navigate('/login');
    } catch (err) {
      showToastError(err.message);
    }
  };

  return <RegisterForm organization={orgId} onRegister={handleRegister} />;
};

export default Register;
