import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, deleteUser } from '../features/users';
import UserForm from '../components/User/Form'; // Update the path as necessary
import UserList from '../components/User/List'; // Import the new UserList component
import { Button } from '@mui/material';
import ConfirmationDialog from '../components/ConfirmationDialog'; // Import the component

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const status = useSelector((state) => state.users.status);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUsers());
    }
  }, [dispatch, status]);

  const handleOpenAddForm = () => {
    setSelectedUser(null); // Ensure it's for adding new user
    setOpen(true);
  };

  const handleOpenEditForm = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
  };

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDeleteUser(userToDelete);
    setDialogOpen(false);
    setUserToDelete(null);
  };

  return (
    <>
      <div style={{ padding: '20px' }}>
        <h1>Users</h1>

        <div>
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpenAddForm}
            style={{ marginBottom: '16px' }}
          >
            Add New User
          </Button>
          <UserList
            users={users}
            status={status}
            onEdit={handleOpenEditForm}
            onDelete={handleDeleteClick}
          />
        </div>
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onConfirm={confirmDelete}
          title='Delete User'
          message={`Are you sure you want to delete the user?`}
        />
        <UserForm user={selectedUser} open={open} onClose={handleCloseForm} />
      </div>
    </>
  );
};

export default Users;
