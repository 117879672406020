import React from 'react';
import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const DeliverySchedulePDF = ({ schools }) => {
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(24);
    doc.text('School Delivery Schedule', 14, 15);
    doc.setFontSize(12);
    doc.text(
      'This is the delivery schedule for the schools in the district.',
      14,
      25,
    );

    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const formatPhone = (phone) => {
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone;
    };

    const getGoogleMapsLink = (address) => {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    daysOfWeek.forEach((day) => {
      const schoolsForDay = schools.filter(
        (school) => school.deliveryDate === day,
      );

      if (schoolsForDay.length > 0) {
        // Section Header for each day
        doc.setFontSize(14);
        doc.text(
          day,
          14,
          doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 35,
        );

        // Table for each day
        autoTable(doc, {
          startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 15 : 40,
          head: [['School Name', 'Population', 'Phone', 'Address']],
          body: schoolsForDay.map((school) => [
            school.name,
            school.population,
            formatPhone(school.phone),
            school.address,
          ]),
          didDrawCell: (data) => {
            if (data.column.index === 3 && data.cell.raw) {
              const link = getGoogleMapsLink(data.cell.raw);
              doc.link(
                data.cell.x,
                data.cell.y,
                data.cell.width,
                data.cell.height,
                { url: link },
              );
            }
          },
        });
      }
    });

    // Save the PDF
    doc.save('school_delivery_schedule.pdf');
  };

  return (
    <Button
      variant='contained'
      sx={{ width: '250px' }}
      color='primary'
      onClick={generatePDF}
    >
      Download Delivery Schedule PDF
    </Button>
  );
};

export default DeliverySchedulePDF;
