// src/theme/paletteTwilight.js

const paletteTwilight = {
  primary: {
    main: "#673ab7", // Deep Purple
  },
  secondary: {
    main: "#4527a0", // Dark Purple
  },
  background: {
    default: "#f3e5f5",
    paper: "#e1bee7",
  },
  text: {
    primary: "#311b92",
    secondary: "#512da8",
  },
};

export default paletteTwilight;
