import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiServices from '../../api'; // Adjust the import path if needed
import { fetchSchools } from '../../features/schools'; // Adjust the import path if needed
import {
  Select,
  MenuItem,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Box,
  Button,
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { jsPDF } from 'jspdf'; // For PDF generation
import 'jspdf-autotable'; // Optional for table support if needed
import { getNextMondays } from '../../utils/dateUtils'; // Adjust the import path if needed
import {
  generatePDF,
  formatItems,
  addHeader,
  addFooter,
} from '../../utils/pdfUtils';
import { formatPhone } from '../../utils/generalUtils'; // Adjust the import path if needed
import CategoryTable from './Tables/CategoryTable';
import { updateFoodOrderStatus } from '../../features/foodOrders'; // Adjust import path

// Define a color mapping for each day
const dayColors = {
  Monday: 'red',
  Tuesday: 'blue',
  Wednesday: 'green',
  Thursday: 'orange',
  Friday: 'purple',
  Saturday: 'brown',
  Sunday: 'pink',
};

const WeeklyGroceryOrders = ({ mode }) => {
  const dispatch = useDispatch();
  //lets connect to schools in redux
  const schools = useSelector((state) => state.schools.schools);
  //need to fetch the schools

  const [savedState, setSavedState] = useState(
    JSON.parse(localStorage.getItem('weeklyGroceryOrdersState')),
  );

  const [weekOf, setWeekOf] = useState(savedState?.weekOf || ''); // State for the selected week
  const [groceryOrders, setGroceryOrders] = useState(
    savedState?.groceryOrders || null,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mainTabValue, setMainTabValue] = useState(
    savedState?.mainTabValue || 0,
  ); // For main Tabs
  const [schoolTabValue, setSchoolTabValue] = useState(
    savedState?.schoolTabValue || 0,
  ); // For school Tabs
  const [organizationImage, setOrganizationImage] = useState(
    savedState?.organizationImage || null,
  );
  const [organizationName, setOrganizationName] = useState(
    savedState?.organizationName || '',
  );
  const [organizationAddress, setOrganizationAddress] = useState(
    savedState?.organizationAddress || '',
  );
  const [organizationPhone, setOrganizationPhone] = useState(
    savedState?.organizationPhone || '',
  );
  const [isPremade] = useState(savedState?.isPremade || mode === 'Premade');

  const [noOrdersFound, setNoOrdersFound] = useState(true);
  const [statusOptions] = useState([
    'New',
    'Pending',
    'Completed',
    'Modified',
    'Cancelled',
  ]);
  const includeNextWeek = true; // Include the next week in the list of Mondays
  const numberOfMondays = 5; // Number of Mondays to include
  const mondays = getNextMondays(numberOfMondays, includeNextWeek);
  const [selectedDays, setSelectedDays] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
    Sunday: false,
  });
  const [noWeekends, setNoWeekends] = useState(true);

  useEffect(() => {
    // only fetch schools if we don't have them
    if (!schools.length) dispatch(fetchSchools());
  }, [dispatch, schools]);

  useEffect(() => {
    setSavedState(JSON.parse(localStorage.getItem('weeklyGroceryOrdersState')));
  }, []);

  useEffect(() => {
    // Save the state to localStorage
    localStorage.setItem(
      'weeklyGroceryOrdersState',
      JSON.stringify({
        weekOf,
        groceryOrders,
        mainTabValue,
        schoolTabValue,
        organizationImage,
        organizationName,
        organizationAddress,
        organizationPhone,
        noOrdersFound,
        statusOptions,
        isPremade,
      }),
    );
  }, [
    weekOf,
    groceryOrders,
    mainTabValue,
    schoolTabValue,
    organizationImage,
    organizationName,
    organizationAddress,
    organizationPhone,
    noOrdersFound,
    statusOptions,
    isPremade,
  ]);

  useEffect(() => {
    const fetchGroceryOrders = async () => {
      if (weekOf) {
        setLoading(true);
        try {
          const response = await apiServices.reportApi.getWeeklyGroceryOrders(
            weekOf,
            mode,
          );

          setGroceryOrders(response.data);
          if (response.data.organizationDetails) {
            setOrganizationImage(
              response.data.organizationDetails?.imageInfo.url,
            );

            setOrganizationName(response.data.organizationDetails.name);
            setOrganizationAddress(response.data.organizationDetails.address);
            setOrganizationPhone(response.data.organizationDetails.phone);
            setNoOrdersFound(false);
          } else {
            setNoOrdersFound(true);
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchGroceryOrders();
  }, [weekOf, mode]);

  const handleWeekChange = (date) => {
    setWeekOf(date);
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSchoolTabChange = (event, newValue) => {
    setSchoolTabValue(newValue);
  };

  const handleDayChange = (event) => {
    const { name, checked } = event.target;
    setSelectedDays((prev) => ({ ...prev, [name]: checked }));
  };

  const downloadFullShoppingListPDF = () => {
    const premade = false;
    const items = formatItems({
      items: groceryOrders.totalShoppingList,
      premade,
    });
    //save selected days to a list:
    const days = Object.keys(selectedDays).filter((day) => selectedDays[day]);
    generatePDF({
      items,
      days,
      title: 'Full Shopping List',
      organizationImage,
      organizationName,
      organizationAddress,
      organizationPhone,
      weekOf,
      premade,
    });
  };

  const downloadSchoolPDF = (school, items, deliveryDate) => {
    const premade = false;
    const itemsList = formatItems({ items, premade });
    const days = null;
    generatePDF({
      items: itemsList,
      days,
      title: `Shopping List for ${school}`,
      organizationImage,
      organizationName,
      organizationAddress,
      organizationPhone,
      weekOf,
      premade,
      deliveryDate,
    });
  };

  const downloadAllSchoolPDFs = () => {
    const doc = new jsPDF();

    Object.entries(groceryOrders.perSchoolList).forEach(
      ([school, items], index) => {
        if (index > 0) doc.addPage(); // Add a new page for each school except the first one

        const itemsList = formatItems({
          items: items.categories,
          premade: false,
        });

        // Add header
        addHeader(
          doc,
          organizationImage,
          organizationName,
          organizationAddress,
          organizationPhone,
          weekOf,
        );

        // Set font size for title and add it to PDF
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        const titleWidth =
          (doc.getStringUnitWidth(`Shopping List for ${school}`) *
            doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const pageWidth = doc.internal.pageSize.getWidth();
        const xPos = (pageWidth - titleWidth) / 2;
        doc.text(`Shopping List for ${school}`, xPos, 30); // Adjust position as needed

        // Define checkbox properties
        const checkboxSize = 3; // Size of the checkbox

        //positions
        let y = 40; // Starting vertical position
        let itemGap = 5.8; // Vertical gap between lines
        let catGap = 6; // Vertical gap between categories
        let x = 13; // Starting horizontal position

        // Split content into lines
        const lines = itemsList.split('\n');
        let ticker = 0;
        lines.forEach((line) => {
          if (line.startsWith('CAT')) {
            if (ticker === 3) {
              x = 120;
              y = 40;
            }
            ticker++;
            // Add category (CAT) line with larger, bold text
            doc.setFontSize(13); // Larger size for categories
            doc.setFont('helvetica', 'bold');
            doc.text(line.slice(4).trim(), x, y); // Remove "CAT:" and add text
            doc.setFontSize(13); // Reset font size for next lines
            doc.setFont('helvetica', 'normal');
            y += itemGap + 1; // Increase vertical position
          } else if (line.startsWith('BOX')) {
            // Draw a checkbox for items starting with BOX
            doc.rect(x + 3, y - 3, checkboxSize, checkboxSize); // Draw the box
            doc.text(line.slice(4).trim(), x + 6 + checkboxSize, y); // Remove "BOX" and indent text
            y += itemGap; // Increase vertical position
          } else {
            // Handle any other lines if needed
            doc.text(line, x, y);
            y += catGap; // Increase vertical position
          }
        });

        // Add footer
        addFooter(doc, index + 1);
      },
    );

    // Make the filename unique to avoid caching issues
    const timestamp = new Date().toLocaleString();
    const uniqueFilename = `All Schools Shopping List - ${timestamp}.pdf`;

    // Save the PDF
    try {
      doc.save(uniqueFilename);
    } catch (error) {
      console.error('Failed to save PDF:', error);
    }
  };

  const downloadPremadeShoppingListPDF = () => {
    const premade = true;
    const items = formatItems({
      items: groceryOrders.totalShoppingList,
      premade,
    });
    const schoolItems = formatItems({
      schoolsItems: groceryOrders.perSchoolList,
      premade,
    });

    const days = null;
    if (items)
      generatePDF({
        items,
        days,
        title: 'Premade Order List',
        organizationImage,
        organizationName,
        organizationAddress,
        organizationPhone,
        weekOf,
        premade,
        schoolItems,
      });
  };

  const handleNoWeekendsChange = (event) => {
    if (noWeekends) {
      setSelectedDays((prev) => ({
        ...prev,
        Saturday: false,
        Sunday: false,
      }));
    }
    setNoWeekends(!noWeekends);
  };

  const sortItems = (items) => {
    return Object.entries(items)
      .sort(([a], [b]) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };

  const filteredDays = Object.keys(selectedDays).filter(
    (day) => selectedDays[day],
  );
  // Show only weekdays if noWeekends is true
  let displayDays;
  if (noWeekends) {
    displayDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  } else {
    displayDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
  }

  const handleStatusChange = (orderId, newStatus) => {
    dispatch(updateFoodOrderStatus({ orderId, status: newStatus }))
      .then(() => {
        // Optionally refetch or update local state if needed
        setGroceryOrders((prevOrders) => {
          // Update the state to reflect the change
          const updatedOrders = { ...prevOrders };
          Object.entries(updatedOrders.perSchoolList).forEach(
            ([school, details]) => {
              if (details.order._id === orderId) {
                details.order.status = newStatus;
              }
            },
          );
          return updatedOrders;
        });
      })
      .catch((error) => {
        console.error('Failed to update order status:', error);
      });
  };

  const calculateTotals = (data) => {
    const totals = {};

    const filteredDaysLowercase = filteredDays.map((day) => day.toLowerCase());

    Object.keys(data).forEach((category) => {
      if (category === 'dailyTotals') {
        Object.keys(data[category]).forEach((day) => {
          if (filteredDaysLowercase.includes(day.toLowerCase())) {
            Object.keys(data[category][day]).forEach((categoryName) => {
              if (!totals[categoryName]) {
                totals[categoryName] = {};
              }
              Object.keys(data[category][day][categoryName]).forEach(
                (menuItem) => {
                  if (!totals[categoryName][menuItem]) {
                    totals[categoryName][menuItem] = {
                      dailyQuantities: {
                        monday: 0,
                        tuesday: 0,
                        wednesday: 0,
                        thursday: 0,
                        friday: 0,
                        saturday: 0,
                        sunday: 0,
                        quantity: 0,
                      },
                      totalQuantity: 0,
                      bulkQuantity: 0,
                      size: data[category][day][categoryName][menuItem].menuItem
                        .size,
                    };
                  }
                  const quantity =
                    data[category][day][categoryName][menuItem].quantity;
                  const dayLower = day.toLowerCase();

                  totals[categoryName][menuItem].dailyQuantities[dayLower] +=
                    quantity;
                  totals[categoryName][menuItem].dailyQuantities.quantity +=
                    quantity;
                  totals[categoryName][menuItem].totalQuantity += quantity;
                  totals[categoryName][menuItem].bulkQuantity += quantity;
                },
              );
            });
          }
        });
      }
    });
    return totals;
  };

  if (groceryOrders) {
    groceryOrders.totalShoppingList = calculateTotals(
      groceryOrders,
      selectedDays,
    );
  }

  // Sort the array by name
  const sortedSchools = [...schools].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  // Define the chronological order of the days
  const dayOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // Group the schools by delivery day
  const groupedSchools = sortedSchools.reduce((acc, school) => {
    if (selectedDays[school.deliveryDate]) {
      if (!acc[school.deliveryDate]) {
        acc[school.deliveryDate] = [];
      }
      acc[school.deliveryDate].push(school);
    }
    return acc;
  }, {});

  // Sort the keys of the groupedSchools object based on the chronological order
  const orderedDays = Object.keys(groupedSchools).sort((a, b) => {
    return dayOrder.indexOf(a) - dayOrder.indexOf(b);
  });

  const schoolElements = orderedDays.map((deliveryDate) => (
    <Paper elevation={3} sx={{ p: 1, mb: 1, width: '100%' }} key={deliveryDate}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
      >
        <Typography variant='h6' style={{ color: dayColors[deliveryDate] }}>
          {deliveryDate}
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='center'
          gap={1}
          width='100%'
        >
          {groupedSchools[deliveryDate].map((school) => (
            <Typography key={school._id} variant='body2'>
              {school.name}
            </Typography>
          ))}
        </Box>
      </Box>
    </Paper>
  ));

  // Call the function and display the sorted schools

  return (
    <div>
      {loading ? (
        <Typography>Loading grocery orders...</Typography>
      ) : error ? (
        <Typography color='error'>Error: {error}</Typography>
      ) : (
        <>
          <div align='center'>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography variant='h4' sx={{ mb: 1 }}>
                  Weekly{' '}
                  {isPremade ? 'Pre-mades Meal Orders' : 'Bulk Grocery Orders'}{' '}
                </Typography>
                <Typography variant='subtitle1' sx={{ mb: 1 }}>
                  Show orders for the week of:
                </Typography>
                <div style={{ marginBottom: '16px' }}>
                  {mondays.map((date) => (
                    <Button
                      key={date}
                      variant={weekOf === date ? 'contained' : 'outlined'}
                      color='primary'
                      sx={{ m: 0.5 }}
                      onClick={() => handleWeekChange(date)}
                    >
                      {new Date(date).toLocaleDateString()}
                    </Button>
                  ))}
                </div>
              </Box>
              {weekOf && noOrdersFound ? (
                <Container sx={{ mt: 10 }}>
                  <Paper elevation={3} sx={{ p: 20, m: 10 }}>
                    <Box align='center'>
                      <Typography variant='h3'>
                        No orders found for the selected week
                      </Typography>
                    </Box>
                  </Paper>
                </Container>
              ) : (
                groceryOrders && (
                  <div>
                    {!isPremade ? (
                      <div>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={downloadFullShoppingListPDF}
                        >
                          Download Full Shopping List PDF
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{ ml: 2 }}
                          onClick={downloadAllSchoolPDFs}
                        >
                          Download All School PDFs
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {' '}
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={downloadPremadeShoppingListPDF}
                        >
                          Download Premade Order List PDF
                        </Button>
                      </div>
                    )}

                    {/* Main Tabs - All Groceries and Schools */}
                    <Tabs
                      value={mainTabValue}
                      onChange={handleMainTabChange}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='fullWidth'
                      sx={{
                        'mt': 2,
                        '& .MuiTabs-indicator': {
                          display: 'none', // Hide the default tab indicator
                        },
                        '& .MuiTab-root': {
                          'textTransform': 'none', // Keep the tab text case as is
                          'border': '1px solid #eee', // Add border to each tab
                          'borderRadius': '4px', // Slightly round the corners
                          'marginRight': '8px', // Space between tabs
                          '&.Mui-selected': {
                            backgroundColor: '#f5f5f5', // Background color for selected tab
                          },
                        },
                      }}
                    >
                      <Tab
                        label={
                          isPremade
                            ? 'Total Premade Orders'
                            : 'All Bulk Groceries'
                        }
                      />
                      <Tab
                        label={
                          isPremade
                            ? 'School Premade Orders'
                            : 'School Grocery List'
                        }
                      />
                    </Tabs>

                    {/* Render All Groceries Tab */}
                    {mainTabValue === 0 && (
                      <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                        <Typography variant='h5' align='center'>
                          Total {isPremade ? 'Order' : 'Shopping'} List
                        </Typography>

                        <Box
                          display='flex'
                          justifyContent='center'
                          alignItems={'center'}
                          flexDirection='column'
                          flexWrap='wrap'
                          sx={{ mb: 0 }}
                        >
                          <FormGroup row>
                            {displayDays.map((day) => (
                              <FormControlLabel
                                sx={{ mt: 0 }}
                                key={day}
                                control={
                                  <Checkbox
                                    checked={selectedDays[day]}
                                    onChange={handleDayChange}
                                    name={day}
                                  />
                                }
                                label={day}
                              />
                            ))}
                          </FormGroup>
                          <Link
                            variant='body1'
                            onClick={handleNoWeekendsChange}
                          >
                            {noWeekends ? 'Show' : 'Hide'} Weekends
                          </Link>
                        </Box>

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography variant='subtitle2'>
                              Schools Receiving Deliveries
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              display='flex'
                              // justifyContent='center'
                              flexDirection='row'
                              flexWrap='wrap'
                              gap={1}
                            >
                              {schoolElements}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Box
                          display='flex'
                          justifyContent='center'
                          flexDirection='row'
                          flexWrap='wrap'
                          gap={2}
                        >
                          {Object.entries(groceryOrders.totalShoppingList).map(
                            ([category, items]) => (
                              <CategoryTable
                                key={category}
                                category={category}
                                items={sortItems(items)}
                                isPremade={isPremade}
                              />
                            ),
                          )}
                        </Box>
                      </Paper>
                    )}

                    {/* Render Schools Tab */}
                    {mainTabValue === 1 && (
                      <div>
                        <Tabs
                          value={schoolTabValue}
                          onChange={handleSchoolTabChange}
                          indicatorColor='primary'
                          textColor='primary'
                          variant='scrollable'
                          scrollButtons='auto'
                          sx={{
                            'mt': 2,
                            '& .MuiTabs-indicator': {
                              display: 'none', // Hide the default tab indicator
                            },
                            '& .MuiTab-root': {
                              'textTransform': 'none', // Keep the tab text case as is
                              'border': '1px solid #eee', // Add border to each tab
                              'borderRadius': '4px', // Slightly round the corners
                              'marginRight': '8px', // Space between tabs
                              '&.Mui-selected': {
                                backgroundColor: '#f5f5f5', // Background color for selected tab
                              },
                            },
                          }}
                        >
                          {Object.keys(groceryOrders.perSchoolList).map(
                            (school) => (
                              <Tab key={school} label={school} />
                            ),
                          )}
                        </Tabs>

                        <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                          <Typography variant='h5' align='center' mb={2}>
                            Shopping List for{' '}
                            {
                              Object.keys(groceryOrders.perSchoolList)[
                                schoolTabValue
                              ]
                            }
                          </Typography>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant='h6' sx={{ mb: 1 }}>
                              Order Details
                            </Typography>
                            <Paper
                              elevation={1}
                              sx={{
                                p: 2,
                                border: '1px solid #ccc',
                                borderRadius: 1,
                              }}
                            >
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='space-between'
                                gap={2}
                              >
                                <Box>
                                  <Typography sx={{ textAlign: 'left' }}>
                                    <strong>Order Status:</strong>{' '}
                                    {
                                      groceryOrders.perSchoolList[
                                        Object.keys(
                                          groceryOrders.perSchoolList,
                                        )[schoolTabValue]
                                      ].order?.status
                                    }
                                  </Typography>
                                  <Typography sx={{ textAlign: 'left' }}>
                                    <strong>Contact Name:</strong>{' '}
                                    {
                                      groceryOrders.perSchoolList[
                                        Object.keys(
                                          groceryOrders.perSchoolList,
                                        )[schoolTabValue]
                                      ].order.contactName
                                    }
                                  </Typography>
                                  <Typography sx={{ textAlign: 'left' }}>
                                    <strong>Bulk Delivery Day:</strong>{' '}
                                    {
                                      groceryOrders.perSchoolList[
                                        Object.keys(
                                          groceryOrders.perSchoolList,
                                        )[schoolTabValue]
                                      ].deliveryDate
                                    }
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 0 }}>
                                  <Box
                                    display='flex'
                                    flexDirection='row'
                                    alignItems='center'
                                    gap={0}
                                  >
                                    <Typography
                                      variant='body2'
                                      sx={{
                                        mr: 0,
                                        pb: 0,
                                        transform: 'rotate(-90deg)',
                                        whiteSpace: 'nowrap',
                                        lineHeight: '0.85',
                                      }}
                                    >
                                      Change
                                      <br /> Status
                                    </Typography>
                                    <Select
                                      sx={{ mt: 0 }}
                                      value={
                                        groceryOrders.perSchoolList[
                                          Object.keys(
                                            groceryOrders.perSchoolList,
                                          )[schoolTabValue]
                                        ].order.status || ''
                                      }
                                      onChange={(e) =>
                                        handleStatusChange(
                                          groceryOrders.perSchoolList[
                                            Object.keys(
                                              groceryOrders.perSchoolList,
                                            )[schoolTabValue]
                                          ].order._id,
                                          e.target.value,
                                        )
                                      }
                                    >
                                      {statusOptions.map((status, index) => (
                                        <MenuItem key={index} value={status}>
                                          {status}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Box>
                                </Box>

                                <Box>
                                  <Typography sx={{ textAlign: 'right' }}>
                                    <strong>Email:</strong>{' '}
                                    {
                                      groceryOrders.perSchoolList[
                                        Object.keys(
                                          groceryOrders.perSchoolList,
                                        )[schoolTabValue]
                                      ].order.contactEmail
                                    }
                                  </Typography>
                                  <Typography sx={{ textAlign: 'right' }}>
                                    <strong>Phone:</strong>{' '}
                                    {formatPhone(
                                      groceryOrders.perSchoolList[
                                        Object.keys(
                                          groceryOrders.perSchoolList,
                                        )[schoolTabValue]
                                      ].order.contactPhone,
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Paper>
                          </Box>

                          <Box
                            display='flex'
                            justifyContent='center'
                            flexDirection='row'
                            flexWrap='wrap'
                            gap={2}
                          >
                            {Object.entries(
                              groceryOrders.perSchoolList[
                                Object.keys(groceryOrders.perSchoolList)[
                                  schoolTabValue
                                ]
                              ].categories,
                            ).map(([category, items]) => (
                              <CategoryTable
                                key={category}
                                category={category}
                                items={sortItems(items)}
                                isPremade={isPremade}
                                school={
                                  Object.keys(groceryOrders.perSchoolList)[
                                    schoolTabValue
                                  ]
                                }
                              />
                            ))}
                          </Box>
                          {!isPremade && (
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={() =>
                                downloadSchoolPDF(
                                  Object.keys(groceryOrders.perSchoolList)[
                                    schoolTabValue
                                  ],
                                  groceryOrders.perSchoolList[
                                    Object.keys(groceryOrders.perSchoolList)[
                                      schoolTabValue
                                    ]
                                  ].categories,
                                  groceryOrders.perSchoolList[
                                    Object.keys(groceryOrders.perSchoolList)[
                                      schoolTabValue
                                    ]
                                  ].deliveryDate,
                                )
                              }
                              sx={{ mt: 2 }}
                            >
                              Download School PDF
                            </Button>
                          )}
                        </Paper>
                      </div>
                    )}
                  </div>
                )
              )}
            </Paper>
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyGroceryOrders;
