import { createTheme } from "@mui/material/styles";

const lightTheme = (fontSize) =>
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#1976d2", // Blue
      },
      secondary: {
        main: "#dc004e", // Pink
      },
      background: {
        default: "#fafafa", // Light gray
        paper: "#ffffff", // White
      },
      text: {
        primary: "#000000", // Black
        secondary: "#333333", // Dark gray
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: `${fontSize * 2.25}px`, // Adjusted based on fontSize
      },
      h2: {
        fontSize: `${fontSize * 1.875}px`, // Adjusted based on fontSize
      },
      h3: {
        fontSize: `${fontSize * 1.5}px`, // Adjusted based on fontSize
      },
      body1: {
        fontSize: `${fontSize}px`, // Adjusted based on fontSize
      },
      body2: {
        fontSize: `${fontSize * 0.875}px`, // Adjusted based on fontSize
      },
      button: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff", // White background for tabs
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "#000000", // Black text color for tabs
            "&.Mui-selected": {
              color: "#1976d2", // Color for the selected tab
            },
          },
        },
      },
    },
  });

export default lightTheme;
