import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import apiServices from '../api'; // Import the api instance

// Define initial state
const initialState = {
  foodOrders: [],
  orders: {
    breakfast: {
      regular: [],
      veggie: [],
    },
    lunch: {
      regular: [],
      veggie: [],
    },
    snack: [],
    dinner: {
      regular: [],
      veggie: [],
    },
  },
  preMadeOrders: {}, // Add preMadeOrders to the state
  status: 'idle',
  error: null,
};

// Async thunk for fetching orders
export const fetchFoodOrders = createAsyncThunk(
  'foodOrders/fetchFoodOrders',
  async (loadHistoricOrders = false) => {
    const response =
      await apiServices.foodOrderApi.fetchFoodOrders(loadHistoricOrders);
    return response.data;
  },
);

// Async thunk for adding an order
export const createFoodOrder = createAsyncThunk(
  'foodOrders/createFoodOrder',
  async (orderFoodDetails) => {
    const response =
      await apiServices.foodOrderApi.createFoodOrder(orderFoodDetails);
    return response.data;
  },
);

// Async thunk for updating an order
export const updateFoodOrder = createAsyncThunk(
  'foodOrders/updateFoodOrder',
  async ({ id, details }) => {
    const response = await apiServices.foodOrderApi.updateFoodOrder(
      id,
      details,
    );
    return response.data;
  },
);

// Async thunk for updating the order status to 'Completed'
export const updateFoodOrderStatus = createAsyncThunk(
  'foodOrders/updateFoodOrderStatus',
  async ({ orderId, status }) => {
    const response = await apiServices.foodOrderApi.updateOrderStatus(
      orderId,
      status,
    );
    return response.data;
  },
);

// Async thunk for deleting an order
export const deleteFoodOrder = createAsyncThunk(
  'foodOrders/deleteFoodOrder',
  async (id) => {
    await apiServices.foodOrderApi.deleteFoodOrder(id);
    return id;
  },
);

// Async thunk for fetching premade orders
export const fetchPreMadeOrders = createAsyncThunk(
  'foodOrders/fetchPreMadeOrders',
  async (orderDate) => {
    const response =
      await apiServices.foodOrderApi.fetchPreMadeOrders(orderDate);
    return { data: response.data, orderDate };
  },
);

// Selectors
const selectFoodOrdersState = (state) => state.foodOrders;

export const selectAllFoodOrders = createSelector(
  selectFoodOrdersState,
  (foodOrdersState) => foodOrdersState.foodOrders,
);

// Define the slice
const foodOrdersSlice = createSlice({
  name: 'foodOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFoodOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFoodOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.foodOrders = action.payload;
      })
      .addCase(fetchFoodOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(createFoodOrder.fulfilled, (state, action) => {
        state.foodOrders.push(action.payload);
      })
      .addCase(createFoodOrder.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(updateFoodOrder.fulfilled, (state, action) => {
        const index = state.foodOrders.findIndex(
          (fo) => fo._id === action.payload._id,
        );
        if (index !== -1) {
          state.foodOrders[index] = {
            ...state.foodOrders[index],
            ...action.payload,
          };
        }
      })
      .addCase(updateFoodOrderStatus.fulfilled, (state, action) => {
        const index = state.foodOrders.findIndex(
          (fo) => fo._id === action.payload._id,
        );
        if (index !== -1) {
          state.foodOrders[index] = {
            ...state.foodOrders[index],
            status: action.payload.status,
          };
        }
      })
      .addCase(updateFoodOrder.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteFoodOrder.fulfilled, (state, action) => {
        state.foodOrders = state.foodOrders.filter(
          (fo) => fo._id !== action.payload,
        );
      })
      .addCase(deleteFoodOrder.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchPreMadeOrders.fulfilled, (state, action) => {
        const { data: preMadeOrders, orderDate: escapedDate } = action.payload;

        const orderDate = decodeURIComponent(escapedDate);

        // Loop through the fetched orders and store them in the desired format
        preMadeOrders.forEach((order) => {
          const { menuItemName, totalQuantity } = order;

          // Initialize the structure if not present
          if (!state.preMadeOrders[orderDate]) {
            state.preMadeOrders[orderDate] = {};
          }
          if (!state.preMadeOrders[orderDate][menuItemName]) {
            state.preMadeOrders[orderDate][menuItemName] = 0;
          }

          // Add the quantity to the existing amount
          state.preMadeOrders[orderDate][menuItemName] = totalQuantity;
        });
      })
      .addCase(fetchPreMadeOrders.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export default foodOrdersSlice.reducer;
