// src/pages/Organizations.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrganizations,
  deleteOrganization,
} from "../features/organizations";
import OrganizationForm from "../components/Organization/Form";
import OrganizationList from "../components/Organization/List"; // Import the new OrganizationList component
import {
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const Organizations = () => {
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state) => state.organizations.organizations
  );
  const status = useSelector((state) => state.organizations.status);
  const error = useSelector((state) => state.organizations.error);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchOrganizations());
    }
  }, [dispatch, status]);

  const handleDeleteOrganization = (id) => {
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteOrganization(deleteId))
      .unwrap()
      .catch((error) => {
        setSnackbarMessage(`Error: ${error.message}`);
        setOpenSnackbar(true);
      });
    setConfirmDelete(false);
  };

  const handleAddClick = () => {
    setSelectedOrganization(null); // Clear selected organization for adding a new one
    setShowForm(true);
  };

  const handleEditClick = (organization) => {
    setSelectedOrganization(organization); // Set selected organization for editing
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      {status === "loading" && <p>Loading...</p>}
      {status === "failed" && <p>Error: {error}</p>}
      {status === "succeeded" && (
        <div style={{ padding: "20px" }}>
          <h1>Organizations</h1>
          <Button variant="contained" color="primary" onClick={handleAddClick}>
            Add New Organization
          </Button>
          {showForm && (
            <OrganizationForm
              organization={selectedOrganization}
              open={showForm}
              onClose={handleCloseForm}
            />
          )}
          {status === "succeeded" && (
            <OrganizationList
              organizations={organizations}
              onEdit={handleEditClick}
              onDelete={handleDeleteOrganization}
            />
          )}

          {/* Snackbar for Error Messages */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {/* Confirmation Dialog for Deletion */}
          <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this organization?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDelete(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Organizations;
