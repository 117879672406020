import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';

import classicTheme from './theme/classicTheme';
import darkTheme from './theme/darkTheme';
import lightTheme from './theme/lightTheme';
import sunriseTheme from './theme/sunriseTheme';
import oceanTheme from './theme/oceanTheme';
import forestTheme from './theme/forestTheme';
import twilightTheme from './theme/twilightTheme';
import { fetchCurrentUser } from './features/auth';
import Home from './pages/Home';
import Import from './pages/Import';
import NotFound from './pages/NotFound';
import Users from './pages/Users';
import Reports from './pages/Reports';
import Depot from './pages/Depot';
import Schools from './pages/Schools';
import FoodOrders from './pages/FoodOrders';
import MenuItems from './pages/MenuItems';
import Organizations from './pages/Organizations';
import Navbar from './components/Navbar';
import Login from './components/Login';
import SetPassword from './pages/SetPassword';
import Register from './pages/Register';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/PrivateRoute';
import './assets/scss/styles.scss';

const App = () => {
  const [currentTheme, setCurrentTheme] = useState('Classic');
  const [fontSize, setFontSize] = useState(14); // Default font size

  const dispatch = useDispatch();
  const { user, status } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedTheme = localStorage.getItem('selectedTheme');
    const storedFontSize = localStorage.getItem('fontSize');
    if (storedTheme) {
      setCurrentTheme(storedTheme);
    }
    if (storedFontSize) {
      setFontSize(parseInt(storedFontSize, 10));
    }
  }, []);

  const themes = {
    Classic: classicTheme,
    Sunrise: sunriseTheme,
    Ocean: oceanTheme,
    Forest: forestTheme,
    Twilight: twilightTheme,
    Light: lightTheme,
    Dark: darkTheme,
  };

  const theme = themes[currentTheme](fontSize);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    localStorage.setItem('selectedTheme', theme);
  };

  const handleFontSizeChange = (size) => {
    setFontSize(size);
    localStorage.setItem('fontSize', size);
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <Navbar
              onThemeChange={handleThemeChange}
              onFontSizeChange={handleFontSizeChange}
            />
            <div style={{ paddingTop: '60px' }}>
              <Routes>
                <Route path='/' element={<Home />} />
                {/* <Route path="/Test" element={<Test />} /> */}
                <Route path='/reset/:token' element={<SetPassword />} />
                <Route
                  path='/login'
                  element={!user ? <Login /> : <Navigate to='/' />}
                />
                <Route path='/register' element={<Register />} />
                <Route
                  path='/users'
                  element={<PrivateRoute element={<Users />} />}
                />
                <Route
                  path='/schools'
                  element={<PrivateRoute element={<Schools />} />}
                />
                <Route
                  path='/import'
                  element={<PrivateRoute element={<Import />} />}
                />
                <Route
                  path='/depot'
                  element={<PrivateRoute element={<Depot />} />}
                />
                <Route
                  path='/reports'
                  element={<PrivateRoute element={<Reports />} />}
                />
                <Route
                  path='/orders'
                  element={<PrivateRoute element={<FoodOrders />} />}
                />
                <Route
                  path='/menu-items'
                  element={<PrivateRoute element={<MenuItems />} />}
                />
                <Route
                  path='/organizations'
                  element={<PrivateRoute element={<Organizations />} />}
                />
                <Route
                  path='/nlsf'
                  element={<Register nlsfOrgId='66bc0a39e18126f19f6dbec6' />}
                />

                <Route path='*' element={<NotFound />} />
              </Routes>
              <ToastContainer />
            </div>
          </Router>
        </DndProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
