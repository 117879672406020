import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const PremadeSelections = ({
  filteredMenuItems,
  items,
  handleSetQuantity,
  handleSetQualityAllDays,
}) => {
  const [showWeekends, setShowWeekends] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [allDaysQuantity, setAllDaysQuantity] = useState({});
  const [, setTotalQuantity] = useState(0);

  useEffect(() => {
    const initialQuantities = {};
    let total = 0;
    items.forEach((item) => {
      const itemQuantities = {};
      daysOfWeek.forEach((day) => {
        const dayKey = `${day.toLowerCase()}Quantity`;
        itemQuantities[dayKey] = item[dayKey] || 0;
        total += itemQuantities[dayKey];
      });
      initialQuantities[item.menuItem] = itemQuantities;
    });
    setQuantities(initialQuantities);
    setTotalQuantity(total);
  }, [items]);

  const handleQuantityChange = (menuItemId, day, value) => {
    const numericValue = parseInt(value, 10) || 0;
    handleSetQuantity(menuItemId, day, numericValue);
    setQuantities((prev) => ({
      ...prev,
      [menuItemId]: {
        ...prev[menuItemId],
        [day]: numericValue,
      },
    }));
  };

  const handleToggleWeekends = (event) => {
    setShowWeekends(event.target.checked);
  };

  const handleSetAllDaysQuantity = (menuItemId) => {
    const quantity = parseInt(allDaysQuantity[menuItemId], 10) || 0;
    const updatedQuantities = { ...quantities[menuItemId] };
    let total = 0;
    daysOfWeek.forEach((day) => {
      if (
        showWeekends ||
        (!showWeekends && day !== "Saturday" && day !== "Sunday")
      ) {
        const dayKey = `${day.toLowerCase()}Quantity`;
        updatedQuantities[dayKey] = quantity;
        total += quantity;
      }
    });

    setTotalQuantity(total);
    handleSetQualityAllDays(menuItemId, updatedQuantities, total);

    setQuantities((prev) => ({
      ...prev,
      [menuItemId]: updatedQuantities,
    }));
  };

  const handleBlur = (menuItemId, day, value) => {
    if (isNaN(value) || value === "") {
      handleQuantityChange(menuItemId, day, 0);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="block">
          <Typography variant="h6">Pre-Made Selections</Typography>
        </Box>
        <Box display="block">
          <Typography variant="body1" textAlign={"center"}>
            Select the quantity of premade meals needed for each day.
            <br />
            Note that there are vegetarian options.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={showWeekends}
              onChange={handleToggleWeekends}
              color="primary"
            />
          }
          label="Show Weekends"
        />
      </Box>
      {filteredMenuItems.map((item) => {
        const itemQuantity = quantities[item._id] || {};

        return (
          <Paper
            elevation={6}
            sx={{
              pt: 1,
              mb: 1,
            }}
            key={item._id}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                {item.name}
              </Typography>
              <Grid
                container
                spacing={1}
                wrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                {daysOfWeek?.map((day) => {
                  if (
                    !showWeekends &&
                    (day === "Saturday" || day === "Sunday")
                  ) {
                    return null;
                  }
                  const dayKey = `${day.toLowerCase()}Quantity`;
                  return (
                    <Grid item key={day} sx={{ mt: 0 }}>
                      <TextField
                        type="number"
                        label={day}
                        sx={{ width: "80px" }}
                        value={itemQuantity[dayKey] || 0}
                        onChange={(e) =>
                          handleQuantityChange(item._id, dayKey, e.target.value)
                        }
                        onBlur={(e) =>
                          handleBlur(item._id, dayKey, e.target.value)
                        }
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </Grid>
                  );
                })}
                <Grid item key={`set-all-${item._id}`} sx={{ my: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                  >
                    <TextField
                      type="number"
                      label="Set All Days"
                      sx={{ width: "100px", mr: 2, mt: 2 }}
                      value={allDaysQuantity[item._id] || 0}
                      onChange={(e) =>
                        setAllDaysQuantity((prev) => ({
                          ...prev,
                          [item._id]: e.target.value,
                        }))
                      }
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleSetAllDaysQuantity(item._id)}
                    >
                      Set All
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        );
      })}
    </Box>
  );
};

export default PremadeSelections;
