import React from "react";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import StepNavigationButtons from "./StepNavigationButtons"; // Adjust the path as necessary

const SchoolSelectionStep = ({ school, setSchool, setActiveStep, schools }) => {
  const handlePreviousStep = () => {
    setActiveStep(0);
  };

  const handleNextStep = () => {
    if (school) {
      setActiveStep(1);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        value={school || null} // Ensure value is null if no school is selected
        onChange={(event, newValue) => {
          setSchool(newValue);
        }}
        options={schools || []}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField {...params} label="Select School" />
        )}
        isOptionEqualToValue={(option, value) => option._id === value?._id}
      />
      <StepNavigationButtons
        previousStep={handlePreviousStep}
        nextStep={handleNextStep}
        nextDisabled={!school} // Disable "Next" button if no school is selected
        nextText="Next Step - Contact Info" // Update button text
      />
    </FormControl>
  );
};

export default SchoolSelectionStep;
