import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DayBox from './DayBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getMonthDates = (year, month) => {
  const dates = [];
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const startDayOfWeek = firstDayOfMonth.getDay();

  for (let i = startDayOfWeek; i > 0; i--) {
    const date = new Date(year, month, -i + 1);
    dates.push({ date, currentMonth: false });
  }

  for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
    const date = new Date(year, month, i);
    dates.push({ date, currentMonth: true });
  }

  const totalDays = dates.length;
  for (let i = totalDays; i % 7 !== 0; i++) {
    const date = new Date(year, month + 1, i - totalDays + 1);
    dates.push({ date, currentMonth: false });
  }

  return dates;
};

const getWeekDates = (currentDate) => {
  const dates = [];
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - startOfWeek.getDay());

  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    dates.push({
      date,
      currentMonth: date.getMonth() === currentDate.getMonth(),
    });
  }
  return dates;
};

const isWeekend = (date) => date.getDay() === 0 || date.getDay() === 6;

const Calendar = ({ drawerOpen }) => {
  const savedState = JSON.parse(localStorage.getItem('calendarState'));
  const now = new Date();
  const [currentDate, setCurrentDate] = useState(
    savedState?.currentDate ? new Date(savedState?.currentDate) : now,
  );
  const [view, setView] = useState(savedState?.view || 'month');
  const [noWeekends, setNoWeekends] = useState(savedState?.noWeekends || true);

  useEffect(() => {
    // Save state to localStorage
    const stateToSave = {
      currentDate: currentDate?.toISOString(),
      view,
      noWeekends,
    };
    localStorage.setItem('calendarState', JSON.stringify(stateToSave));
  }, [currentDate, view, noWeekends]);

  const year = currentDate?.getFullYear();
  const month = currentDate?.getMonth();

  const monthDates = getMonthDates(year, month);
  const weekDates = getWeekDates(currentDate);
  const dayDate = view === 'day' ? currentDate : null;

  const currentMonthName = currentDate?.toLocaleString('default', {
    month: 'long',
  });

  const handlePrev = () => {
    setCurrentDate(
      new Date(
        currentDate.setDate(
          currentDate.getDate() -
            (view === 'month' ? 30 : view === 'week' ? 7 : 1),
        ),
      ),
    );
  };

  const handleNext = () => {
    setCurrentDate(
      new Date(
        currentDate.setDate(
          currentDate.getDate() +
            (view === 'month' ? 30 : view === 'week' ? 7 : 1),
        ),
      ),
    );
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        marginBottom: 5,
      }}
    >
      {/* View Switcher */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button
          variant={view === 'month' ? 'contained' : 'outlined'}
          onClick={() => setView('month')}
        >
          <CalendarTodayIcon />
          Month View
        </Button>
        <Button
          variant={view === 'week' ? 'contained' : 'outlined'}
          onClick={() => setView('week')}
        >
          Week View
        </Button>
        <Button
          variant={view === 'day' ? 'contained' : 'outlined'}
          onClick={() => setView('day')}
        >
          Day View
        </Button>
      </Box>

      {/* Navigation */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          mb: 2,
          mr: drawerOpen ? 20 : 0,
          ml: drawerOpen ? 20 : 0,
        }}
      >
        <Button onClick={handlePrev}>
          <ArrowBackIcon />
          Previous
        </Button>
        <Typography variant='h4'>
          {view === 'month'
            ? `${currentMonthName} ${year}`
            : view === 'week'
              ? `Week View - ${currentMonthName}`
              : view === 'day'
                ? currentDate.toDateString()
                : ''}
        </Typography>
        <Button onClick={handleNext}>
          Next <ArrowForwardIcon />
        </Button>
      </Box>

      {/* Return to Today Button */}
      <Box sx={{ mb: 2 }} display={'flex'} gap={2}>
        <Button onClick={handleToday} variant='contained'>
          Return to Today
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={noWeekends}
              onChange={(e) => setNoWeekends(e.target.checked)}
            />
          }
          label='No Weekends'
        />
      </Box>

      {/* Days of the Week */}
      {(view === 'month' || view === 'week') && (
        <Grid container spacing={5} justifyContent='center'>
          {days.map((day) =>
            noWeekends && (day === 'Saturday' || day === 'Sunday') ? null : (
              <Grid item xs={noWeekends ? 2.4 : 1.7} key={day}>
                <Typography variant='subtitle1' align='center'>
                  {day}
                </Typography>
              </Grid>
            ),
          )}
        </Grid>
      )}

      {/* Calendar View */}
      <Grid
        container
        spacing={1}
        sx={{ marginRight: 10, marginLeft: 10 }}
        justifyContent='center'
      >
        {view === 'month' &&
          monthDates.map(({ date, currentMonth }) =>
            noWeekends && isWeekend(date) ? null : (
              <Grid item xs={noWeekends ? 2.4 : 1.7} key={date}>
                <DayBox
                  noDrop={noWeekends && isWeekend(date)} // Set noDrop based on noWeekends and whether the date is a weekend
                  currentDate={
                    new Date(date).toLocaleDateString().split(',')[0]
                  }
                  isHighlighted={
                    date.getDate() === new Date().getDate() && currentMonth
                  }
                  view={view}
                  highlightToday={
                    date.toDateString() === new Date().toDateString()
                  }
                  sx={{
                    backgroundColor: currentMonth ? 'transparent' : '#a0a0a0', // Different background for non-current month dates
                  }}
                />
              </Grid>
            ),
          )}

        {view === 'week' &&
          weekDates.map(({ date }) =>
            noWeekends && isWeekend(date) ? null : (
              <Grid item xs={noWeekends ? 2.4 : 1.7} key={date}>
                <DayBox
                  noDrop={noWeekends && isWeekend(date)}
                  currentDate={
                    new Date(date).toLocaleDateString().split(',')[0]
                  }
                  isHighlighted={date.getDate() === new Date().getDate()}
                  view={view}
                  highlightToday={
                    date.toDateString() === new Date().toDateString()
                  }
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
            ),
          )}
        {view === 'day' && (
          <Grid item xs={10}>
            <DayBox
              noDrop={noWeekends && isWeekend(dayDate)}
              currentDate={new Date(dayDate).toLocaleDateString().split(',')[0]}
              isHighlighted={false}
              view={view}
              highlightToday={
                dayDate.toDateString() === new Date().toDateString()
              }
              sx={{ width: '100%', height: '100%' }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default Calendar;
