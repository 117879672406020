import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrganization,
  updateOrganization,
  fetchOrganizations,
} from '../../features/organizations';
import { fetchSchools } from '../../features/schools';
import { fetchUsers } from '../../features/users';
import ImageUpload from '../Images/ImageUpload';

const OrganizationForm = ({ organization, open, onClose }) => {
  const dispatch = useDispatch();
  const schools = useSelector((state) => state.schools.schools || []); // Ensure default value
  const schoolsStatus = useSelector((state) => state.schools.status || 'idle'); // Ensure default value

  const users = useSelector((state) => state.users.users || []); // Ensure default value
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (open) {
      dispatch(fetchSchools());
      dispatch(fetchUsers());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (organization) {
      setName(organization.name);
      setAddress(organization.address);
      setPhone(organization.phone);
      setEmail(organization.email);
      setSelectedSchools(
        organization.schools.map((school) => school._id) || [],
      );
      setSelectedUserIds(organization.contacts || []);
      setSelectAll(
        (organization.schools || []).length === (schools || []).length,
      );
    } else {
      setName('');
      setAddress('');
      setPhone('');
      setEmail('');
      setSelectedSchools([]);
      setSelectedUserIds([]);
      setSelectAll(false);
      setSelectedFile(null);
    }
  }, [organization, schools]);

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedSchools(schools.map((school) => school._id));
    } else {
      setSelectedSchools([]);
    }
  };

  const handleSchoolChange = (event) => {
    const value = event.target.value;
    setSelectedSchools(value);
    setSelectAll(value.length === schools.length);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('schools', JSON.stringify(selectedSchools));
    formData.append('contacts', JSON.stringify(selectedUserIds));
    let imageInfo;

    if (!selectedFile && organization !== null) {
      imageInfo = organization.imageInfo;
      formData.append('imageInfo', JSON.stringify(imageInfo));
    } else if (selectedFile) {
      formData.append('image', selectedFile);
    }

    const action = organization
      ? updateOrganization({ id: organization._id, formData })
      : createOrganization(formData);

    dispatch(action)
      .unwrap()
      .catch((error) =>
        console.error('Failed to process organization:', error),
      );
    dispatch(fetchOrganizations());
    onClose();
  };

  // Check if schools is defined and sort alphabetically by name
  const sortedSchools = (schools || [])
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {organization ? 'Edit Organization' : 'Add Organization'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin='dense'
            label='Organization Name'
            type='text'
            fullWidth
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            margin='dense'
            label='Address'
            type='text'
            fullWidth
            value={address || ''}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <TextField
            margin='dense'
            label='Phone'
            type='tel'
            fullWidth
            value={phone || ''}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <TextField
            margin='dense'
            label='Email'
            type='email'
            fullWidth
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <ImageUpload
            onFileSelect={setSelectedFile}
            initialImageURL={organization?.imageInfo?.url}
          />

          {organization && ( // only show schools if editing an organization
            <FormControl fullWidth margin='dense'>
              <InputLabel>Schools</InputLabel>
              <Select
                multiple
                value={selectedSchools}
                onChange={handleSchoolChange}
                renderValue={(selected) =>
                  sortedSchools
                    .filter((school) => selected.includes(school._id))
                    .map((school) => school.name)
                    .join(', ')
                }
                label='Schools'
              >
                {schoolsStatus === 'loading' ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : sortedSchools.length > 0 ? (
                  sortedSchools.map((school) => (
                    <MenuItem key={school._id} value={school._id}>
                      {school.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Schools Available</MenuItem>
                )}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                }
                label='Select All Schools'
              />
            </FormControl>
          )}
          <FormControl fullWidth margin='dense'>
            <InputLabel>Contacts</InputLabel>
            <Select
              multiple
              value={selectedUserIds}
              onChange={(e) => setSelectedUserIds(e.target.value)}
              renderValue={(selected) =>
                users
                  .filter((user) => selected.includes(user._id))
                  .map((user) => user.name)
                  .join(', ')
              }
              label='Contacts'
            >
              {users.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Cancel
            </Button>
            <Button type='submit' color='primary'>
              {organization ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationForm;
