import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { showToastError, showToastSuccess } from '../utils/toastUtils';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { useParams } from 'react-router-dom';
import { setPassword, validateToken } from '../features/users'; // Import the setPassword thunk
import { Paper, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SetPassword = () => {
  const { token } = useParams(); // Use useParams to get the token from the URL
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState(''); // Renamed for clarity
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const hasValidated = useRef(false); // Track if validation has been performed
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };
  useEffect(() => {
    if (hasValidated.current) return; // Skip if already validated

    const validate = async () => {
      try {
        await dispatch(validateToken(token)).unwrap();
      } catch (error) {
        if (!hasValidated.current) {
          setIsTokenValid(false);
          setErrorMessage(
            'Invalid or expired token. Please request a new password.',
          );

          showToastError('Invalid or expired token');
        }
      } finally {
        hasValidated.current = true; // Mark as validated
      }
    };

    validate();
  }, [dispatch, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isTokenValid) {
      showToastError('Invalid or expired token');
      return;
    }
    if (newPassword !== confirmPassword) {
      showToastError('Passwords do not match');
      return;
    }

    if (newPassword.length < 6) {
      showToastError('Password must be at least 6 characters long');
      return;
    }

    setLoading(true);

    try {
      // Dispatch the setPassword thunk with the token and new password
      await dispatch(setPassword({ token, newPassword })).unwrap();
      showToastSuccess('Password has been set successfully.');
      navigate('/login'); // Redirect to the login page
    } catch (error) {
      // Extracting the error message from the server response
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message;

      console.error('Error setting password:', errorMessage);

      if (errorMessage.includes('invalid or has expired')) {
        setIsTokenValid(false);
        setErrorMessage(
          'Password reset token is invalid or has expired. Please request a new one.',
        );
      } else {
        showToastError(`Failed to set password. Error: ${errorMessage}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='50vh'
      bgcolor='background.default'
    >
      <Paper elevation={3} sx={{ padding: 4, width: 400 }}>
        {isTokenValid ? (
          <>
            <Typography variant='h5' gutterBottom>
              Set Your New Password
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label='New Password'
                  type='password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label='Confirm Password'
                  type='password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Box>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                disabled={loading}
              >
                {loading ? 'Setting Password...' : 'Set Password'}
              </Button>
            </form>
          </>
        ) : (
          <>
            <Box mt={2}>
              <Typography variant='h5' sx={{ mb: 3 }}>
                Password Request Has Expired
              </Typography>
              Please contact Support to have your password request resent or try
              password reset from the login page.
            </Box>

            <Button
              onClick={handleLoginRedirect}
              sx={{ my: 2 }}
              variant='contained'
              color='primary'
              fullWidth
            >
              Return to Login
            </Button>
            <Typography color='error'>{errorMessage}</Typography>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default SetPassword;
