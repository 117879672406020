import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices from '../api'; // Import the api instance

// Define initial state
const initialState = {
  weeklyPremadeSummary: {},
  weeklyGroceryOrders: {},
  weeklyShoppingLists: {},
  premadesPortioning: {},
  historicActivityReport: {},
  status: 'idle',
  error: null,
};

// Async thunks for fetching report data
export const fetchWeeklyPremadeSummary = createAsyncThunk(
  'reporting/fetchWeeklyPremadeSummary',
  async ({ startDate, endDate }) => {
    const response = await apiServices.reportApi.getWeeklyPremadeSummary(
      startDate,
      endDate,
    );
    return response.data;
  },
);

export const fetchWeeklyGroceryOrders = createAsyncThunk(
  'reporting/fetchWeeklyGroceryOrders',
  async ({ startDate, endDate }) => {
    const response = await apiServices.reportApi.getWeeklyGroceryOrders(
      startDate,
      endDate,
    );
    return response.data;
  },
);

export const fetchWeeklyShoppingLists = createAsyncThunk(
  'reporting/fetchWeeklyShoppingLists',
  async ({ startDate, endDate }) => {
    const response = await apiServices.reportApi.getWeeklyShoppingLists(
      startDate,
      endDate,
    );
    return response.data;
  },
);

export const fetchPremadesPortioning = createAsyncThunk(
  'reporting/fetchPremadesPortioning',
  async ({ startDate, endDate }) => {
    const response = await apiServices.reportApi.getPremadesPortioning(
      startDate,
      endDate,
    );
    return response.data;
  },
);

export const fetchHistoricActivityReport = createAsyncThunk(
  'reporting/fetchHistoricActivityReport',
  async ({ startDate, endDate, showAll }) => {
    const response = await apiServices.reportApi.getHistoricActivityReport(
      startDate,
      endDate,
      showAll,
    );
    return response.data;
  },
);

export const fetchOrdersAndSpendingReport = createAsyncThunk(
  'reporting/fetchOrdersAndSpendingReport',
  async ({ startDate, endDate, showAll }) => {
    const response = await apiServices.reportApi.getOrdersAndSpendingReport(
      startDate,
      endDate,
      showAll,
    );
    return response.data;
  },
);

export const fetchMenuItemsReport = createAsyncThunk(
  'reporting/fetchMenuItemsReport',
  async ({ startDate, endDate, showAll }) => {
    const response = await apiServices.reportApi.getMenuItemsReport(
      startDate,
      endDate,
      showAll,
    );
    return response.data;
  },
);

// Define the slice
const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyPremadeSummary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWeeklyPremadeSummary.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.weeklyPremadeSummary = action.payload;
      })
      .addCase(fetchWeeklyPremadeSummary.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchWeeklyGroceryOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWeeklyGroceryOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.weeklyGroceryOrders = action.payload;
      })
      .addCase(fetchWeeklyGroceryOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchWeeklyShoppingLists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWeeklyShoppingLists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.weeklyShoppingLists = action.payload;
      })
      .addCase(fetchWeeklyShoppingLists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchPremadesPortioning.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPremadesPortioning.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.premadesPortioning = action.payload;
      })
      .addCase(fetchPremadesPortioning.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchHistoricActivityReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHistoricActivityReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.historicActivityReport = action.payload;
      })
      .addCase(fetchHistoricActivityReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchMenuItemsReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenuItemsReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.menuItemsReport = action.payload;
      })
      .addCase(fetchMenuItemsReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchOrdersAndSpendingReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersAndSpendingReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ordersAndSpendingReport = action.payload; // Correctly assign the report data
      })
      .addCase(fetchOrdersAndSpendingReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default reportingSlice.reducer;
