import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  TextField,
} from '@mui/material';

const OrdersAndSpendingTable = ({ data, onSortChange }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [filter, setFilter] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSortChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = useMemo(
    () =>
      data.filter(
        (row) =>
          row.date.includes(filter) ||
          row.orders.toString().includes(filter) ||
          row.amountSpent.toString().includes(filter),
      ),
    [data, filter],
  );

  const sortedData = useMemo(() => {
    const comparator = (a, b) => {
      if (orderBy === 'amountSpent' || orderBy === 'orders') {
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      } else if (orderBy === 'date') {
        return order === 'asc'
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
      return 0; // Fallback
    };

    return [...filteredData].sort(comparator);
  }, [filteredData, order, orderBy]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [sortedData, page, rowsPerPage]);

  return (
    <Paper>
      <TextField
        variant='outlined'
        label='Search'
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin='normal'
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'orders'}
                  direction={orderBy === 'orders' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'orders')}
                >
                  Orders
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'amountSpent'}
                  direction={orderBy === 'amountSpent' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'amountSpent')}
                >
                  Amount Spent
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.orders}</TableCell>
                <TableCell>{`$${row.amountSpent.toLocaleString()}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default OrdersAndSpendingTable;
