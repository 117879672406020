import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRoles } from '../utils/authUtils'; // Import the custom hook

const Home = () => {
  const { user } = useSelector((state) => state.auth);

  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  return (
    <div>
      {/* Main Content */}
      <div style={contentStyle}>
        <h1>Welcome to LunchTimeExpress</h1>
        <p>Your go-to platform for school meal ordering.</p>

        {user && (
          <div style={buttonContainerStyle}>
            {(isAdmin || isLTEAdmin) && (
              <Link to='/users' style={linkStyle}>
                <button style={buttonStyle}>View Users</button>
              </Link>
            )}
            <Link to='/schools' style={linkStyle}>
              <button style={buttonStyle}>View Schools</button>
            </Link>
            <Link to='/orders' style={linkStyle}>
              <button style={buttonStyle}>View / Place Orders</button>
            </Link>
            {(isAdmin || isManager) && (
              <Link to='/menu-items' style={linkStyle}>
                <button style={buttonStyle}>View Menu Items</button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const contentStyle = {
  textAlign: 'center',
  padding: '20px',
  paddingTop: '60px', // Ensure content isn't hidden behind the fixed Navbar
};

const buttonContainerStyle = {
  marginTop: '20px',
};

const linkStyle = {
  textDecoration: 'none',
  margin: '0 10px',
};

const buttonStyle = {
  padding: '10px 20px',
  fontSize: '16px',
  color: '#fff',
  backgroundColor: '#007bff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  margin: '5px',
};

export default Home;
