const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        textTransform: "none",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        "&:hover": {
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
        color: "#202124",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: "collapse",
        width: "100%",
        backgroundColor: "#ffffff",
        color: "#202124",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: "#f5f5f5",
        color: "#202124",
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: "1px solid #e0e0e0",
        padding: "16px",
        textAlign: "left",
        color: "#202124",
        "&:first-of-type": {
          paddingLeft: "24px",
        },
        "&:last-of-type": {
          paddingRight: "24px",
        },
      },
    },
  },
};

export default components;
