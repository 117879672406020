// ../components/Reports.js
import React, { useState, useEffect } from 'react';
import { Container, ToggleButtonGroup, ToggleButton } from '@mui/material';
import WeeklyGroceryOrders from '../components/Reporting/WeeklyGroceryOrders';
import MenuBuilder from './MenuBuilder';

const Depot = () => {
  const [value, setValue] = useState(0);

  // Clear the localStorage for the weeklyGroceryOrdersState key
  const ClearLocalStorage = (key) => {
    localStorage.removeItem(key);

    return true;
  };

  // Retrieve stored state from localStorage on component mount
  useEffect(() => {
    const savedValue = localStorage.getItem('reportTabValue');
    if (savedValue !== null) {
      setValue(parseInt(savedValue, 10));
    }
  }, []);

  // Update localStorage whenever the tab value changes
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
      localStorage.setItem('reportTabValue', newValue);
    }
  };

  return (
    <Container sx={{ m: 1 }} maxWidth='100%'>
      <ToggleButtonGroup
        sx={{
          'mt': 2,
          '& .MuiToggleButton-root': {
            'textTransform': 'none', // Keep the button text case as is
            'border': '1px solid #eee', // Add border to each button
            'borderRadius': '4px', // Slightly round the corners
            'marginRight': '8px', // Space between buttons
            'padding': '8px 16px', // Padding inside each button
            '&.Mui-selected': {
              backgroundColor: '#f5f5f5', // Background color for selected button
            },
          },
        }}
        value={value}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value={0}>Weekly Grocery Orders</ToggleButton>
        <ToggleButton value={1}>Weekly Pre-made Summary</ToggleButton>
        <ToggleButton value={2}>Pre-made Menu Builder</ToggleButton>
      </ToggleButtonGroup>

      {value === 0 && ClearLocalStorage('weeklyGroceryOrdersState') && (
        <WeeklyGroceryOrders mode='Bulk' />
      )}
      {value === 1 && <WeeklyGroceryOrders mode='Premade' />}
      {value === 2 && <MenuBuilder />}
    </Container>
  );
};

export default Depot;
