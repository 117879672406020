import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const PeriodSelector = ({ period, handlePeriodChange }) => {
  return (
    <FormControl fullWidth variant='outlined' margin='normal' sx={{ flex: 2 }}>
      <InputLabel htmlFor='period'>Select Period</InputLabel>
      <Select
        id='period'
        value={period}
        onChange={handlePeriodChange}
        label='Select Period'
      >
        <MenuItem value='schoolYear'>This School Year</MenuItem>
        <MenuItem value='lastWeek'>Last Week</MenuItem>
        <MenuItem value='lastMonth'>Last Month</MenuItem>
        <MenuItem value='lastSchoolYear'>Last School Year</MenuItem>
        <MenuItem value='all'>Show All</MenuItem>
        <MenuItem value='custom'>Custom</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PeriodSelector;
