// MapComponent.js
import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

function MapComponent({ center }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAjBxQGnL790rQIUmSGkeI-L46NW_-pt2A",
  });

  const [, setMap] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Add markers or other map components here */}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
}

export default MapComponent;
