import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersAndSpendingReport } from '../../features/reporting';
import OrdersAndSpendingTable from './Tables/OrdersAndSpendingTable';
import { Box, TextField, Paper, CircularProgress, Alert } from '@mui/material';
import OrdersAndSpendingChart from './Charts/OrdersAndSpendingChart';
import PeriodSelector from './PeriodSelector'; // Adjust the import path as needed
import { getDateRange } from '../../utils/dateUtils';

const OrdersAndSpendingReport = () => {
  const dispatch = useDispatch();
  const { ordersAndSpendingReport, status, error } = useSelector(
    (state) => state.reporting,
  );

  const [showAll, setShowAll] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [period, setPeriod] = useState('schoolYear');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showCustom, setShowCustom] = useState(false);

  // Set initial date range and trigger data fetching
  useEffect(() => {
    if (period !== 'custom') {
      const { start, end } = getDateRange(period);
      setStartDate(start.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    }
  }, [period]); // Trigger when period changes

  // Fetch report whenever date range or "show all" flag changes
  useEffect(() => {
    if (startDate && endDate)
      dispatch(fetchOrdersAndSpendingReport({ startDate, endDate, showAll }));
  }, [dispatch, startDate, endDate, showAll]);

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setPeriod(value);

    if (value === 'custom') {
      setShowCustom(true);
      setShowAll(false);
    } else {
      setShowCustom(false);
      setShowAll(value === 'all');
    }
  };
  const sortData = (data, sortBy, sortOrder) => {
    if (!data) return [];

    const comparator = (a, b) => {
      if (sortBy === 'amountSpent' || sortBy === 'orders') {
        return sortOrder === 'asc'
          ? a[sortBy] - b[sortBy]
          : b[sortBy] - a[sortBy];
      } else if (sortBy === 'date') {
        return sortOrder === 'asc'
          ? new Date(a.orderDate) - new Date(b.orderDate)
          : new Date(b.orderDate) - new Date(a.orderDate);
      }
      return 0; // Fallback
    };

    return [...data].sort(comparator);
  };

  // Sort data whenever sortBy or sortOrder changes
  const sortedData = React.useMemo(() => {
    return sortData(ordersAndSpendingReport, sortBy, sortOrder);
  }, [ordersAndSpendingReport, sortBy, sortOrder]);

  const handleSort = (field, sortingOrder) => {
    setSortBy(field);
    setSortOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <PeriodSelector
          period={period}
          handlePeriodChange={handlePeriodChange}
        />
        <Box mb={2} display='flex'>
          {showCustom && (
            <>
              <TextField
                type='date'
                label='Start Date'
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                type='date'
                label='End Date'
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </>
          )}
        </Box>

        {status === 'loading' && <CircularProgress />}
        {status === 'failed' && <Alert severity='error'>{error}</Alert>}

        {status === 'succeeded' && Array.isArray(ordersAndSpendingReport) && (
          <>
            {/* Render the chart component */}
            <OrdersAndSpendingChart data={sortedData} />
            <OrdersAndSpendingTable
              data={sortedData}
              onSortChange={handleSort}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default OrdersAndSpendingReport;
