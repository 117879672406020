import { createTheme } from "@mui/material/styles";

const darkTheme = (fontSize) =>
  createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#bb86fc", // Purple
      },
      secondary: {
        main: "#03dac6", // Teal
      },
      background: {
        default: "#121212", // Dark gray
        paper: "#1e1e1e", // Darker gray
      },
      text: {
        primary: "#ffffff", // White
        secondary: "#bbbbbb", // Light gray
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: `${fontSize * 2.25}px`, // Adjusted based on fontSize
      },
      h2: {
        fontSize: `${fontSize * 1.875}px`, // Adjusted based on fontSize
      },
      h3: {
        fontSize: `${fontSize * 1.5}px`, // Adjusted based on fontSize
      },
      body1: {
        fontSize: `${fontSize}px`, // Adjusted based on fontSize
      },
      body2: {
        fontSize: `${fontSize * 0.875}px`, // Adjusted based on fontSize
      },
      button: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: "#1e1e1e", // Darker background for tabs
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "#ffffff", // White text color for tabs
            "&.Mui-selected": {
              color: "#bb86fc", // Color for the selected tab
            },
          },
        },
      },
    },
  });

export default darkTheme;
