// features/roles.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../api"; // Import the configured axios instance

// Define initial state
const initialState = {
  roles: [],
  status: "idle",
  error: null,
};

// Async thunk for fetching roles
export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.roleApi.fetchRoles();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for adding a role
export const addRole = createAsyncThunk("roles/addRole", async (role) => {
  const response = await apiServices.roleApi.addRole(role); // Use the axios instance
  return response.data;
});

// Async thunk for updating a role
export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async ({ id, ...updates }) => {
    const response = await apiServices.roleApi.updateRole(id, updates);
    return response.data;
  }
);

// Async thunk for deleting a role
export const deleteRole = createAsyncThunk("roles/deleteRole", async (id) => {
  await apiServices.roleApi.deleteRole(id); // Use the axios instance
  return id;
});

// Create the slice
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        state.roles.push(action.payload);
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        const index = state.roles.findIndex(
          (role) => role._id === action.payload._id
        );
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.roles = state.roles.filter((role) => role._id !== action.payload);
      });
  },
});

// Export the reducer
export default rolesSlice.reducer;
