// src/theme/paletteSunrise.js

const paletteSunrise = {
  primary: {
    main: "#ff9800", // Vibrant Orange
  },
  secondary: {
    main: "#ff5722", // Deep Orange
  },
  background: {
    default: "#fff3e0",
    paper: "#ffe0b2",
  },
  text: {
    primary: "#4e342e",
    secondary: "#5d4037",
  },
};

export default paletteSunrise;
