import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../features/auth';
import { resendPasswordEmail } from '../../features/users';
import { useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { isValidEmail } from '../../utils/validationUtils';
import { showToastError, showToastSuccess } from '../../utils/toastUtils';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [recoverEmail, setRecoverEmail] = useState(''); // State for password recovery
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
  const [emailError, setEmailError] = useState(''); // State for email validation error
  const [error, setError] = useState(''); // Add error state

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(''); // Clear previous error

    dispatch(login(credentials))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        if (err && err.message.includes('401')) {
          setError('Incorrect email or password');
          showToastError(`Incorrect email or password`);
        } else {
          showToastError(`Login failed. Error: ${err.message}`);
        }
      });
  };

  const handleRecoverPassword = () => {
    if (!recoverEmail) {
      showToastError('Please enter your email address.');
      setEmailError('Email address is required.');
      return;
    }

    if (!isValidEmail(recoverEmail)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    const message =
      'Check your email. If the address you entered is valid, you will receive a link to update your password.';
    dispatch(resendPasswordEmail(recoverEmail))
      .unwrap()
      .then(() => {
        showToastSuccess(message);
        setRecoverEmail(''); // Clear the email input after success
        setDialogOpen(false); // Close the dialog
        setEmailError(''); // Clear any previous email errors
      })
      .catch((error) => {
        showToastSuccess(message);
        // Do not show specific error messages, just close the dialog
        setRecoverEmail(''); // Clear the email input after an attempt
        setDialogOpen(false); // Close the dialog
        setEmailError(''); // Clear any previous email errors
        // Optionally log the error for debugging purposes
        //console.error("Error during password recovery:", error);
      });
  };

  return (
    <Container component='main' maxWidth='xs' sx={{ marginTop: 8 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='h5' component='h1'>
          Login
        </Typography>
        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            type='email'
            autoComplete='email'
            autoFocus
            value={credentials.email}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }} // Force label to shrink
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            label='Password'
            name='password'
            type='password'
            autoComplete='current-password'
            value={credentials.password}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }} // Force label to shrink
          />
          {error && (
            <Typography color='error' variant='body2' sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ mt: 2 }}
          >
            Login
          </Button>
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            sx={{ mt: 2 }}
            onClick={() => setDialogOpen(true)} // Open the dialog
          >
            Recover Password
          </Button>
        </Box>
        {/* Registration Link */}
        <Typography variant='body2' sx={{ mt: 2 }}>
          If you don't have an account,{' '}
          <Link to='/register' style={{ color: '#1976d2' }}>
            Register here
          </Link>
        </Typography>
        {/* Display profile image if available */}
      </Box>

      {/* Dialog for password recovery */}

      <Dialog
        open={dialogOpen}
        maxWidth='sm'
        fullWidth
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Recover Password</DialogTitle>
        <DialogContent>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='recover-email'
            label='Email Address'
            name='recover-email'
            type='email'
            value={recoverEmail}
            onChange={(e) => {
              setRecoverEmail(e.target.value);
              if (emailError) {
                setEmailError(''); // Clear error when user starts typing
              }
            }}
            error={!!emailError} // Mark text box red if there's an error
            helperText={emailError} // Display error message
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRecoverPassword}>Send Recovery Email</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
