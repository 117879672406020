import React, { useState } from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material"; // Import MenuIcon
import { formatCurrency } from "../../utils/currencyUtils";

const FoodMenuItem = ({
  item,
  onEdit,
  onDelete,
  imagePreview,
  handleMenuClick,
}) => {
  const [isActive, setIsActive] = useState(item.active || false);

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
    onEdit({ ...item, active: !isActive }); // Notify parent about the change
  };

  return (
    <Card
      key={item._id}
      style={{
        width: "200px",
        height: "200px",
        position: "relative",
        paddingTop: "16px",
        border: "1px solid #ddd",
        overflow: "hidden", // Ensure child elements do not overflow the card
      }}
    >
      {imagePreview ? (
        <CardMedia
          component="img"
          image={imagePreview}
          alt={item.name}
          style={{
            maxHeight: "100px", // Set max height to 100px
            objectFit: "scale-down", // Ensure the image covers the area while preserving aspect ratio
          }}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          color="grey.600"
          style={{ height: "100px" }} // Set a height to align with the image placeholder
        >
          No Image
        </Box>
      )}
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative", // Ensure the content area can position items absolutely
          padding: "16px",
          overflow: "hidden", // Ensure no content overflows
        }}
      >
        <Tooltip title={item.name} arrow>
          <Typography
            variant="subtitle1"
            component="div"
            style={{
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Add ellipses when text overflows
              width: "150px",
            }}
          >
            {item.name}
          </Typography>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="more"
          onClick={(event) => handleMenuClick(event, item)}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="body2" // Use a smaller variant like body2
          component="div"
          style={{
            fontWeight: 300, // Lighter font weight
            color: "#555", // Optional: lighter color for better contrast
          }}
        >
          {item.size}
        </Typography>
        <Typography
          variant="body2" // Use a smaller variant like body2
          component="div"
          style={{
            fontWeight: 300, // Lighter font weight
            color: "#555", // Optional: lighter color for better contrast
          }}
        >
          {formatCurrency(item.price)}
        </Typography>
        <Typography
          variant="body2" // Use a smaller variant like body2
          component="div"
          style={{
            fontWeight: 300, // Lighter font weight
            color: "#555", // Optional: lighter color for better contrast
          }}
        >
          {item.category}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Active"
          style={{
            marginTop: "8px",
          }}
        />
      </CardContent>
    </Card>
  );
};

export default FoodMenuItem;
