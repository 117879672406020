import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const SchoolOrdersChart = ({ data }) => {
  const chartData = {
    labels: data.map((entry) => entry.school),
    datasets: [
      {
        label: "Total Orders",
        data: data.map((entry) => entry.totalOrders),
        borderColor: "#4285f4",
        backgroundColor: "#dbe6fd",
        fill: false,
        tension: 0.1,
        yAxisID: "y1", // Associate with the second Y-axis
      },
      {
        label: "Total Amount Spent",
        data: data.map((entry) => entry.totalAmount),
        borderColor: "#ea4335",
        backgroundColor: "#f8d7da",
        fill: false,
        tension: 0.1,
        yAxisID: "y", // Associate with the first Y-axis
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "School Orders and Spending",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Total Amount Spent ($)",
        },
      },
      y1: {
        beginAtZero: true,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false, // Prevent grid lines from overlapping
        },
        title: {
          display: true,
          text: "Total Orders",
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default SchoolOrdersChart;
