// src/utils/toastUtils.js

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToastError = (message) => {
  toast.error(message);
};

export const showToastSuccess = (message) => {
  toast.success(message);
};
