import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Paper } from '@mui/material';
import { fetchPreMadeOrders } from '../../features/foodOrders';

import MealSection from './MealSection';
import { addMenuItemToSlot } from '../../features/menuSlice';

const DayBox = ({
  currentDate,
  menuDates,
  isHighlighted,
  highlightToday,
  sx,
  noDrop,
  view,
}) => {
  const dispatch = useDispatch();
  const preMadeOrders = useSelector((state) => state.foodOrders.preMadeOrders);

  const getQuantitiesForCurrentDate = useCallback(() => {
    const ordersForDate = preMadeOrders[currentDate] || {};

    return {
      breakfastRegularOrders: ordersForDate['Pre-made Breakfast'] || 0,
      lunchRegularOrders: ordersForDate['Pre-made Lunch'] || 0,
      breakfastVeggieOrders:
        ordersForDate['Pre-made Breakfast Vegetarian'] || 0,
      lunchVeggieOrders: ordersForDate['Pre-made Lunch Vegetarian'] || 0,
    };
  }, [preMadeOrders, currentDate]);

  const [quantities, setQuantities] = useState(getQuantitiesForCurrentDate());

  useEffect(() => {
    setQuantities(getQuantitiesForCurrentDate());
  }, [preMadeOrders, currentDate, setQuantities, getQuantitiesForCurrentDate]);

  useEffect(() => {
    // Check if pre-made orders for the current date exist in the store
    if (!preMadeOrders[currentDate]) {
      const escapedDate = encodeURIComponent(currentDate);
      dispatch(fetchPreMadeOrders(escapedDate)); // Fetch pre-made orders if not already in the store
    }
  }, [dispatch, currentDate, preMadeOrders]);

  const handleDrop = ({ mealTime, mealOption, currentDate, item }) => {
    if (!currentDate || !mealTime || !mealOption || !item) {
      console.error('Drop item missing required information:', {
        currentDate,
        mealTime,
        mealOption,
        item,
      });
    } else {
      dispatch(
        addMenuItemToSlot({
          currentDate,
          mealTime, // Breakfast, Lunch, Dinner
          mealOption, // main-reg, mainVeg, fruitVeg, snack
          item: item.id,
        }),
      );
    }
  };

  return (
    <Paper
      elevation={5}
      sx={{
        p: 2,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: isHighlighted ? '#f0f0f0' : '#fff',
        border: highlightToday ? '2px solid #ff5722' : '1px solid grey',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          p: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Typography variant='subtitle2'></Typography>
        <Typography variant='subtitle2'>
          {new Date(currentDate).getDate()}
        </Typography>
      </Box>

      {noDrop ? (
        <Box sx={{ mb: 1, flex: 1 }}>
          <Typography variant='subtitle1' sx={{ mb: 1 }}>
            Weekend - No Orders
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: view === 'day' ? 'row' : 'column',
            gap: 1,
            flex: 1,
          }}
        >
          <MealSection
            key={`meal-breakfast`}
            mealTime='breakfast'
            regularOrders={quantities.breakfastRegularOrders}
            veggieOrders={quantities.breakfastVeggieOrders}
            currentDate={currentDate}
            menuDates={menuDates}
            onDropItem={({ mealTime, mealOption, currentDate, item }) =>
              handleDrop({ mealTime, mealOption, currentDate, item })
            }
            view={view}
          />
          <MealSection
            key={`meal-lunch`}
            mealTime='lunch'
            regularOrders={quantities.lunchRegularOrders}
            veggieOrders={quantities.lunchVeggieOrders}
            currentDate={currentDate}
            menuDates={menuDates}
            onDropItem={({ mealTime, mealOption, currentDate, item }) =>
              handleDrop({ mealTime, mealOption, currentDate, item })
            }
            view={view}
          />
        </Box>
      )}
    </Paper>
  );
};

export default DayBox;
