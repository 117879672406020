import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices from '../api'; // Import the combined API services

// Define initial state
const initialState = {
  users: [],
  status: 'idle',
  userStatus: 'idle',
  error: null,
  currentUser: null,
};

// Async thunk for fetching users
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.getUsers(); // Use the userApi methods
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

export const requestNewToken = createAsyncThunk(
  'users/requestNewToken',
  async (email, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.requestNewToken(email); // Call the request new token API
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

export const validateToken = createAsyncThunk(
  'auth/validateToken',
  async (token) => {
    const response = await apiServices.authApi.validateToken(token); // Replace with your API call
    return response.data;
  },
);

// Async thunk for fetching a single user
export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.getUserById(userId); // Use the userApi methods
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for adding a user
export const addUser = createAsyncThunk(
  'users/addUser',
  async (user, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await apiServices.userApi.createUser(user, config); // Use the userApi methods
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for updating a user
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (updates, { rejectWithValue }) => {
    const userId = updates.get('_id') || null;
    try {
      if (!userId) {
        return rejectWithValue('User ID is missing');
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await apiServices.userApi.updateUser(
        userId,
        updates,
        config,
      ); // Use the userApi methods
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for deleting a user
export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id, { rejectWithValue }) => {
    try {
      await apiServices.userApi.deleteUser(id); // Use the userApi methods
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for resending a password email
export const resendPasswordEmail = createAsyncThunk(
  'users/resendPasswordEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.resendPasswordEmail(email); // Call the resend password API
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for setting a new password
export const setPassword = createAsyncThunk(
  'users/setPassword',
  async ({ token, newPassword }, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.setPassword(
        token,
        newPassword,
      ); // Call the set password API
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Async thunk for changing the password
export const changePassword = createAsyncThunk(
  'users/changePassword',
  async ({ userId, oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await apiServices.userApi.changePassword(
        userId,
        oldPassword,
        newPassword,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  },
);

// Create the slice
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.currentUser = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.error = action.payload || action.error.message;
      })

      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload.user);
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (user) => user._id === action.payload._id,
        );
        if (index !== -1) {
          state.users[index] = action.payload.user;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user._id !== action.payload);
      })
      .addCase(setPassword.fulfilled, (state, action) => {
        // Optionally handle successful password reset state here
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Optionally handle successful password change state here
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

// Export the reducer
export default usersSlice.reducer;
