// src/theme/paletteOcean.js

const paletteOcean = {
  primary: {
    main: "#00acc1", // Cyan
  },
  secondary: {
    main: "#00838f", // Teal
  },
  background: {
    default: "#e0f7fa",
    paper: "#b2ebf2",
  },
  text: {
    primary: "#004d40",
    secondary: "#00796b",
  },
};

export default paletteOcean;
