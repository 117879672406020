import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import apiServices from '../api'; // Import the api instance

// Define initial state
const initialState = {
  menus: [], // Array of all menus
  items: [], // Array of all menu items
  status: 'idle',
  dateMenu: {}, // Object to store menus by date
  menuSlots: {}, // Object to store menu slots by date, mealTime, and mealOption
  error: null,
};

// Async thunks for fetching, creating, updating, and deleting menus
export const fetchMenus = createAsyncThunk('menus/fetchMenus', async () => {
  const response = await apiServices.menuApi.fetchMenus();
  return response.data; // Assuming the data comes in `response.data`
});

export const fetchMenuItems = createAsyncThunk(
  'menus/fetchMenuItems',
  async () => {
    const response = await apiServices.menuApi.fetchMenuItems();
    return response.data; // Assuming the data comes in `response.data`
  },
);

export const saveMenu = createAsyncThunk(
  'menus/saveMenu',
  async ({ id, menuData }) => {
    // Update existing menu
    const response = await apiServices.menuApi.saveMenu(menuData);
    return response.data;
  },
);

export const removeMenuItem = createAsyncThunk(
  'menus/removeMenuItem',
  async ({ itemId: item, currentDate, mealTime, mealOption }) => {
    // Call API to delete the item
    await apiServices.menuApi.removeMenuItem({
      item,
      currentDate,
      mealTime,
      mealOption,
    });
    return { item, currentDate, mealTime, mealOption }; // Return payload for further processing
  },
);

export const addMenuItemToSlot = createAsyncThunk(
  'menus/addMenuItemToSlot',
  async ({ item, currentDate, mealTime, mealOption }, { dispatch }) => {
    try {
      // add the item to the slot in the server
      await apiServices.menuApi.updateDateMenu({
        item,
        currentDate,
        mealTime,
        mealOption,
      });

      // Fetch the updated menu data from the server
      const response = await apiServices.menuApi.fetchMenus();
      const updatedMenus = response.data;

      // Update Redux store with the updated menu data
      return { currentDate, mealTime, mealOption, item, updatedMenus };
    } catch (error) {
      // Handle errors
      console.error('Failed to add menu item to slot:', error);
      throw error;
    }
  },
);

// Selectors
const selectMenus = (state) => state.menus.menus;
const selectItems = (state) => state.menus.items;
const selectCurrentDate = (_, currentDate) => currentDate;
const selectMealTime = (_, __, mealTime) => mealTime;
const selectMealOption = (_, __, ___, mealOption) => mealOption;

export const selectMenuForSlot = createSelector(
  [
    selectMenus, // Selector to get menus from the state
    selectItems, // Selector to get items from the state
    selectCurrentDate,
    selectMealTime,
    selectMealOption,
  ],
  (menus, items, currentDate, mealTime, mealOption) => {
    if (!items.length || !menus) return []; // Return early if items or menus are not populated

    const filteredMenus = menus.filter((menu) => {
      const menuDate = new Date(menu.menuDate).toISOString().split('T')[0];
      return menuDate === currentDate;
    });

    if (!filteredMenus.length) return [];

    const mealOptionItems = filteredMenus.flatMap((menu) => {
      const mealData = menu[mealTime];
      return mealData ? mealData[mealOption] : [];
    });

    const resultItems = mealOptionItems
      .map((id) => {
        if (!id) return null;
        const item = items.find((item) => item._id === id);
        if (!item) {
          console.error(`Item with ID ${id} not found.`);
        }
        return item;
      })
      .filter((item) => item);

    return resultItems;
  },
);

// Combined slice
const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenuItems: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenuItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchMenuItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchMenus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.menus = action.payload;
      })
      .addCase(fetchMenus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(saveMenu.fulfilled, (state, action) => {
        const index = state.menus.findIndex(
          (menu) => menu._id === action.payload._id,
        );
        if (index !== -1) {
          state.menus[index] = {
            ...state.menus[index],
            ...action.payload,
          };
        } else {
          state.menus.push(action.payload);
        }
      })
      .addCase(saveMenu.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(removeMenuItem.fulfilled, (state, action) => {
        const { item, currentDate, mealTime, mealOption } = action.payload;

        const menu = state.menus.find(
          (menu) =>
            new Date(menu.menuDate).toISOString().split('T')[0] ===
            new Date(currentDate).toISOString().split('T')[0],
        );

        if (menu) {
          if (menu[mealTime] && menu[mealTime][mealOption]) {
            menu[mealTime][mealOption] = menu[mealTime][mealOption].filter(
              (itm) => itm !== item,
            );
          } else {
            console.warn(`menu[${mealTime}][${mealOption}] does not exist.`);
          }
        } else {
          console.error(`Menu not found for date ${currentDate}`);
        }
      })
      .addCase(removeMenuItem.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(addMenuItemToSlot.fulfilled, (state, action) => {
        const { updatedMenus } = action.payload;

        // Update the state with the new menus
        state.menus = updatedMenus;

        // Optional: Handle additional logic if needed
      })
      .addCase(addMenuItemToSlot.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setMenuItems, setMenuForDate } = menuSlice.actions;
export default menuSlice.reducer;
