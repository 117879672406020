import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFoodOrder, updateFoodOrder } from '../../features/foodOrders';
import { fetchMenuItems } from '../../features/menuItems';
import {
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from '@mui/material';
import SchoolSelectionStep from './SchoolSelectionStep';
import ContactInformationStep from './ContactInformationStep';
import OrderWeekStep from './OrderWeekStep';
import MenuItemsStep from './MenuItemsStep';
import OrderSummaryStep from './OrderSummaryStep';
import { showToastError, showToastSuccess } from '../../utils/toastUtils';

const FoodOrderForm = ({ open, handleClose, selectedFoodOrder = null }) => {
  const dispatch = useDispatch();
  const menuItems = useSelector((state) => state.menuItems.menuItems);
  const schools = useSelector((state) => state.schools.schools);

  const [school, setSchool] = useState('');
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [status, setStatus] = useState('New');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [weekOf, setWeekOf] = useState('');
  const [, setUseAssignedContact] = useState(true);
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingOrder, setLoadingOrder] = useState(true);

  useEffect(() => {
    const loadMenuItems = async () => {
      if (menuItems.length > 0) {
        setLoading(false);
        return; // Menu items are already loaded, no need to fetch
      }
      try {
        await dispatch(fetchMenuItems()).unwrap();
      } catch (error) {
        console.error('Failed to fetch menu items: ', error);
      } finally {
        setLoading(false);
      }
    };

    loadMenuItems();

    if (selectedFoodOrder) {
      if (!school) setSchool(selectedFoodOrder.school || '');
      setItems(
        selectedFoodOrder.items.map((item) => ({
          menuItem: item.menuItem._id,
          quantity: item.quantity,
          name: item.menuItem.name,
          category: item.menuItem.category,
          mondayQuantity: item.mondayQuantity,
          tuesdayQuantity: item.tuesdayQuantity,
          wednesdayQuantity: item.wednesdayQuantity,
          thursdayQuantity: item.thursdayQuantity,
          fridayQuantity: item.fridayQuantity,
          saturdayQuantity: item.saturdayQuantity,
          sundayQuantity: item.sundayQuantity,
        })),
      );
      setTotalAmount(selectedFoodOrder.totalAmount || 0);
      setStatus('Modified');
      setDeliveryDate(selectedFoodOrder.deliveryDate || '');
      setWeekOf(selectedFoodOrder.weekOf || '');
      setContactName(selectedFoodOrder.contactName || school.contactName || '');
      setContactEmail(
        selectedFoodOrder.contactEmail || school.contactEmail || '',
      );
      setContactPhone(
        selectedFoodOrder.contactPhone || school.contactPhone || '',
      );
    } else {
      if (!school) resetForm();
    }
    setLoadingOrder(false);
  }, [dispatch, selectedFoodOrder, loading, school]);

  const handleSubmit = async () => {
    try {
      const orderDetails = {
        school,
        items,
        totalAmount,
        status,
        deliveryDate,
        contactName,
        contactEmail,
        contactPhone,
        weekOf,
      };

      if (selectedFoodOrder) {
        await dispatch(
          updateFoodOrder({ id: selectedFoodOrder._id, details: orderDetails }),
        );
      } else {
        await dispatch(createFoodOrder(orderDetails));
      }
      showToastSuccess('Order saved successfully');
      resetForm();
      handleClose();
    } catch (error) {
      showToastError('Failed to save order');
      console.error('Failed to save order: ', error);
    }
  };

  const resetForm = () => {
    setSchool('');
    setItems([]);
    setTotalAmount(0);
    setStatus('New');
    setDeliveryDate('');
    setWeekOf('');
    setUseAssignedContact(true);
    setContactName('');
    setContactEmail('');
    setContactPhone('');
  };

  const steps = [
    'Select School',
    'Contact Information',
    'Order Week',
    'Select Menu Items',
    'Order Summary',
  ];
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SchoolSelectionStep
            school={school}
            setSchool={setSchool}
            setActiveStep={setActiveStep}
            schools={schools}
          />
        );
      case 1:
        return (
          <ContactInformationStep
            setActiveStep={setActiveStep}
            school={school}
            contactName={contactName}
            setContactName={setContactName}
            contactEmail={contactEmail}
            setContactEmail={setContactEmail}
            contactPhone={contactPhone}
            setContactPhone={setContactPhone}
          />
        );
      case 2:
        return (
          <OrderWeekStep
            weekOf={weekOf}
            school={school}
            setWeekOf={setWeekOf}
            setActiveStep={setActiveStep}
            school={school}
          />
        );
      case 3:
        return (
          <MenuItemsStep
            menuItems={menuItems}
            items={items}
            setItems={setItems}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            setActiveStep={setActiveStep}
            school={school}
          />
        );
      case 4:
        return (
          <OrderSummaryStep
            school={school}
            setActiveStep={setActiveStep}
            weekOf={weekOf}
            status={status}
            totalAmount={totalAmount}
            items={items}
            handleSubmit={handleSubmit}
            isEdit={selectedFoodOrder ? true : false}
          />
        );
      default:
        return null;
    }
  };

  if (loading || loadingOrder) {
    return <Typography>Loading...</Typography>; // Or any loading indicator
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xl' fullWidth>
      <DialogTitle component='div' sx={{ m: 0, p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: 1,
          }}
        >
          <Typography variant='h6'>
            Food Order Form {school.name ? 'for ' + school.name : ''}
          </Typography>
          <Typography variant='body1' sx={{ color: 'darkmagenta' }}>
            {school.deliveryDate && `(${school.deliveryDate} Deliveries)`}
          </Typography>
        </Box>
        <Typography v ariant='body2'>
          {contactName
            ? `Ordered By: ${contactName} - ${contactEmail} - ${contactPhone}`
            : ''}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='body2'
            color='textSecondary'
            sx={{ textAlign: 'center', padding: 1 }}
          >
            Orders must be placed by 4 PM on Monday for deliveries in the
            following week.
          </Typography>
        </Box>
        <Button onClick={handleClose}>Cancel</Button>
        {/* Add navigation buttons (previous/next) if needed */}
      </DialogActions>
    </Dialog>
  );
};

export default FoodOrderForm;
