// src/services/apiBase.js
import axios from "axios";
import { redirectToLogin } from "./utils/redirect"; // Adjust the path as necessary
import { getToken, removeToken } from "./utils/authUtils"; // Adjust the path as necessary

// Create an Axios instance with a base URL
const apiBase = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL + "/api" || "http://localhost:4004/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add a request interceptor to include the token in the headers
apiBase.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle errors
apiBase.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          removeToken();
          redirectToLogin(); // Redirect to login page
          break;

        case 403:
          console.error(
            "Forbidden: You don't have permission to access this resource.",
          );
          break;
        case 404:
          console.error("Resource not found.");
          break;
        case 500:
          console.error("Server error. Please try again later.");
          break;
        default:
          console.error("An error occurred:", error.response.statusText);
          break;
      }
    } else {
      console.error("Network error or no response received.");
    }
    return Promise.reject(error);
  },
);

export default apiBase;
