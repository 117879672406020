import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TextField,
  IconButton,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../ConfirmationDialog';
import ImagePreview from '../Images/ImagePreview';

const OrganizationList = ({ organizations, onEdit, onDelete }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteClick = (organization) => {
    setOrgToDelete(organization);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    onDelete(orgToDelete._id);
    setDialogOpen(false);
    setOrgToDelete(null);
  };

  const filteredOrganizations = organizations.filter((org) =>
    org.name.toLowerCase().includes(filter.toLowerCase()),
  );

  const sortedOrganizations = filteredOrganizations.sort((a, b) => {
    if (orderBy === 'name') {
      return order === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return 0;
  });

  const paginatedOrganizations = sortedOrganizations.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <div>
      <TextField
        label='Search Organizations'
        variant='outlined'
        fullWidth
        margin='dense'
        value={filter}
        onChange={handleFilterChange}
      />
      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Logo</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell align='center'>School Count</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrganizations.map((organization) => (
              <TableRow key={organization._id}>
                <TableCell sx={{ textAlign: 'center' }}>
                  {/* {organization.imageInfo ? ( */}
                  <ImagePreview
                    imageURL={organization.imageInfo?.url}
                    alt={organization.name}
                    placeholderIcon='org'
                    style={{
                      width: '250px',
                      height: 'auto',
                    }}
                  />
                  {/* ) : (
                    <div />
                  )} */}
                </TableCell>
                <TableCell>{organization.name}</TableCell>
                <TableCell>{organization.email}</TableCell>
                <TableCell align='center'>
                  {organization.schools?.length}
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={() => onEdit(organization)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge='end'
                    color='secondary'
                    aria-label='delete'
                    onClick={() => handleDeleteClick(organization)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredOrganizations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title='Delete Organization'
        message={`Are you sure you want to delete the organization "${orgToDelete?.name}"?`}
      />
    </div>
  );
};

export default OrganizationList;
