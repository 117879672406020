import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatPhone } from '../../utils/generalUtils';
import {
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch } from 'react-redux';
import { resendPasswordEmail } from '../../features/users'; // Update the path to your slice accordingly
import { showToastError, showToastSuccess } from '../../utils/toastUtils';
import { lastTimeSeen } from '../../utils/dateUtils';
import ImagePreview from '../Images/ImagePreview';

const UserList = React.memo(({ status, onEdit, onDelete, resetForm }) => {
  const dispatch = useDispatch(); // Get the dispatch function
  const users = useSelector((state) => state.users.users); // Access users from Redux store
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName'); // Changed to "firstName"
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleEdit = (user) => {
    try {
      onEdit(user);
    } catch (error) {
      setError('Failed to edit user.');
      setSnackbarOpen(true);
    }
  };

  const handleDelete = (id) => {
    try {
      onDelete(id);
    } catch (error) {
      setError('Failed to delete user.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleResendEmail = async (email) => {
    try {
      await dispatch(resendPasswordEmail(email)).unwrap(); // Dispatch the thunk and wait for it to resolve
      showToastSuccess('Password reset email sent successfully.');
    } catch (error) {
      setError('Failed to resend password reset email.');
      setSnackbarOpen(true);
      showToastError('Failed to resend password reset email.');
    }
  };

  // Updated filter to include multiple fields
  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        const filterLower = filter.toLowerCase();
        return (
          user.firstName?.toLowerCase().includes(filterLower) ||
          user.lastName?.toLowerCase().includes(filterLower) ||
          user.email?.toLowerCase().includes(filterLower) ||
          user.phone?.toLowerCase().includes(filterLower) ||
          user.address?.toLowerCase().includes(filterLower) ||
          user.roles?.some((role) =>
            role.name?.toLowerCase().includes(filterLower),
          ) ||
          user.organizations?.some((org) =>
            org.name?.toLowerCase().includes(filterLower),
          )
        );
      }),
    [users, filter],
  );

  // Updated sorting to support additional fields
  const sortedUsers = useMemo(() => {
    return filteredUsers.slice().sort((a, b) => {
      if (orderBy === 'firstName') {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
      if (orderBy === 'email') {
        const emailA = a.email.toLowerCase();
        const emailB = b.email.toLowerCase();
        return order === 'asc'
          ? emailA.localeCompare(emailB)
          : emailB.localeCompare(emailA);
      }
      if (orderBy === 'phone') {
        const phoneA = a.phone.toLowerCase();
        const phoneB = b.phone.toLowerCase();
        return order === 'asc'
          ? phoneA.localeCompare(phoneB)
          : phoneB.localeCompare(phoneA);
      }
      if (orderBy === 'address') {
        const addressA = a.address.toLowerCase();
        const addressB = b.address.toLowerCase();
        return order === 'asc'
          ? addressA.localeCompare(addressB)
          : addressB.localeCompare(addressA);
      }
      if (orderBy === 'roles') {
        const rolesA = a.roles
          .map((role) => role.name)
          .join(', ')
          .toLowerCase();
        const rolesB = b.roles
          .map((role) => role.name)
          .join(', ')
          .toLowerCase();
        return order === 'asc'
          ? rolesA.localeCompare(rolesB)
          : rolesB.localeCompare(rolesA);
      }
      if (orderBy === 'organizations') {
        const orgsA = a.organizations
          .map((org) => org.name)
          .join(', ')
          .toLowerCase();
        const orgsB = b.organizations
          .map((org) => org.name)
          .join(', ')
          .toLowerCase();
        return order === 'asc'
          ? orgsA.localeCompare(orgsB)
          : orgsB.localeCompare(orgsA);
      }
      if (orderBy === 'lastSeen') {
        const lastSeenA = new Date(a.lastSeen).getTime();
        const lastSeenB = new Date(b.lastSeen).getTime();
        return order === 'asc' ? lastSeenA - lastSeenB : lastSeenB - lastSeenA;
      }
      return 0;
    });
  }, [filteredUsers, orderBy, order]);

  const paginatedUsers = useMemo(
    () =>
      sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortedUsers, page, rowsPerPage],
  );

  return (
    <div>
      <h1>User List</h1>
      <TextField
        label='Search Users'
        variant='outlined'
        fullWidth
        margin='dense'
        value={filter}
        onChange={handleFilterChange}
      />
      {status === 'loading' && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <CircularProgress />
          <Typography>Loading users...</Typography>
        </div>
      )}

      {status === 'failed' && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Typography color='error'>
            Failed to load users. Please try again.
          </Typography>
        </div>
      )}

      {status === 'succeeded' && users.length === 0 && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Typography>No users found.</Typography>
        </div>
      )}

      {status === 'succeeded' && users.length > 0 && (
        <>
          <TableContainer component={Paper} style={{ marginTop: '16px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align='right'>
                    <TableSortLabel
                      active={orderBy === 'firstName'}
                      direction={orderBy === 'firstName' ? order : 'asc'}
                      onClick={() => handleRequestSort('firstName')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={() => handleRequestSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'phone'}
                      direction={orderBy === 'phone' ? order : 'asc'}
                      onClick={() => handleRequestSort('phone')}
                    >
                      Phone
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'roles'}
                      direction={orderBy === 'roles' ? order : 'asc'}
                      onClick={() => handleRequestSort('roles')}
                    >
                      Roles
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'organizations'}
                      direction={orderBy === 'organizations' ? order : 'asc'}
                      onClick={() => handleRequestSort('organizations')}
                    >
                      Organizations
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'lastSeen'}
                      direction={orderBy === 'lastSeen' ? order : 'asc'}
                      onClick={() => handleRequestSort('lastSeen')}
                    >
                      Last Seen
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>
                      <>
                        <Tooltip title={user.firstName + ' ' + user.lastName}>
                          <>
                            <ImagePreview
                              imageURL={user.imageInfo?.url}
                              alt={user.firstName}
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                              }}
                            />
                          </>
                        </Tooltip>
                      </>
                    </TableCell>
                    <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatPhone(user.phone)}</TableCell>
                    <TableCell>
                      {user.roles.map((role) => role.name).join(', ')}
                    </TableCell>
                    <TableCell>
                      {user.organizations.map((org) => org.name).join(', ')}
                    </TableCell>
                    <TableCell>{lastTimeSeen(user.lastSeen)}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        color='primary'
                        onClick={() => handleEdit(user)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color='secondary'
                        onClick={() => handleDelete(user._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color='default'
                        onClick={() => handleResendEmail(user.email)}
                      >
                        <EmailIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={error ? 'error' : 'success'}
              sx={{ width: '100%' }}
            >
              {error || 'Action completed successfully'}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
});

export default UserList;
