import React, { useState, useEffect } from 'react';
import { fetchSchools } from '../../features/schools';
import { fetchMenuItems } from '../../features/menuItems';
import { useDispatch, useSelector } from 'react-redux';
import { createFoodOrder } from '../../features/foodOrders';
import { format } from 'date-fns';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';

const BulkLoader = () => {
  const dispatch = useDispatch();

  const [inputData, setInputData] = useState('');
  const [rows, setRows] = useState([]);
  const [isDataProcessed, setIsDataProcessed] = useState(false);
  const [date, setDate] = useState(
    format(new Date('2023-10-16'), 'yyyy-MM-dd'),
  );
  const schools = useSelector((state) => state.schools);
  const menuItems = useSelector((state) => state.menuItems);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [, setHasErrors] = useState(false); // State to track errors
  const [, setError] = useState(''); // State to store error message

  useEffect(() => {
    dispatch(fetchSchools());
    dispatch(fetchMenuItems());
  }, [dispatch]);

  const handleProcessData = () => {
    let hasErrors = false; // Initialize hasErrors to false
    let errors = []; // Initialize an array to store error messages

    const parseData = () => {
      const parsedRows = inputData.split('\n').map((line) => line.split('\t'));

      const formattedRows = parsedRows.map((row, index) => {
        const [item, cost, ...schoolValues] = row;

        //first row will have the date grab adn set it then skip
        if (false || index === 0) {
          //item will be a date like Sept 6-8, need to convert to 2023-09-06 if Sept - Dec, if jan - aug 2024
          const dateParts = item.split(' ');
          const month = dateParts[0].toLowerCase();
          const dayRange = dateParts[1].split('-');
          const dayStart = dayRange[0];
          const year =
            month === 'sept' ||
            month === 'oct' ||
            month === 'nov' ||
            month === 'dec'
              ? '2023'
              : '2024';
          const monthNumber =
            month === 'sept'
              ? '09'
              : month === 'oct'
                ? '10'
                : month === 'nov'
                  ? '11'
                  : month === 'dec'
                    ? '12'
                    : month === 'jan'
                      ? '01'
                      : month === 'feb'
                        ? '02'
                        : month === 'mar'
                          ? '03'
                          : month === 'apr'
                            ? '04'
                            : month === 'may'
                              ? '05'
                              : month === 'jun'
                                ? '06'
                                : month === 'jul'
                                  ? '07'
                                  : month === 'aug'
                                    ? '08'
                                    : '';

          const startDate = new Date(`${year}-${monthNumber}-${dayStart}`);
          startDate.setDate(startDate.getDate() + 1); // Add a day
          setDate(format(startDate, 'yyyy-MM-dd'));
        }
        const menuItem = Array.isArray(menuItems.menuItems)
          ? menuItems.menuItems.find((menuItem) => {
              const menuItemName = menuItem.name?.trim().toLowerCase();
              return menuItemName === item?.trim().toLowerCase();
            }) || { name: item, _id: null } // Ensure default value if not found
          : { name: item, _id: null };
        if (index > 0 && !menuItem._id) {
          hasErrors = true;
          setError(`Menu item not found: ${item}`);
          errors.push(`Menu item not found: ${item}`);
        }

        const schoolData = schoolValues.map((value, i) => {
          const schoolName = parsedRows[0]?.[i + 3]?.trim().toLowerCase() || '';

          const matchedSchool = Array.isArray(schools.schools)
            ? schools.schools.find((school) => {
                const shortName = school.shortName?.trim().toLowerCase();
                return shortName === schoolName;
              })
            : null;

          if (!matchedSchool) {
            hasErrors = true;
            setError(`School not found: ${schoolName}`);
            errors.push(`School not found: ${schoolName}`);
          }

          return {
            name: schoolName,
            value,
            matched: !!matchedSchool,
            shortName: matchedSchool?.shortName || '',
            school: matchedSchool,
          };
        });

        return {
          cost,
          menuItem,
          schoolData,
        };
      });

      setRows(formattedRows);
      setIsDataProcessed(true);
      setHasErrors(hasErrors); // Set the hasErrors state

      if (hasErrors) {
        setSnackbarMessage(errors.join('\n'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    parseData();
  };

  const handleLoadData = async () => {
    if (!date) {
      setSnackbarMessage('Please enter a date.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const schoolOrders = {};
    let errors = [];
    let schoolErrors = [];
    let menuItemErrors = [];

    for (const row of rows) {
      if (row.cost === '$$') continue;

      const { menuItem, schoolData } = row;

      for (const school of schoolData) {
        if (!school.value || school.name === '' || school.name === 'total')
          continue;

        if (!school.school) {
          schoolErrors.push(`School not found: ${school.name}`);
          continue;
        }
        if (school.matched && menuItem._id) {
          if (!schoolOrders[school.shortName]) {
            schoolOrders[school.shortName] = {
              school: school.school,
              items: [],
              totalAmount: 0,
            };
          }

          const quantity = parseInt(school.value, 10);
          if (isNaN(quantity) || quantity === 0) continue;

          const totalPrice = quantity * menuItem.price;

          schoolOrders[school.shortName].items.push({
            menuItem: menuItem,
            price: menuItem.price,
            quantity: quantity,
          });

          schoolOrders[school.shortName].totalAmount += totalPrice;
        } else {
          menuItemErrors.push(`Menu item not found: ${menuItem.name}`);
        }
      }
    }

    for (const [schoolShortName, orderDetails] of Object.entries(
      schoolOrders,
    )) {
      const finalOrderDetails = {
        ...orderDetails,
        status: 'Completed',
        contactName: 'Imported',
        contactEmail: 'Imported',
        contactPhone: 'Imported',
        orderDate: date,
        weekOf: new Date(date),
      };

      try {
        await dispatch(createFoodOrder(finalOrderDetails));
      } catch (error) {
        errors.push(
          `Error creating order for ${schoolShortName}: ${error.message}`,
        );
      }
    }

    if (
      schoolErrors.length > 0 ||
      menuItemErrors.length > 0 ||
      errors.length > 0
    ) {
      setSnackbarMessage(
        [...schoolErrors, ...menuItemErrors, ...errors].join('\n'),
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setHasErrors(true); // Set hasErrors to true if errors are found
    } else {
      setSnackbarMessage('Data loaded successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setHasErrors(false); // Set hasErrors to false if no errors
    }
  };

  const processInputData = (data) => {
    const lines = data.split('\n');
    let joinedRow = '';
    let i = 0;
    const result = [];

    for (const row of lines) {
      if (row.trim() === '') {
        result.push(joinedRow.trim());
        joinedRow = '';
        break;
      } else {
        joinedRow += row + ' ';
        i++;
      }
    }

    lines.splice(0, i + 1);
    lines.unshift(result.join().replace(/ {2}/g, ' '));

    for (let i = 0; i < lines.length - 1; i++) {
      if (lines[i].endsWith('Granola') && lines[i + 1].startsWith('Bars')) {
        lines[i] += ' Bars' + lines[i + 1].substring(4);
        lines.splice(i + 1, 1);
      }
    }

    return lines
      .map((line) =>
        line
          .replace(/cheese +/gi, 'Cheese ')
          .replace(/yogurt +/gi, 'Yogurt ')
          .replace(/Applesauce\s+\(6 pack\)/gi, 'Applesauce'),
      )
      .join('\n');
  };

  const handleTextAreaChange = (e) => {
    const cleanedText = processInputData(e.target.value);
    setInputData(cleanedText);
  };

  return (
    <div>
      <TextField
        label='Date'
        type='date'
        variant='outlined'
        fullWidth
        value={date}
        onChange={(e) => setDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ marginBottom: '16px' }}
      />
      <TextField
        multiline
        rows={10}
        variant='outlined'
        fullWidth
        value={inputData}
        onChange={handleTextAreaChange}
        placeholder='Paste your data here'
      />
      <Button
        variant='contained'
        color='primary'
        onClick={handleProcessData}
        style={{ marginTop: '16px' }}
      >
        Process Data
      </Button>
      {isDataProcessed && (
        <>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleLoadData}
            style={{ marginTop: '16px' }}
          >
            Load Data
          </Button>
          <div style={{ overflowX: 'auto', marginTop: '16px' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Menu Items</TableCell>
                    {Array.isArray(rows[0]?.schoolData) &&
                      rows[0]?.schoolData.map((school, index) => (
                        <TableCell
                          key={index}
                          style={{
                            color: school.matched ? 'green' : 'red',
                          }}
                        >
                          {school.matched ? '✓' : '✗'} {school.name}
                          {school.matched && ` (${school.shortName})`}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          color: row.menuItem._id ? 'green' : 'red',
                          backgroundColor: row.menuItem._id
                            ? '#e6ffe6'
                            : '#ffe6e6',
                        }}
                      >
                        {row.menuItem ? row.menuItem.name : 'No Menu Item'}
                      </TableCell>
                      {row.schoolData.map((school, i) => (
                        <TableCell key={i}>{school.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BulkLoader;
