// src/theme/paletteForest.js

const paletteForest = {
  primary: {
    main: "#388e3c", // Green
  },
  secondary: {
    main: "#2e7d32", // Dark Green
  },
  background: {
    default: "#e8f5e9",
    paper: "#c8e6c9",
  },
  text: {
    primary: "#1b5e20",
    secondary: "#2e7d32",
  },
};

export default paletteForest;
