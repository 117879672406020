export const sanitizeUrl = (url) => {
  return url
    .replace(/^(https?:\/\/)?(www\.)?/, '') // Remove http(s):// and www.
    .replace(/\/$/, ''); // Remove trailing slash
};

export const formatPhone = (phone) => {
  // Remove all non-digit characters from the phone number
  const digitsOnly = phone.replace(/\D/g, '');

  // Format the phone number in the desired format
  const formattedPhone = digitsOnly.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3',
  );
  return formattedPhone;
};
