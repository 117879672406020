// src/theme/classicTheme.js

import { createTheme } from "@mui/material/styles";
import paletteClassic from "./paletteClassic";
import typography from "./typography";
import components from "./components";

const classicTheme = (fontSize) =>
  createTheme({
    palette: paletteClassic,
    typography: typography(fontSize),
    components,
  });

export default classicTheme;
