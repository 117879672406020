import React from 'react';
import { useDrag } from 'react-dnd';
import { Box, Typography } from '@mui/material';

const DraggableMenuItem = ({ item }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'MENU_ITEM',
    item: {
      item: item,
      id: item._id,
      name: item.name,
      type: item.preMadeMenuType,
    }, // Ensure all necessary details are included
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={drag}
      sx={{
        padding: 1,
        width: '130px',
        border: '1px solid grey',
        marginBottom: 0.5,
        backgroundColor: isDragging ? '#f0f0f0' : '#fff',
      }}
    >
      <Typography
        variant='body1'
        sx={{
          fontSize: '0.7rem',
          whiteSpace: 'normal', // Enable word wrapping
          overflowWrap: 'break-word', // Break words if necessary
        }}
      >
        {item.name}
      </Typography>
    </Box>
  );
};

export default DraggableMenuItem;
