import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const OrdersAndSpendingChart = ({ data }) => {
  // Prepare data for the chart
  const labels = data.map((entry) => entry.date);
  const orders = data.map((entry) => entry.orders);
  const amountSpent = data.map((entry) => entry.amountSpent);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Number of Orders",
        data: orders,
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.2)",
        yAxisID: "y",
      },
      {
        label: "Amount Spent ($)",
        data: amountSpent,
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.2)",
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 5,
      easing: "easeInOutBounce",
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Orders and Spending Over Time",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // prevents grid lines from appearing on the chart area for y1
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default OrdersAndSpendingChart;
