import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchools, deleteSchool } from '../features/schools';
import SchoolForm from '../components/School/Form';
import SchoolList from '../components/School/List';
import CSVUpload from '../components/CSVUpload';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useRoles } from '../utils/authUtils'; // Import the custom hook

const Schools = () => {
  const dispatch = useDispatch();
  const { schools, status, error } = useSelector((state) => state.schools);
  const { user } = useSelector((state) => state.auth);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showCSVDialog, setShowCSVDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchSchools());
    }
  }, [dispatch, status]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDeleteSchool = (id) => {
    setDeleteId(id);
    setConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSchool(deleteId))
      .unwrap()
      .then(() => {
        openSnackbar('School successfully deleted', 'success');
      })
      .catch((error) => {
        openSnackbar(`Error: ${error.message}`, 'error');
      });
    setConfirmDelete(false);
  };

  const handleAddClick = () => {
    setSelectedSchool(null);
    setShowForm(true);
  };

  const handleEditClick = (school) => {
    setSelectedSchool(school);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleOpenCSVDialog = () => {
    setShowCSVDialog(true);
  };

  const handleCloseCSVDialog = () => {
    setShowCSVDialog(false);
  };
  return (
    <>
      {status === 'loading' && <p>Loading...</p>}
      {status === 'failed' && <p>Error: {error}</p>}
      {status === 'succeeded' && (
        <>
          <div style={{ padding: '20px' }}>
            <h1>Schools</h1>
            {isAdmin ||
              isManager ||
              (isLTEAdmin && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddClick}
                  style={{ marginRight: '10px' }}
                >
                  Add New School
                </Button>
              ))}

            {isLTEAdmin && (
              <Button
                variant='contained'
                color='secondary'
                onClick={handleOpenCSVDialog}
                startIcon={<UploadFileIcon />}
              >
                Bulk Load Schools
              </Button>
            )}
            <br />

            {status === 'succeeded' && (
              <SchoolList
                schools={schools}
                onEdit={handleEditClick}
                onDelete={handleDeleteSchool}
              />
            )}
            {/* Confirmation Dialog for Deletion */}
            <Dialog
              open={confirmDelete}
              onClose={() => setConfirmDelete(false)}
            >
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this school?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmDelete(false)} color='primary'>
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color='primary'>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            {/* CSV Upload Dialog */}
            <Dialog
              open={showCSVDialog}
              onClose={handleCloseCSVDialog}
              fullWidth
              maxWidth='md'
            >
              <DialogTitle>Bulk Load Schools</DialogTitle>
              <DialogContent>
                <CSVUpload
                  onClose={handleCloseCSVDialog}
                  snackBarMessage={openSnackbar}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCSVDialog} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {showForm && (
              <SchoolForm
                school={selectedSchool}
                open={showForm}
                onClose={handleCloseForm}
              />
            )}

            {/* Snackbar for Messages */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}
    </>
  );
};

export default Schools;
