import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSchools } from "../../features/schools";
import { fetchOrganizations } from "../../features/organizations"; // Adjust the import path

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

const CSVUpload = ({ onClose, snackBarMessage }) => {
  const [csvData, setCsvData] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const organizations =
    useSelector((state) => state.organizations.organizations) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const handleCSVChange = (event) => {
    const data = event.target.value;
    setCsvData(data);
    setPreviewData(parseCSVData(data));
  };

  const handleOrganizationChange = (event) => {
    setSelectedOrganization(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const schools = parseCSVData(csvData);

    if (selectedOrganization) {
      schools.forEach((school) => (school.organization = selectedOrganization));
    }

    try {
      await dispatch(
        createSchools({ organization: selectedOrganization, schools })
      ).unwrap();
      snackBarMessage("Schools successfully added", "success");
    } catch (error) {
      console.error("Failed to create schools:", error);
      snackBarMessage("Failed to create schools", "error");
    } finally {
      setLoading(false);
      resetForm();
      onClose(); // Close the dialog immediately after submission
    }
  };

  const parseCSVData = (data) => {
    const rows = data.trim().split("\n");
    const delimiter = rows[0].includes(",") ? "," : "\t"; // Detect delimiter
    const headers = rows[0].split(delimiter);
    return rows.slice(1).map((row) => {
      const values = row.split(delimiter);
      const item = {};
      headers.forEach((header, index) => {
        item[header.trim()] = values[index] ? values[index].trim() : "";
      });
      return item;
    });
  };

  const resetForm = () => {
    setCsvData("");
    setSelectedOrganization("");
    setPreviewData([]);
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Upload Schools from CSV or Tab-Separated Data</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          label="Paste Data Here"
          value={csvData}
          onChange={handleCSVChange}
          required
        />
        <FormControl fullWidth variant="outlined" style={{ marginTop: "10px" }}>
          <InputLabel>Organization</InputLabel>
          <Select
            value={selectedOrganization}
            onChange={handleOrganizationChange}
            label="Organization"
          >
            {organizations?.map((org) => (
              <MenuItem key={org._id} value={org._id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
          disabled={loading} // Disable button while loading
        >
          {loading ? <CircularProgress size={24} /> : "Create Schools"}
        </Button>
      </form>
      {previewData.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(previewData[0]).map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, i) => (
                    <TableCell key={i}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CSVUpload;
