// src/theme/oceanTheme.js

import { createTheme } from "@mui/material/styles";
import paletteOcean from "./paletteOcean";
import typography from "./typography";
import components from "./components";

const oceanTheme = (fontSize) =>
  createTheme({
    palette: paletteOcean,
    typography: typography(fontSize),

    components,
  });

export default oceanTheme;
