import { useMemo } from 'react';
import { showToastError } from './toastUtils';
import { STORAGE_KEYS } from '../constants/storageKeys';

// Utility function to check if user has a specific role
export const hasRole = (user, role) => user?.roles.includes(role);

// Custom hook to memoize role checks
export const useRoles = (user) => {
  return useMemo(
    () => ({
      isAdmin: hasRole(user, 'admin'),
      isManager: hasRole(user, 'manager'),
      isLTEAdmin: hasRole(user, 'LTEAdmin'),
    }),
    [user],
  );
};

export const clearLocalStorage = () => {
  const keysToRemove = [
    'user',
    'authToken',
    'selectedMenu',
    'menuItemListState',
    'reportTabValue',
    'menuItemsPageState',
    'weeklyGroceryOrdersState',
  ];

  keysToRemove.forEach((key) => localStorage.removeItem(key));
};

export const handleAuthTokenCheck = (token, rejectWithValue) => {
  if (!token) {
    clearLocalStorage();
    if (window.location.pathname !== '/login')
      showToastError('No authentication token found. Please log in.');
    return rejectWithValue('No authentication token found. Please log in.');
  }
  return null;
};

export const handleAuthError = (error, rejectWithValue) => {
  if (error.response && error.response.status === 401) {
    clearLocalStorage();
    // if already on login page don't show error
    if (window.location.pathname !== '/login')
      showToastError('Session expired. Please log in again.');
    return rejectWithValue('Session expired. Please log in again.');
  }
  showToastError('An error occurred while fetching user data.');
  return rejectWithValue(error.message);
};

export const saveToken = (token) => {
  localStorage.setItem(STORAGE_KEYS.authToken, token);
};

export const getToken = () => {
  return localStorage.getItem(STORAGE_KEYS.authToken);
};

export const removeToken = () => {
  return localStorage.removeItem(STORAGE_KEYS.authToken);
};
