import { jsPDF } from 'jspdf';

export const addHeader = ({
  doc,
  organizationImage,
  organizationName,
  organizationAddress,
  organizationPhone,
  weekOf,
}) => {
  if (organizationImage) {
    doc.addImage(organizationImage, 'PNG', 5, 5, 40, 15); // Adjust size and position as needed
  }
  doc.setFontSize(14);
  doc.text(organizationName, 70, 8); // Adjust position as needed
  doc.setFontSize(10);
  doc.text(organizationAddress, 70, 13); // Adjust position as needed
  doc.setFontSize(10);
  doc.text(organizationPhone, 70, 18); // Adjust position as needed

  doc.setFontSize(14);
  doc.text(`Order for the Week of: ${weekOf}`, 70, 25); // Adjust position as needed
};

export const addFooter = (doc) => {
  doc.setFontSize(10);
  const totalPages = doc.internal.getNumberOfPages();
  const date = new Date().toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.text(
      `Page ${i} of ${totalPages}`,
      doc.internal.pageSize.width - 25,
      doc.internal.pageSize.height - 10,
    );
    doc.text(`Printed: ${date}`, 10, doc.internal.pageSize.height - 10);
  }
};

export const generatePDF = ({
  items: content,
  days,
  title,
  organizationImage,
  organizationName,
  organizationAddress,
  organizationPhone,
  weekOf,
  premade,
  schoolItems,
  deliveryDate,
}) => {
  const doc = new jsPDF();

  // Add header
  addHeader({
    doc,
    organizationImage,
    organizationName,
    organizationAddress,
    organizationPhone,
    weekOf,
  });

  // Set font size for title and add it to PDF
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  const titleWidth =
    (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const pageWidth = doc.internal.pageSize.getWidth();
  const xPos = (pageWidth - titleWidth) / 2;
  doc.text(title, xPos, 35); // Adjust position as needed
  // Define checkbox properties
  const checkboxSize = 3; // Size of the checkbox

  //positions
  const startY = 45;
  let y = startY; // Starting vertical position
  let itemGap = 5.8; // Vertical gap between lines
  let catGap = 6; // Vertical gap between categories
  let x = 13; // Starting horizontal position

  if (!premade) {
    // Split content into lines
    const lines = content.split('\n');
    let ticker = 0;
    lines.forEach((line) => {
      if (line.startsWith('CAT')) {
        if (ticker === 3) {
          x = 120; // right column x position
          y = startY;
        }
        ticker++;
        // Add category (CAT) line with larger, bold text
        doc.setFontSize(13); // Larger size for categories
        doc.setFont('helvetica', 'bold');
        doc.text(line.slice(4).trim(), x, y); // Remove "CAT:" and add text
        doc.setFontSize(13); // Reset font size for next lines
        doc.setFont('helvetica', 'normal');
        y += itemGap + 1; // Increase vertical position
      } else if (line.startsWith('BOX')) {
        // Draw a checkbox for items starting with BOX
        doc.rect(x + 3, y - 3, checkboxSize, checkboxSize); // Draw the box
        doc.text(line.slice(4).trim(), x + 6 + checkboxSize, y); // Remove "BOX" and indent text
        y += itemGap; // Increase vertical position
      } else {
        // Handle any other lines if needed
        doc.text(line, x, y);
        y += catGap; // Increase vertical position
      }
    });
    if (days || deliveryDate) {
      // Add the rotated text on the right-hand side for the shopping days
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      const shoppingForDaysText = deliveryDate
        ? 'Delivery Date:'
        : 'Shopping For Days:';
      const daysText = deliveryDate ? deliveryDate : days.join(', '); // Join the days array into a single string

      // Define the position for the text
      const rightX = doc.internal.pageSize.width - 10; // Right-hand side of the page
      const centerY = doc.internal.pageSize.height / 2; // Center vertically

      // Display the "Shopping For Days:" text
      doc.text(
        shoppingForDaysText,
        rightX,
        deliveryDate ? centerY - 65 : centerY - 80,
        {
          angle: -90,
        },
      );

      // Display the days list under "Shopping For Days:"
      doc.text(daysText, rightX, centerY - 30, {
        angle: -90,
      });
    }
  } else if (content && premade) {
    //premade section

    // Assuming 'doc' is the reference to the PDF instance

    // Check if 'content' and 'table' structure exist
    if (content && content['table'] && Array.isArray(content['table'].body)) {
      // Extract the table data from the 'content' object
      let { body } = content['table'];

      // Identify if all Saturday and Sunday entries are zero
      const shouldPrintSaturday = body.some(
        (row, index) => index > 0 && row[6] !== 0,
      ); // Check Saturday (column 6)
      const shouldPrintSunday = body.some(
        (row, index) => index > 0 && row[7] !== 0,
      ); // Check Sunday (column 7)

      // Adjust header and body if Saturday or Sunday columns should be removed
      if (!shouldPrintSaturday) {
        body = body.map((row) => row.slice(0, 6)); // Remove Saturday column (index 6)
      }
      if (!shouldPrintSunday) {
        body = body.map((row) => row.slice(0, 7)); // Remove Sunday column (index 7)
      }

      // Add the table to the PDF using doc.autoTable or another appropriate PDF library
      doc.autoTable({
        head: [body[0]], // The first row is the header
        body: body.slice(1), // The rest of the rows are the table body
        startY: 45, // Adjust the starting Y position as needed
        columnStyles: {
          0: { cellWidth: 'auto' }, // Set column 0 (Meal Item) to auto width
        },
        styles: {
          fontSize: 10, // Adjust font size for compactness
          cellPadding: 4, // Adjust cell padding for compact layout
        },
        headStyles: {
          fillColor: [22, 160, 133], // Optional: Set header background color
          textColor: 255, // Optional: Set header text color
        },
        margin: { top: 10 }, // Adjust top margin
      });
    } else {
      console.error('Invalid content structure or missing table body');
    }

    // Add footer
    addFooter(doc);

    // Function to output daily tables for PDF
    Object.entries(schoolItems).forEach(([day, tableContent]) => {
      const { table } = tableContent;

      doc.addPage();
      addHeader({
        doc,
        organizationImage,
        organizationName,
        organizationAddress,
        organizationPhone,
        weekOf,
      });

      if (table && table.body && Array.isArray(table.body)) {
        let { body } = table;

        // Check if Saturday and Sunday entries should be printed
        const shouldPrintSaturday = body.some((row) => row[6] !== 0);
        const shouldPrintSunday = body.some((row) => row[7] !== 0);

        // Adjust header and body if Saturday or Sunday columns should be removed
        if (!shouldPrintSaturday) {
          body = body.map((row) => row.slice(0, 6)); // Remove Saturday column (index 6)
        }
        if (!shouldPrintSunday) {
          body = body.map((row) => row.slice(0, 7)); // Remove Sunday column (index 7)
        }
        // Define the title for the table
        const tableTitle = `Pre-Made Orders for ${day}`;
        // Add the table to the PDF for the current day
        doc.autoTable({
          head: [body[0]], // The first row is the header
          body: body.slice(1), // The rest of the rows are the table body
          startY: 40, // Adjust the starting Y position as needed
          columnStyles: {
            0: { cellWidth: 'auto' }, // Set column 0 (School) to auto width
          },
          styles: {
            fontSize: 10, // Adjust font size for compactness
            cellPadding: 4, // Adjust cell padding for compact layout
          },
          headStyles: {
            fillColor: [22, 160, 133], // Optional: Set header background color
            textColor: 255, // Optional: Set header text color
          },
          margin: { top: 40 }, // Adjust top margin
          didDrawPage: (data) => {
            // Add the header to each page
            addHeader({
              doc,
              organizationImage,
              organizationName,
              organizationAddress,
              organizationPhone,
              weekOf,
            });

            // Add the title before the table
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            // Calculate the width of the title text
            const titleWidth =
              (doc.getStringUnitWidth(tableTitle) *
                doc.internal.getFontSize()) /
              doc.internal.scaleFactor;
            // Calculate the x position to center the title
            const pageWidth = doc.internal.pageSize.getWidth();
            const xPos = (pageWidth - titleWidth) / 2;
            doc.text(tableTitle, xPos, data.settings.startY - 5);
          },
        });
      } else {
        console.error(
          `Invalid content structure or missing table body for ${day}`,
        );
      }
    });
  }
  addFooter(doc);

  // Make the filename unique to avoid caching issues
  const timestamp = new Date().toLocaleString();
  const uniqueFilename = `${title}_${timestamp}.pdf`;

  // Save the PDF
  try {
    doc.save(uniqueFilename);
  } catch (error) {
    console.error('Failed to save PDF:', error);
  }
};

export const formatItems = ({ items, schoolsItems, premade = false }) => {
  if (!premade && items) {
    return Object.entries(items)
      .map(([category, categoryItems]) => {
        // Ensure categoryItems is an object
        if (!categoryItems || typeof categoryItems !== 'object') {
          return `${category}:\nNo items available`;
        }

        // Sorting items within each category
        const sortedItems = Object.entries(categoryItems)
          .sort(([a], [b]) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map(([item, details]) => {
            // Ensure details is an object and has quantity
            const quantity = details?.bulkQuantity ?? 'N/A';
            const size = details?.size ?? 'N/A';
            return `BOX ${quantity} - ${item} (${size})`;
          })
          .join('\n');

        return `CAT ${category}:\n${sortedItems}`;
      })
      .join('\n\n');
  } else if (schoolsItems && premade) {
    // create a table for a day that has all the pre made meals for each school

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']; // Skipping Saturday and Sunday

    // Function to build daily tables for each school and day
    const tables = {};

    // Iterate through each day of the week
    daysOfWeek.forEach((day) => {
      const tableBody = [];

      // Build table header with schools and meal items
      tableBody.push([
        'School',
        'Pre-made Breakfast',
        'Pre-made Breakfast Vegetarian',
        'Pre-made Lunch',
        'Pre-made Lunch Vegetarian',
      ]); // Header row

      // Iterate through schools
      Object.entries(schoolsItems).forEach(([schoolName, schoolDetails]) => {
        const { categories } = schoolDetails;
        const meals = categories['Pre-Made'];

        // Create a row with the school name
        const row = [schoolName];

        // Populate the row with the quantities for each pre-made meal on the given day
        [
          'Pre-made Breakfast',
          'Pre-made Breakfast Vegetarian',
          'Pre-made Lunch',
          'Pre-made Lunch Vegetarian',
        ].forEach((mealName) => {
          const mealDetails = meals[mealName];
          const quantity = mealDetails?.dailyQuantities[day.toLowerCase()] || 0; // Get the quantity for the specific day or default to 0
          row.push(quantity);
        });

        tableBody.push(row); // Add the row for the school
      });

      // Create the table object for this day
      tables[day] = {
        table: {
          headerRows: 1, // Number of header rows
          widths: ['auto', '*', '*', '*', '*'], // Adjust column widths as needed
          body: tableBody,
        },
      };
    });

    return tables;
  } else if (items && premade) {
    // Days of the week for the table headers
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    // Build the table header
    const tableBody = [];
    tableBody.push(['Meal Item', ...daysOfWeek]); // Header row

    // Iterate through categories and meals to populate rows
    Object.entries(items).forEach(([category, meals]) => {
      Object.entries(meals).forEach(([mealName, mealDetails]) => {
        const { dailyQuantities } = mealDetails;

        // Create a row with meal name followed by daily quantities
        const row = [mealName].concat(
          daysOfWeek.map((day) => dailyQuantities[day.toLowerCase()] ?? 0), // Ensure no missing quantities
        );

        tableBody.push(row); // Add the row to the table body
      });
    });

    // Define the table object for pdfmake
    const table = {
      table: {
        headerRows: 1, // Number of header rows
        widths: ['auto', '*', '*', '*', '*', '*', '*', '*'], // Adjust column widths as needed
        body: tableBody,
      },
    };
    return table;
  }
};
