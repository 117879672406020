// themeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    currentTheme: "Classic", // Default theme
    fontSize: 14, // Default font size
  },
  reducers: {
    setTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
    setFontSize: (state, action) => {
      state.fontSize = action.payload;
    },
  },
});

export const { setTheme, setFontSize } = themeSlice.actions;
export default themeSlice.reducer;
