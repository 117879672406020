import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getNextMondays = (
  numberOfMondays = 4,
  includeNextWeek = false,
) => {
  const mondays = [];
  const currentDate = new Date();

  // Get the current day and time
  const dayOfWeek = currentDate.getDay();
  const currentTime = currentDate.getHours() + currentDate.getMinutes() / 60;

  // Adjust the start date based on the current day and time
  if (dayOfWeek === 0) {
    // Sunday or Monday to 4pm
    currentDate.setDate(currentDate.getDate() + 1); // Move to Monday
  } else if (dayOfWeek === 1 && currentTime <= 16) {
    currentDate.setHours(16, 0, 0, 0); // Set to 4 PM
  } else {
    //  if it is after Monday at 4pm then the first date should be the next Monday
    currentDate.setDate(currentDate.getDate() + 7); // Move to the Monday next week

    if (includeNextWeek) {
      currentDate.setDate(currentDate.getDate() - 7); // include the current week if we are already past Monday
    }
  }

  let daysUntilMonday = (1 - currentDate.getDay() + 7) % 7;
  currentDate.setDate(currentDate.getDate() + daysUntilMonday);

  // Add the next 4 Mondays to the array
  for (let i = 0; i < numberOfMondays; i++) {
    mondays.push(currentDate.toDateString());
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return mondays;
};

export const lastTimeSeen = (lastSeenTimestamp) => {
  return (
    <span>
      {lastSeenTimestamp ? ` ${dayjs(lastSeenTimestamp).fromNow()}` : 'Never'}
    </span>
  );
};

export function getDateRange(rangeType) {
  const today = new Date();
  let start, end;
  console.log('rangeType', rangeType);
  switch (rangeType) {
    case 'lastWeek':
      start = new Date(today);
      start.setDate(today.getDate() - 7);
      end = today;
      break;
    case 'lastMonth':
      start = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
      );
      end = today;
      break;
    case 'lastSchoolYear':
      if (today.getMonth() >= 8) {
        // If current month is September (8) or later
        start = new Date(today.getFullYear() - 1, 8, 1); // Last year's September 1st
        end = new Date(today.getFullYear(), 5, 30); // This year's June 30th
      } else {
        start = new Date(today.getFullYear() - 2, 8, 1); // Year before last's September 1st
        end = new Date(today.getFullYear() - 1, 5, 30); // Last year's June 30th
      }
      break;
    case 'schoolYear':
      if (today.getMonth() >= 8) {
        // If current month is September (8) or later
        start = new Date(today.getFullYear(), 8, 1); // This year's September 1st
        end = new Date(today.getFullYear() + 1, 5, 30); // Next year's June 30th
      } else {
        start = new Date(today.getFullYear() - 1, 8, 1); // Last year's September 1st
        end = new Date(today.getFullYear(), 5, 30); // This year's June 30th
      }
      break;
    case 'all':
      start = new Date('2000-01-01');
      end = new Date('2100-01-01');
      break;
    default:
      throw new Error('Invalid range type');
  }

  return { start, end };
}
