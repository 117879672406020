import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import SummaryTable from './Tables/SummaryTable'; // Import the new SummaryTable component

// Register the components needed for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

const SchoolDetailDialog = ({ open, onClose, schoolDetails }) => {
  if (!schoolDetails) return null;
  // Prepare data for charts
  const orderDates = schoolDetails.orders.map((order) =>
    new Date(order.orderDate).toLocaleDateString(),
  );
  const orderAmounts = schoolDetails.orders.map((order) => order.amount);
  const orderQuantities = schoolDetails.orders.map((order) => order.quantity);

  // Aggregate item data for table
  const itemSummary = schoolDetails.orders.reduce((acc, order) => {
    if (order.items) {
      order.items.forEach((item) => {
        if (!acc[item.name]) {
          acc[item.name] = { totalQuantity: 0, totalCost: 0 };
        }
        acc[item.name].totalQuantity += item.quantity;
        acc[item.name].totalCost += item.quantity * (item.price || 0); // Check if price exists
      });
    }
    return acc;
  }, {});

  // Convert aggregated item data to an array
  const itemSummaryArray = Object.keys(itemSummary).map((itemName) => ({
    name: itemName,
    ...itemSummary[itemName],
  }));

  const chartData = {
    labels: orderDates,
    datasets: [
      {
        label: 'Order Amount',
        data: orderAmounts,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        yAxisID: 'y1',
        tension: 0.1, // Smooth line
      },
      {
        label: 'Order Quantity',
        data: orderQuantities,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        yAxisID: 'y2',
        tension: 0.1, // Smooth line
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.dataset.label === 'Order Amount') {
              return `$${tooltipItem.raw.toLocaleString()}`;
            }
            return tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      y1: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Amount ($)',
        },
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
        },
      },
      y2: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Quantity',
        },
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 5,
      },
    },
  };

  return (
    <Dialog open={open} maxWidth='xl' fullWidth onClose={onClose}>
      <DialogTitle>{schoolDetails.school}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6'>
            Total Orders: {schoolDetails.totalOrders.toLocaleString()}
          </Typography>
          <Typography variant='h6'>
            Total Items: {schoolDetails.totalItems.toLocaleString()}
          </Typography>
          <Typography variant='h6'>
            Total Amount: ${schoolDetails.totalAmount.toLocaleString()}
          </Typography>
        </Box>

        {/* Render graphs */}
        <Box mt={2} mb={2}>
          <Typography variant='h6'>
            Order Amount and Quantity Over Time
          </Typography>
          <Line data={chartData} options={options} />
        </Box>

        {/* Render item summary table */}
        <Box mt={2}>
          <SummaryTable data={itemSummaryArray} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SchoolDetailDialog;
