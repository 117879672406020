import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showToastError } from "../utils/toastUtils";

const PrivateRoute = ({ element }) => {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      showToastError("Invalid or expired session. Please log in again.");
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return element;
};

export default PrivateRoute;
