import React from "react";
import { Button, Box } from "@mui/material";

const StepNavigationButtons = ({
  previousStep,
  nextStep,
  previousText = "Previous Step",
  nextText = "Next Step",
  nextDisabled = false, // New prop to control the disabled state of the next button
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
      {previousStep && (
        <Button
          onClick={previousStep}
          variant="contained"
          color="primary"
          sx={{ flex: 1, mr: 1 }} // Adds margin-right for spacing
        >
          {previousText}
        </Button>
      )}
      {nextStep && (
        <Button
          onClick={nextStep}
          variant="contained"
          color="primary"
          sx={{ flex: 1, ml: 1 }} // Adds margin-left for spacing
          disabled={nextDisabled} // Apply disabled state based on the prop
        >
          {nextText}
        </Button>
      )}
    </Box>
  );
};

export default StepNavigationButtons;
