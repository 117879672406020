import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiServices from '../api'; // Import the api instance

// Define async thunks for CRUD operations
export const fetchOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiServices.organizationApi.getOrganizations();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchOrganizationsPublic = createAsyncThunk(
  'organizations/fetchOrganizationsPublic',
  async (organization = '', { rejectWithValue }) => {
    try {
      const response =
        await apiServices.organizationApi.getOrganizationsPublic(organization);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createOrganization = createAsyncThunk(
  'organizations/createOrganization',
  async (organization, { rejectWithValue }) => {
    try {
      const response =
        await apiServices.organizationApi.createOrganization(organization);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateOrganization = createAsyncThunk(
  'organizations/updateOrganization',
  async (orgData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await apiServices.organizationApi.updateOrganization(
        orgData.id,
        orgData.formData,
        config,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteOrganization = createAsyncThunk(
  'organizations/deleteOrganization',
  async (id, { rejectWithValue }) => {
    try {
      await apiServices.organizationApi.deleteOrganization(`${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Define the slice
const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.organizations = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchOrganizationsPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrganizationsPublic.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.organizations = action.payload;
      })
      .addCase(fetchOrganizationsPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizations.push(action.payload);
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        const index = state.organizations.findIndex(
          (org) => org._id === action.payload._id,
        );
        if (index !== -1) {
          state.organizations[index] = action.payload;
        }
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.organizations = state.organizations.filter(
          (org) => org._id !== action.payload,
        );
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const selectOrganizations = (state) => state.organizations.organizations;

export default organizationsSlice.reducer;
