import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth";
import { setTheme, setFontSize } from "../../features/themeSlice";
import { changePassword, fetchUser } from "../../features/users";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { showToastError, showToastSuccess } from "../../utils/toastUtils";
import UserForm from "../User/Form"; // Import the UserForm component

const ProfileMenu = ({
  anchorEl,
  open,
  onClose,
  onThemeChange,
  onFontSizeChange,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user?.id);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anchorElTheme, setAnchorElTheme] = useState(null);
  const [anchorElFont, setAnchorElFont] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId))
        .unwrap()
        .then((user) => {
          setSelectedUser(user);
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    }
  }, [dispatch, userId]);

  const handleThemeMenuOpen = (event) => {
    setAnchorElTheme(event.currentTarget);
  };

  const handleThemeMenuClose = () => {
    setAnchorElTheme(null);
  };

  const handleFontMenuOpen = (event) => {
    setAnchorElFont(event.currentTarget);
  };

  const handleFontMenuClose = () => {
    setAnchorElFont(null);
  };

  const handleThemeChange = (newTheme) => {
    dispatch(setTheme(newTheme));
    localStorage.setItem("selectedTheme", newTheme);
    if (onThemeChange) onThemeChange(newTheme);
    handleThemeMenuClose();
    onClose(true);
  };

  const handleFontSizeChange = (size) => {
    dispatch(setFontSize(size));
    localStorage.setItem("fontSize", size);
    if (onFontSizeChange) onFontSizeChange(size);
    handleFontMenuClose();
    onClose(true);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedMenu");
    localStorage.removeItem("selectedTheme");
    localStorage.removeItem("fontSize");
    onClose();
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }
    setPasswordMatch(true);
    setLoading(true);

    try {
      await dispatch(
        changePassword({ userId, oldPassword, newPassword }),
      ).unwrap();
      showToastSuccess("Password changed successfully");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setPasswordDialogOpen(false);
    } catch (error) {
      showToastError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordMatch(true);
    setPasswordDialogOpen(false);
  };

  const handleCloseForm = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <MenuItem onClick={() => setDialogOpen(true)}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem onClick={handleThemeMenuOpen}>Themes</MenuItem>
        <Menu
          anchorEl={anchorElTheme}
          open={Boolean(anchorElTheme)}
          onClose={handleThemeMenuClose}
        >
          <MenuItem onClick={() => handleThemeChange("Light")}>
            Light Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Dark")}>
            Dark Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Classic")}>
            Classic Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Sunrise")}>
            Sunrise Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Ocean")}>
            Ocean Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Forest")}>
            Forest Theme
          </MenuItem>
          <MenuItem onClick={() => handleThemeChange("Twilight")}>
            Twilight Theme
          </MenuItem>
        </Menu>
        <MenuItem onClick={handleFontMenuOpen}>Font Size</MenuItem>
        <Menu
          anchorEl={anchorElFont}
          open={Boolean(anchorElFont)}
          onClose={handleFontMenuClose}
        >
          <MenuItem onClick={() => handleFontSizeChange(12)}>
            Font Size: 12px
          </MenuItem>
          <MenuItem onClick={() => handleFontSizeChange(14)}>
            Font Size: 14px
          </MenuItem>
          <MenuItem onClick={() => handleFontSizeChange(16)}>
            Font Size: 16px
          </MenuItem>
          <MenuItem onClick={() => handleFontSizeChange(18)}>
            Font Size: 18px
          </MenuItem>
          <MenuItem onClick={() => handleFontSizeChange(20)}>
            Font Size: 20px
          </MenuItem>
        </Menu>
        <MenuItem onClick={() => setPasswordDialogOpen(true)}>
          Change Password
        </MenuItem>
      </Menu>

      <Dialog open={dialogOpen} onClose={handleCloseForm}>
        <DialogTitle>Profile</DialogTitle>
        <DialogContent>
          <UserForm
            user={selectedUser}
            open={dialogOpen}
            onClose={handleCloseForm}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={passwordDialogOpen} onClose={handleCancel}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Old Password"
            type="password"
            fullWidth
            variant="outlined"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm New Password"
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!passwordMatch}
            helperText={!passwordMatch ? "Passwords do not match" : ""}
          />
          <Button
            onClick={handleChangePassword}
            color="primary"
            disabled={loading}
            sx={{ mt: 2, mr: 1 }}
          >
            {loading ? "Changing..." : "Change Password"}
          </Button>
          <Button onClick={handleCancel} color="secondary" sx={{ mt: 2 }}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileMenu;
