import React from 'react';
import { Box, Typography } from '@mui/material';
import DroppableSlot from './DroppableSlot';

const MealSlot = ({ currentDate, mealTime, mealOption, onDropItem, view }) => {
  return (
    <DroppableSlot
      currentDate={currentDate}
      mealTime={mealTime}
      mealOption={mealOption}
      onDrop={onDropItem}
      view={view}
      sx={{ flex: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '3rem', // Adjust as needed
        }}
      >
        <Typography
          variant='body2'
          sx={{
            transform: 'rotate(-90deg)',
            transformOrigin: 'left center',
            whiteSpace: 'nowrap',
            marginBottom:
              mealOption === 'main'
                ? -10
                : mealOption === 'mainVeg'
                  ? -13
                  : mealOption === 'snack'
                    ? -10
                    : -12.5, // Adjust spacing based on the category
            marginLeft:
              mealOption === 'fruitVeg'
                ? 1
                : mealOption === 'mainVeg'
                  ? 1.5
                  : -1, // Adjust based on category
          }}
        >
          {mealOption === 'main'
            ? 'Main'
            : mealOption === 'mainVeg'
              ? 'Main-Veg'
              : mealOption === 'fruitVeg'
                ? 'Fruit/Veg'
                : 'Snack'}
        </Typography>
      </Box>
    </DroppableSlot>
  );
};

const MealSlots = ({ currentDate, mealTime, onDropItem, view }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {/* <Box sx={{ display: 'flex', gap: 0.5 }}> */}
      <MealSlot
        currentDate={currentDate}
        mealTime={mealTime}
        mealOption='main'
        onDropItem={onDropItem}
        view={view}
      />
      <MealSlot
        currentDate={currentDate}
        mealTime={mealTime}
        mealOption='mainVeg'
        onDropItem={onDropItem}
        view={view}
      />
      {/* </Box> */}
      <MealSlot
        currentDate={currentDate}
        mealTime={mealTime}
        mealOption='fruitVeg'
        onDropItem={onDropItem}
        view={view}
      />
      <MealSlot
        currentDate={currentDate}
        mealTime={mealTime}
        mealOption='snack'
        onDropItem={onDropItem}
        view={view}
      />
    </Box>
  );
};

export default MealSlots;
