import React, { useEffect, useState } from 'react';
import {
  Person as PersonIcon,
  Fastfood as FoodIcon,
  Business as OrgIcon,
} from '@mui/icons-material';

const ImagePreview = ({
  imageURL,
  alt,
  placeholderIcon,
  style = { width: 50, height: 50, borderRadius: '50%', objectFit: 'cover' },
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const placeholder =
    placeholderIcon === 'food' ? (
      <FoodIcon />
    ) : placeholderIcon === 'org' ? (
      <OrgIcon />
    ) : (
      <PersonIcon />
    );

  useEffect(() => {
    setImagePreview(imageURL);
  }, [imageURL]);

  return (
    <>
      {imageURL || imagePreview ? (
        <img src={imagePreview} alt={alt} style={style} />
      ) : (
        placeholder
      )}
    </>
  );
};

export default ImagePreview;
