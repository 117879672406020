import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  TablePagination,
  TextField,
  Paper,
  Menu,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  FormControlLabel,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/MoreVert';
import FoodMenuItem from './Item';
import { formatCurrency } from '../../utils/currencyUtils';
import ImagePreview from '../Images/ImagePreview';
import { useDebounce } from '../hooks/hooks'; // Adjust the path as needed

const MenuItemList = ({ menuItems = [], onEdit, onDelete, viewMode }) => {
  const storedState = JSON.parse(localStorage.getItem('menuItemListState'));

  const [order, setOrder] = useState(storedState?.order || 'asc');
  const [orderBy, setOrderBy] = useState(storedState?.orderBy || 'name');
  const [page, setPage] = useState(storedState?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    storedState?.rowsPerPage || 25,
  );
  const [filter, setFilter] = useState(storedState?.filter || '');
  const debouncedFilter = useDebounce(filter);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    storedState?.selectedCategory || 'All',
  );
  const [showActive, setShowActive] = useState(storedState?.showActive ?? true);

  const searchRef = useRef(null);

  // Save state to localStorage when specific state variables change
  useEffect(() => {
    const stateToSave = {
      order,
      orderBy,
      page,
      rowsPerPage,
      filter,
      selectedCategory,
      showActive,
    };
    localStorage.setItem('menuItemListState', JSON.stringify(stateToSave));
  }, [order, orderBy, page, rowsPerPage, filter, selectedCategory, showActive]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0); // Reset to first page when filter changes
  };

  const handleCategoryChange = (event, newCategory) => {
    setSelectedCategory(newCategory || 'All');
    setPage(0);
  };

  const handleFocus = () => {
    if (searchRef.current) {
      searchRef.current.select();
    }
  };

  const handleToggleActive = (event) => {
    setShowActive(event.target.checked);
    setPage(0);
  };

  const renderName = (item) => {
    if (item.isCollection && item.collectionItems) {
      return (
        <div>
          <Typography variant='subtitle1'>{item.name}</Typography>
          <div>
            {item.collectionItems.map((collectionItem, index) => (
              <span key={index}>
                {collectionItem.quantity} {collectionItem.units} x{' '}
                {collectionItem.menuItem.name}
                {index < item.collectionItems.length - 1 && ', '}
              </span>
            ))}
          </div>
        </div>
      );
    } else {
      return <Typography variant='subtitle1'>{item.name}</Typography>;
    }
  };

  const filteredMenuItems = useMemo(() => {
    return menuItems
      .filter(
        (item) =>
          item &&
          (selectedCategory === 'All' || item.category === selectedCategory) &&
          (showActive ? item.active : !item.active), // Filter based on active status
      )
      .filter(
        (item) =>
          item &&
          item.name.toLowerCase().includes(debouncedFilter.toLowerCase()),
      );
  }, [menuItems, selectedCategory, showActive, debouncedFilter]);

  const sortedMenuItems = useMemo(() => {
    return [...filteredMenuItems].sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (orderBy === 'size') {
        return order === 'asc' ? a.size - b.size : b.size - a.size;
      } else if (orderBy === 'category') {
        return order === 'asc'
          ? a.category.localeCompare(b.category)
          : b.category.localeCompare(a.category);
      } else if (orderBy === 'price') {
        return order === 'asc' ? a.price - b.price : b.price - a.price;
      }
      return 0;
    });
  }, [filteredMenuItems, orderBy, order]);

  const paginatedMenuItems = useMemo(() => {
    return sortedMenuItems.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [sortedMenuItems, page, rowsPerPage]);

  const handleMenuClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrentItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentItem(null);
  };

  const handleEdit = () => {
    if (onEdit && currentItem) {
      onEdit(currentItem);
    }
    handleMenuClose();
  };

  const handleDelete = () => {
    if (currentItem) {
      if (onDelete && currentItem) {
        onDelete(currentItem._id);
      }
      handleMenuClose();
    }
  };

  const categories = useMemo(() => {
    const uniqueCategories = new Set(
      menuItems.filter((item) => item).map((item) => item.category),
    );
    return ['All', ...uniqueCategories];
  }, [menuItems]);

  return (
    <div>
      <TextField
        label='Search Menu Items'
        variant='outlined'
        fullWidth
        margin='dense'
        value={filter}
        onChange={handleFilterChange}
        onFocus={handleFocus}
        inputRef={searchRef}
      />
      <FormControlLabel
        control={
          <Switch
            checked={showActive}
            onChange={handleToggleActive}
            color='primary'
          />
        }
        label={showActive ? 'Show Active Only' : 'Show Non-Active Only'}
        style={{ marginTop: '16px' }}
      />
      <div style={{ marginTop: '16px', width: '100%' }}>
        <ToggleButtonGroup
          value={selectedCategory}
          exclusive
          onChange={handleCategoryChange}
          aria-label='Category'
          style={{ width: '100%' }}
        >
          {categories.map((category) => (
            <ToggleButton key={category} value={category}>
              {category}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      {viewMode === 'table' ? (
        <TableContainer component={Paper} style={{ marginTop: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'size'}
                    direction={orderBy === 'size' ? order : 'asc'}
                    onClick={() => handleRequestSort('size')}
                  >
                    Size
                  </TableSortLabel>
                </TableCell>
                <TableCell>Units</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'category'}
                    direction={orderBy === 'category' ? order : 'asc'}
                    onClick={() => handleRequestSort('category')}
                  >
                    Category
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'price'}
                    direction={orderBy === 'price' ? order : 'asc'}
                    onClick={() => handleRequestSort('price')}
                  >
                    Price
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedMenuItems.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <ImagePreview
                      imageURL={item.imageInfo?.url ? item.imageInfo.url : ''}
                      placeHodlerIcon='foodIcon'
                      style={{
                        width: '75px',
                        height: '75px',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                  </TableCell>
                  <TableCell>{renderName(item)}</TableCell>
                  <TableCell>{item.size}</TableCell>
                  <TableCell>{item.units}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{formatCurrency(item.price)}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      edge='end'
                      aria-label='more'
                      onClick={(event) => handleMenuClick(event, item)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            marginTop: '16px',
          }}
        >
          {paginatedMenuItems.map((item) => (
            <FoodMenuItem
              key={item._id}
              item={item}
              imagePreview={item.imageInfo?.url}
              onEdit={() => handleMenuClick(null, item)}
              onDelete={() => handleDelete()}
              handleMenuClick={handleMenuClick}
            />
          ))}
        </div>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={filteredMenuItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon
            color='primary'
            sx={{ marginRight: '10px' }}
            fontSize='small'
          />{' '}
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon
            color='secondary'
            sx={{ marginRight: '10px' }}
            fontSize='small'
          />{' '}
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuItemList;
