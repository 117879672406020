import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Button,
  IconButton,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MenuDrawer = ({
  drawerOpen,
  setDrawerOpen,
  renderDraggableItems,
  handleSaveMenu,
}) => {
  // State to manage the open/close status of each category
  const [openMain, setOpenMain] = useState(true);
  const [openFruitVeg, setOpenFruitVeg] = useState(true);
  const [openSnack, setOpenSnack] = useState(true);

  return (
    <>
      <Drawer
        variant='persistent'
        anchor='right'
        open={drawerOpen}
        sx={{
          'width': 250,
          'flexShrink': 0,
          '& .MuiDrawer-paper': {
            width: 200,
            marginTop: 8,
            boxSizing: 'border-box',
            height: `calc(100% - 50px)`, // Adjust height to account for the shift
          },
        }}
      >
        <Box sx={{ width: 250, padding: 2 }}>
          <Typography variant='h6'>Select Menu Items</Typography>
          <Box>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => setOpenMain(!openMain)}>
                {openMain ? '-' : '+'}
              </IconButton>
              <Typography variant='subtitle1'>Main</Typography>
            </Box>

            <Collapse in={openMain}>{renderDraggableItems('Main')}</Collapse>
          </Box>
          <Box>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => setOpenFruitVeg(!openFruitVeg)}>
                {openFruitVeg ? '-' : '+'}
              </IconButton>
              <Typography variant='subtitle1'>Fruits & Vegetables</Typography>
            </Box>
            <Collapse in={openFruitVeg}>
              {renderDraggableItems('Fruit/Veg')}
            </Collapse>
          </Box>
          <Box>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => setOpenSnack(!openSnack)}>
                {openSnack ? '-' : '+'}
              </IconButton>
              <Typography variant='subtitle1'>Snack</Typography>
            </Box>

            <Collapse in={openSnack}>{renderDraggableItems('Snack')}</Collapse>
          </Box>

          <Box sx={{ marginTop: 2, marginRight: 3, textAlign: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSaveMenu}
            >
              Save Menu
            </Button>
          </Box>
        </Box>
      </Drawer>

      <IconButton
        onClick={() => setDrawerOpen(!drawerOpen)}
        sx={{
          position: 'fixed',
          right: 16,
          top: 70,
          zIndex: 1201, // Ensure the button is above the drawer
        }}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default MenuDrawer;
