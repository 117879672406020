// src/theme/twilightTheme.js

import { createTheme } from "@mui/material/styles";
import paletteTwilight from "./paletteTwilight";
import typography from "./typography";
import components from "./components";

const twilightTheme = (fontSize) =>
  createTheme({
    palette: paletteTwilight,
    typography: typography(fontSize),

    components,
  });

export default twilightTheme;
