const typography = (fontSize) => ({
  fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  h1: {
    fontSize: `${fontSize * 2.125}px`, // Adjusted based on fontSize
    fontWeight: 400,
    letterSpacing: "-0.01562em",
  },
  h2: {
    fontSize: `${fontSize * 1.75}px`, // Adjusted based on fontSize
    fontWeight: 400,
    letterSpacing: "-0.00833em",
  },
  h3: {
    fontSize: `${fontSize * 1.5}px`, // Adjusted based on fontSize
    fontWeight: 400,
    letterSpacing: "0em",
  },
  h6: {
    fontSize: `${fontSize * 1.2}px`, // Adjusted based on fontSize
    fontWeight: 500,
    letterSpacing: "0.0075em", // Adjust the letter spacing
  },
  body1: {
    fontSize: `${fontSize}px`, // Adjusted based on fontSize
    fontWeight: 400,
  },
  body2: {
    fontSize: `${fontSize * 0.875}px`, // Adjusted based on fontSize
    fontWeight: 400,
  },
  button: {
    textTransform: "none",
    fontWeight: 500,
  },
});

export default typography;
