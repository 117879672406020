// src/theme/sunriseTheme.js

import { createTheme } from "@mui/material/styles";
import paletteSunrise from "./paletteSunrise";
import typography from "./typography";
import components from "./components";

const sunriseTheme = (fontSize) =>
  createTheme({
    palette: paletteSunrise,
    typography: typography(fontSize),

    components,
  });

export default sunriseTheme;
