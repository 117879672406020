// src/theme/forestTheme.js

import { createTheme } from "@mui/material/styles";
import paletteForest from "./paletteForest";
import typography from "./typography";
import components from "./components";

const forestTheme = (fontSize) =>
  createTheme({
    palette: paletteForest,
    typography: typography(fontSize),

    components,
  });

export default forestTheme;
