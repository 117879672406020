import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PremadeMealImport from '../components/Import/PremadeMealImport';
import BulkLoader from '../components/Import/BulkLoader'; // Import another import component
import { Paper } from '@mui/material';

const ImportPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDataImported = (data) => {
    // Process the imported data here
  };

  return (
    <Container sx={{ p: 2, m: 2 }} maxWidth={false} disableGutters>
      <Typography variant='h1'>Admin Import Page</Typography>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label='Premade Meal Import' />
        <Tab label='Bulk Loader' />
      </Tabs>
      <Container>
        <Paper>
          {selectedTab === 0 && (
            <PremadeMealImport onDataImported={handleDataImported} />
          )}
          {selectedTab === 1 && <BulkLoader />}
        </Paper>
      </Container>
    </Container>
  );
};

export default ImportPage;
