import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton } from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';
import ImagePreview from './ImagePreview';

const ImageUploader = ({ onFileSelect, initialImageURL }) => {
  const [imageURL, setImageURL] = useState(initialImageURL || '');

  useEffect(() => {
    setImageURL(initialImageURL);
  }, [initialImageURL]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg'],
      'image/webp': ['.webp'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      onFileSelect(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(file);
    },
  });

  const handleRemoveImage = () => {
    setImageURL('');
  };

  const handleImageClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <Box
      {...getRootProps()}
      border={1}
      borderColor='grey.400'
      borderRadius={2}
      p={2}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      style={{ cursor: 'pointer' }}
    >
      <input {...getInputProps()} id='fileInput' style={{ display: 'none' }} />

      {imageURL ? (
        <>
          <ImagePreview
            imageURL={imageURL}
            originalImageURL={initialImageURL}
            alt='Preview'
            onClick={handleImageClick}
            style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
          />
          <IconButton onClick={handleRemoveImage} aria-label='remove image'>
            <Delete />
          </IconButton>
        </>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          color='grey.600'
        >
          <CloudUpload style={{ fontSize: 40 }} />
          <Typography variant='body2'>No Image Selected</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;
