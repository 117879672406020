import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import DraggableMenuItem from '../components/Premades/DraggableMenuItem';
import CalendarView from '../components/Premades/CalendarView'; // Generalized to handle different timeframes
import { saveMenu, fetchMenus, fetchMenuItems } from '../features/menuSlice';
import MenuDrawer from '../components/Premades/MenuDrawer';
import { showToastError, showToastSuccess } from '../utils/toastUtils';
import { CircularProgress } from '@mui/material';

const MenuBuilder = () => {
  const dispatch = useDispatch();
  const menuItems = useSelector((state) => state.menus.items);
  const menus = useSelector((state) => state.menus.menus);
  const status = useSelector((state) => state.menus.status);
  const error = useSelector((state) => state.menus.error);

  const [drawerOpen, setDrawerOpen] = useState(true); // Start with the drawer open
  const [isMenuItemsLoaded, setIsMenuItemsLoaded] = useState(false);
  const [isMenuDateLoaded, setIsMenuDateLoaded] = useState(false);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchMenus());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const loadMenuItems = async () => {
      try {
        dispatch(fetchMenuItems());
        setIsMenuItemsLoaded(true);
      } catch (error) {
        console.error('Failed to load menu items:', error);
      }
    };

    loadMenuItems();
  }, [dispatch]);

  useEffect(() => {
    // Assume menus is loaded when menuItems are loaded
    if (menuItems.length > 0) {
      setIsMenuDateLoaded(true); // Set menus loaded status here
    }
  }, [menuItems]);

  if (status === 'loading' || !isMenuItemsLoaded || !isMenuDateLoaded) {
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  const handleSaveMenu = async () => {
    try {
      // Format the menu data to match the expected payload structure
      const formattedMenu = Object.keys(menus).map((date) => {
        const mealEntries = menus[date];
        const formattedMeals = {};

        // Loop through each meal type (e.g., breakfast, lunch, dinner)
        Object.keys(mealEntries).forEach((mealType) => {
          const meal = mealEntries[mealType];
          formattedMeals[mealType] = {};

          // Loop through each category within the meal type (e.g., fruitVeg, main)
          Object.keys(meal).forEach((category) => {
            const items = meal[category];

            // Ensure items is an array
            if (Array.isArray(items)) {
              formattedMeals[mealType][category] = items.map((item) => item.id);
            } else {
              console.warn(
                `Expected an array for date ${date}, mealType ${mealType}, category ${category}, but got:`,
                items,
              );
              formattedMeals[mealType][category] = []; // Handle as needed
            }
          });
        });

        return {
          date: new Date(date).toISOString(), // Convert date to ISO string
          items: formattedMeals,
        };
      });

      const id = null; // Replace with actual id if needed

      // Dispatch createOrUpdateMenu action
      await dispatch(
        saveMenu({
          id,
          menuData: {
            menuItems: formattedMenu,
          },
        }),
      ).unwrap(); // Unwrap the promise to handle success/error
      showToastSuccess('Menu saved successfully!');
    } catch (error) {
      showToastError('Failed to save menu. Please try again later.');
      console.error('Failed to save menu:', error);
    }
  };

  const renderDraggableItems = (preMadeMenuType) => {
    if (!Array.isArray(menuItems)) {
      return null;
    }

    return menuItems
      .filter((item) => item.preMadeMenuType.includes(preMadeMenuType))
      .map((item) => (
        <DraggableMenuItem
          key={item._id}
          item={item}
          mealType={preMadeMenuType} // Pass mealType
        />
      ));
  };
  return (
    <Grid container sx={{ mt: 3 }} spacing={2}>
      <Grid item xs={drawerOpen ? 10 : 12}>
        <CalendarView drawerOpen={drawerOpen} />
      </Grid>

      <div>
        <MenuDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          renderDraggableItems={renderDraggableItems}
          handleSaveMenu={handleSaveMenu}
        />
      </div>
    </Grid>
  );
};

export default MenuBuilder;
