import React from 'react';
import { Box, Typography } from '@mui/material';
import OrderSummary from './OrderSummary';
import MealSlots from './MealSlots';

const MealSection = ({
  mealTime,
  regularOrders,
  veggieOrders,
  currentDate,
  onDropItem,
  view,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        border: '2px solid #777',
        borderRadius: '5px',
        p: 1,
        mb: 1,
      }}
    >
      <Typography variant='subtitle1' sx={{ mb: 1 }}>
        {mealTime?.charAt(0).toUpperCase() + mealTime?.slice(1)}
      </Typography>
      <OrderSummary regularOrders={regularOrders} veggieOrders={veggieOrders} />
      <MealSlots
        currentDate={currentDate}
        mealTime={mealTime}
        onDropItem={onDropItem} // Use the handleDrop function here
        view={view}
      />
    </Box>
  );
};

export default MealSection;
