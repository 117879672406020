import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LTExpImage from '../../assets/images/LTExp.webp';

import {
  AppBar,
  Menu,
  MenuItem,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
} from '@mui/material';
import ProfileMenu from './ProfileMenu';
import { useRoles } from '../../utils/authUtils'; // Import the custom hook

const Navbar = ({ onThemeChange, onFontSizeChange }) => {
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [adminMenuAnchorEl, setAdminMenuAnchorEl] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(
    JSON.parse(localStorage.getItem('user'))?.imageUrl ||
      '/default-profile.png',
  );
  const location = useLocation();
  const { isAdmin, isManager, isLTEAdmin } = useRoles(user);

  useEffect(() => {
    if (user) {
      setProfileImageUrl(user.imageUrl);
    } else {
      setProfileImageUrl('/default-profile.png');
    }
  }, [user]);

  // Handlers
  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleProfileMenuClose = () => setAnchorEl(null);

  const handleAdminMenuOpen = (event) =>
    setAdminMenuAnchorEl(event.currentTarget);
  const handleAdminMenuClose = () => setAdminMenuAnchorEl(null);

  const handleLinkClick = (path) => localStorage.setItem('selectedMenu', path);

  const isActive = (path) => location.pathname === path;

  return (
    <AppBar position='static'>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img
            src={LTExpImage}
            alt='LunchTime Express'
            style={{
              width: '55px',
              height: 'auto',
              marginRight: '8px',
            }}
          />
          <Typography variant='h4' component='div'>
            LunchTimeExpress
          </Typography>
        </Box>
        <Box display='flex' flexDirection={'row'} gap={1}>
          {user && (
            <>
              <Button
                component={Link}
                to='/'
                color={isActive('/') ? 'secondary' : 'inherit'}
                onClick={() => handleLinkClick('/')}
              >
                Home
              </Button>
              <Button
                component={Link}
                to='/orders'
                color={isActive('/orders') ? 'secondary' : 'inherit'}
                onClick={() => handleLinkClick('/orders')}
              >
                Orders
              </Button>
              <Button
                component={Link}
                to='/schools'
                color={isActive('/schools') ? 'secondary' : 'inherit'}
                onClick={() => handleLinkClick('/schools')}
              >
                Schools
              </Button>
              {(isAdmin || isManager || isLTEAdmin) && (
                <>
                  <Button
                    component={Link}
                    to='/users'
                    color={isActive('/users') ? 'secondary' : 'inherit'}
                    onClick={() => handleLinkClick('/users')}
                  >
                    Users
                  </Button>
                  <Button
                    component={Link}
                    to='/menu-items'
                    color={isActive('/menu-items') ? 'secondary' : 'inherit'}
                    onClick={() => handleLinkClick('/menu-items')}
                  >
                    Menu Items
                  </Button>
                  <Button
                    component={Link}
                    to='/depot'
                    color={isActive('/depot') ? 'secondary' : 'inherit'}
                    onClick={() => handleLinkClick('/depot')}
                  >
                    Depot
                  </Button>
                  <Button
                    component={Link}
                    to='/reports'
                    color={isActive('/reports') ? 'secondary' : 'inherit'}
                    onClick={() => handleLinkClick('/reports')}
                  >
                    Reports
                  </Button>
                  <Button
                    color={isActive('/admin') ? 'secondary' : 'inherit'}
                    onClick={handleAdminMenuOpen}
                  >
                    Admin
                  </Button>
                  <Menu
                    anchorEl={adminMenuAnchorEl}
                    open={Boolean(adminMenuAnchorEl)}
                    onClose={handleAdminMenuClose}
                  >
                    {isLTEAdmin && (
                      <MenuItem component={Link} to='/register'>
                        Register User
                      </MenuItem>
                    )}
                    <MenuItem
                      component={Link}
                      to='/register?orgId=66bc0a39e18126f19f6dbec6'
                    >
                      Register User (NLSF)
                    </MenuItem>
                  </Menu>
                </>
              )}
              {isLTEAdmin && (
                <>
                  <Button
                    component={Link}
                    to='/organizations'
                    color={isActive('/organizations') ? 'secondary' : 'inherit'}
                    onClick={() => handleLinkClick('/organizations')}
                  >
                    Organizations
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
        <div>
          {user ? (
            <>
              <IconButton onClick={handleProfileMenuOpen} color='inherit'>
                <Avatar src={profileImageUrl} alt='Profile Picture' />
              </IconButton>
              <ProfileMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
                profileImageUrl={profileImageUrl}
                onThemeChange={onThemeChange}
                onFontSizeChange={onFontSizeChange}
                userId={user.id}
              />
            </>
          ) : (
            <Button component={Link} to='/login' color='inherit'>
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
